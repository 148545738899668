import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
// import Sort from '@/views/Sort.vue'
// import Multisort from '@/views/Multisort.vue'
import Dashboard from '@/views/Dashboard.vue'
import Plans from '@/views/Plans.vue'
// import Xero from '@/views/Xero.vue'
import Login from '@/views/Login.vue'
import View from '@/views/View.vue'
// import Test from '@/views/Test.vue'
import store from '@/store'
import { LoadingProgrammatic as Loading, ToastProgrammatic as Toast } from 'buefy'
import { auth, signInXero, getCustomClaim } from '@/firebase'
// import { connectRedirect } from '@/api'

// const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

Vue.use(VueRouter)

// const Login = {
//   template: '<a href="/api/xero/connect">Connect with Xero</a>'
// }

// const User = {
//   template: '<div>User {{ $route.params.id }}</div>'
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/app',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresSub: true
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/view',
    name: 'View',
    component: View
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  }
  // {
  //   path: '/xero',
  //   name: 'Xero',
  //   component: Xero,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/sort',
  //   name: 'Sort',
  //   component: Sort,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/multisort',
  //   name: 'Multisort',
  //   component: Multisort,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/user/:id',
  //   name: 'User',
  //   component: User
  // },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test,
  //   meta: {
  //     requiresAuth: true
  //   }
  // }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log('router', { to, from })
  console.log('router to', to)
  console.log('router from', from)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.currentUser) {
      const user = await signInXero(to.fullPath)
      console.log('router beforeEach user', user)
    } else {
      console.log('router auth.currentUser', auth.currentUser)
    }
  }
  if (to.matched.some(record => record.meta.requiresSub)) {
    const stripe = await getCustomClaim('stripe')
    if (!['trialing', 'active'].includes(stripe?.status)) {
      console.log('not trialing or active', { stripe })
      return next({
        path: '/plans',
        query: { redirect: to.fullPath }
      })
    } else {
      console.log('router stripe', stripe)
      // next((vm) => { vm.maxContacts = stripe.seats })
    }
  }
  // if (to.matched.some(record => record.meta.requiresGuest)) {
  //   if (auth.currentUser) {
  //     return next({
  //       path: '/app'
  //     })
  //   } else {
  //     console.log('router guest')
  //   }
  // }

  const loading = Loading.open()
  console.log('loading from cache...', loading)
  const toast = Toast.open({ message: 'Loading... (please wait)', indefinite: true })
  await store.restored
  // await delay(1000)
  loading.close()
  toast.close()

  next()
})

export default router
