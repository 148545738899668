import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getAuth, signInWithCustomToken, connectAuthEmulator, onAuthStateChanged, updateEmail, updateProfile, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator, doc, deleteDoc, setDoc, Timestamp, collection, getDoc, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore'
import { apiGet, apiCall } from '@/api'
import store from '@/store'
import router from '@/router'
// import get from 'lodash/get'

const firebaseConfig = {
  apiKey: 'AIzaSyD6sdeJxgBV7qs3cqklpwnOQnsIp_tfj-8',
  authDomain: 'commissionaire-xero.firebaseapp.com',
  projectId: 'commissionaire-xero',
  storageBucket: 'commissionaire-xero.appspot.com',
  messagingSenderId: '686485913779',
  appId: '1:686485913779:web:cfdf79a0da7836a26570c6',
  measurementId: 'G-NVJPVRGRPJ'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics()
const auth = getAuth()
const db = getFirestore()
const functions = getFunctions()

// // if (process.env.FUNCTIONS_EMULATOR) {
// if (location.hostname === 'localhost') {
//   // db.useEmulator('localhost', 8081)
//   functions.useFunctionsEmulator('http://localhost:5000')
//   auth.useEmulator('http://localhost:9099')
// }

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8081)
  connectFunctionsEmulator(functions, 'localhost', 5000)
}

// export { app, analytics, auth, db, functions, httpsCallable, signInAnonymously }

const signInXero = async (redirect) => {
  const { idToken } = await apiGet('xero', 'tokens', { redirect })
  const uid = idToken?.xero_userid
  const developerClaims = {}
  const customToken = await apiCall('customToken', { uid, developerClaims })
  const user = await signIn(customToken)
  return user
}

const signIn = async (customToken) => {
  const { user } = await signInWithCustomToken(auth, customToken)
  return user
}

const setClaims = async ({ uid, claims }) => {
  console.log('setClaims', claims)
  return await apiCall('setCustomClaims', { uid, claims })
}

const getClaims = async () => {
  // const auth = getAuth()
  auth.currentUser.getIdTokenResult(true)
    .then((idTokenResult) => {
      console.log('getClaims', idTokenResult.claims)
      // Confirm the user is an Admin.
      if (idTokenResult.claims.admin) {
        // Show admin UI.
        // showAdminUI()
      } else {
        // Show regular user UI.
        // showRegularUI()
      }
      return idTokenResult.claims
    })
    .catch((error) => {
      console.log(error)
    })
}

onAuthStateChanged(auth, async (user) => {
  // const searchParams = new URLSearchParams(window.location.search)
  // if (user && searchParams.get('mode') !== 'signIn') { // don't do xero stuff for email link signin (designated by mode=signIn param)
  console.log('router name', router.currentRoute.name)
  if (user && router.currentRoute.name !== 'Foo') { // don't do xero stuff for email link signin (designated by mode=signIn param)
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid
    const { idToken } = await apiGet('xero', 'tokens')
    await updateProfile(user, {
      displayName: `${idToken.given_name} ${idToken.family_name}`
    })
    await updateEmail(user, idToken.email)
    // ...
    const xeroTenants = (await apiGet('xero', 'tenants')).tenants
    const xeroTenantIds = xeroTenants.map(t => t.tenantId)
    await setClaims({ uid: user.uid, claims: { foo: 'here we go again', xero_tenant_ids: xeroTenantIds } })
    // await user.reload()
    // await user.getIdToken(true)
    await getClaims()
    console.log('claim foo', await getCustomClaim('foo'))
    // if (router.currentRoute.meta?.requiresGuest) {
    //   window.location.assign('/app')
    // }
  } else {
    // User is signed out
    // ...
  }
  store.commit('user', JSON.parse(JSON.stringify(user)))
})

// https://github.com/stripe/stripe-firebase-extensions/blob/master/firestore-stripe-payments/POSTINSTALL.md#assign-custom-claim-roles-to-products-only-used-for-subscriptions
async function getCustomClaim (key) {
  const user = await auth.currentUser
  console.log('getCustomClaim', { user })
  await auth.currentUser.getIdToken(true)
  const decodedToken = await auth.currentUser.getIdTokenResult()
  // return get(decodedToken.claims, key)
  return decodedToken.claims[key]
}

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: location.hostname === 'localhost' ? 'http://localhost:3000/view' : 'https://commissionaire.io/view',
  // This must be true.
  handleCodeInApp: true
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
}

const sendLinkEmail = async (email, userId, orgId) => {
  console.log('send link email email', email)
  console.log('send link email userId', userId)
  console.log('send link email orgId', orgId)
  // actionCodeSettings.url += `?id=${id}`
  actionCodeSettings.url += `?user=${userId}&org=${orgId}`
  console.log('send link email actionCodeSettings.url', actionCodeSettings.url)
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      console.log('email sent!')
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email)
      // ...
    })
    .catch((error) => {
      // const errorCode = error.code
      // const errorMessage = error.message
      // ...
      console.log('email NOT sent!')
      console.log({ error })
    })
}

const signInEmailLink = async (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation')
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log('sign in email link success', { result })
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log('sign in email link error', { error })
      })
  }
}

export {
  Timestamp,
  addDoc,
  analytics,
  app,
  auth,
  collection,
  db,
  doc,
  deleteDoc,
  functions,
  getClaims,
  getCustomClaim,
  getDoc,
  getDocs,
  httpsCallable,
  logEvent,
  onSnapshot,
  onAuthStateChanged,
  query,
  setDoc,
  signInXero,
  signInEmailLink,
  sendLinkEmail,
  where
}
