var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-white is-fullheight"
  }, [_c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container has-text-centered"
  }, [_c('div', {
    staticClass: "columns is-mobile is-centered"
  }, [_c('div', {
    staticClass: "column is-narrow"
  }, [_vm._m(0), _c('div', {
    attrs: {
      "id": "login"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.loginUrl
    }
  }, [_vm._m(1)])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level mb-5",
    attrs: {
      "id": "logo"
    }
  }, [_c('div', {
    staticClass: "level-left"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('figure', {
    staticClass: "image is-64x64"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/commissionaire-logo.png"),
      "alt": "Commissionaire"
    }
  })])])]), _c('div', {
    staticClass: "level-right mt-1"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('div', {
    staticClass: "has-text-primary is-size-4 is-uppercase has-text-weight-bold pb-3"
  }, [_vm._v("Commissionaire")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('figure', {
    staticClass: "image"
  }, [_c('img', {
    staticClass: "connect",
    attrs: {
      "src": require("@/assets/connect-blue.svg")
    }
  })]);
}]

export { render, staticRenderFns }