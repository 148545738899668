var render = function () {
  var _vm$settings, _vm$settings4, _vm$settings5, _vm$settings6, _vm$maps$_vm$mapIndex, _vm$maps$_vm$mapIndex2, _vm$settings7, _vm$settings8, _vm$plan$planType, _vm$plan$planType2, _vm$maps$_vm$mapIndex4, _vm$maps$_vm$mapIndex5, _vm$settings9, _vm$settings10, _vm$settings11, _vm$settings12, _vm$settings23, _vm$settings24, _vm$settings25, _vm$settings26;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('div', {
    attrs: {
      "id": "invoices"
    }
  }, [_vm.activeTenantId ? _c('Nav', {
    attrs: {
      "tenantId": _vm.activeTenantId,
      "tenants": _vm.tenants
    },
    on: {
      "updateTenantId": _vm.updateActiveTenantId
    }
  }) : _vm._e(), _c('section', {
    staticClass: "section"
  }, [_c('Chart', {
    attrs: {
      "invoices": _vm.filteredDataWithComms,
      "fieldInit": "subTotal",
      "dateFormatInit": "YY",
      "dateFieldInit": "dueDate"
    }
  })], 1), _c('b-modal', {
    attrs: {
      "scroll": "keep"
    },
    model: {
      value: _vm.openSettings,
      callback: function callback($$v) {
        _vm.openSettings = $$v;
      },
      expression: "openSettings"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("Settings")]), _c('hr'), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v("Labels")]), _vm._l((_vm$settings = _vm.settings) === null || _vm$settings === void 0 ? void 0 : _vm$settings.labels, function (value, name) {
    var _vm$settings2;

    return _c('b-field', {
      key: name,
      attrs: {
        "label": _vm.capitalize(name),
        "horizontal": ""
      }
    }, [_c('b-input', {
      model: {
        value: (_vm$settings2 = _vm.settings) === null || _vm$settings2 === void 0 ? void 0 : _vm$settings2.labels[name],
        callback: function callback($$v) {
          var _vm$settings3;

          _vm.$set((_vm$settings3 = _vm.settings) === null || _vm$settings3 === void 0 ? void 0 : _vm$settings3.labels, name, $$v);
        },
        expression: "settings?.labels[name]"
      }
    })], 1);
  }), false ? _c('div', {
    attrs: {
      "id": "csv"
    }
  }, [_c('hr'), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v("Line Item Costs CSV")]), _c('b-field', {
    staticClass: "file is-primary",
    class: {
      'has-name': !!_vm.file
    }
  }, [_c('b-upload', {
    staticClass: "file-label",
    attrs: {
      "accept": ".csv",
      "required": "",
      "validationmessage": "Please select a file"
    },
    on: {
      "input": _vm.csvUpload
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, [_c('span', {
    staticClass: "file-cta"
  }, [_c('b-icon', {
    staticClass: "file-icon",
    attrs: {
      "icon": "upload"
    }
  }), _c('span', {
    staticClass: "file-label"
  }, [_vm._v("Click to upload (Only .csv)")])], 1), _vm.file ? _c('span', {
    staticClass: "file-name"
  }, [_vm._v(_vm._s(_vm.file.name))]) : _vm._e()])], 1)], 1) : _vm._e(), _c('hr'), _c('b-field', {
    attrs: {
      "grouped": "",
      "position": "is-right"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "is-success"
    },
    on: {
      "click": function click($event) {
        _vm.openSettings = false;

        _vm.save({
          settings: _vm.settings
        });
      }
    }
  }, [_vm._v("Save")])], 1)], 2)])]), _c('b-modal', {
    attrs: {
      "scroll": "keep"
    },
    on: {
      "close": function close($event) {
        _vm.search = _vm.mergeSearches(_vm.flatSearches);
      }
    },
    model: {
      value: _vm.openSearches,
      callback: function callback($$v) {
        _vm.openSearches = $$v;
      },
      expression: "openSearches"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s((_vm$settings4 = _vm.settings) === null || _vm$settings4 === void 0 ? void 0 : _vm$settings4.labels.searches))]), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v("Invoice Search")]), _c('b-field', {
    attrs: {
      "label": "",
      "grouped": "",
      "group-multiline": ""
    }
  }, [_c('b-field', {
    attrs: {
      "label": "Type",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.invoiceType,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "invoiceType", $$v);
      },
      expression: "search.invoiceType"
    }
  }, [_c('option', {
    attrs: {
      "value": "ACCREC"
    }
  }, [_vm._v("Accounts Receivable")]), _c('option', {
    attrs: {
      "value": "ACCPAY"
    }
  }, [_vm._v("Accounts Payable")])])], 1), _c('b-field', {
    attrs: {
      "label": "Status",
      "label-position": ""
    }
  }, [_c('b-taginput', {
    ref: "statuses",
    attrs: {
      "data": _vm.filteredStatuses,
      "autocomplete": "",
      "open-on-focus": true,
      "icon": "label",
      "placeholder": "Statuses"
    },
    on: {
      "typing": function typing(text) {
        return _vm.getFilteredStatuses(text);
      },
      "input": function input($event) {
        _vm.setRef("statuses");

        _vm.getFilteredStatuses("");
      },
      "focus": function focus($event) {
        _vm.setRef("statuses");

        _vm.getFilteredStatuses("");
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm.niceStatus(props.option)))];
      }
    }, {
      key: "selected",
      fn: function fn(props) {
        return _vm._l(props.tags, function (status, index) {
          return _c('b-tag', {
            key: index,
            attrs: {
              "rounded": "",
              "ellipsis": "",
              "closable": ""
            },
            on: {
              "close": function close($event) {
                return _vm.$refs.statuses.removeTag(index, $event);
              }
            }
          }, [_vm._v(_vm._s(_vm.niceStatus(status)))]);
        });
      }
    }], null, false, 2470388906),
    model: {
      value: _vm.search.statuses,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "statuses", $$v);
      },
      expression: "search.statuses"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label": "Number",
      "label-position": ""
    }
  }, [_c('b-taginput', {
    attrs: {
      "placeholder": "Number",
      "icon": "label"
    },
    model: {
      value: _vm.search.invoiceNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "invoiceNumber", $$v);
      },
      expression: "search.invoiceNumber"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label": "Reference",
      "label-position": ""
    }
  }, [_c('b-taginput', {
    attrs: {
      "placeholder": "Reference",
      "icon": "label"
    },
    model: {
      value: _vm.search.reference,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "reference", $$v);
      },
      expression: "search.reference"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label": "Date"
    }
  }, [_c('b-datepicker', {
    attrs: {
      "placeholder": "Select dates...",
      "range": ""
    },
    model: {
      value: _vm.search.dates,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "dates", $$v);
      },
      expression: "search.dates"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    attrs: {
      "label": "Financial Year",
      "icon-left": "calendar-today"
    },
    on: {
      "click": function click($event) {
        _vm.search.dates = _vm.eofyDates;
      }
    }
  }), _c('b-button', {
    attrs: {
      "label": "Clear",
      "icon-left": "close",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.search.dates = [];
      }
    }
  })], 1)])], 1), _c('b-field', {
    attrs: {
      "label": "Due Date"
    }
  }, [_c('b-datepicker', {
    attrs: {
      "placeholder": "Select dates...",
      "range": ""
    },
    model: {
      value: _vm.search.dueDates,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "dueDates", $$v);
      },
      expression: "search.dueDates"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    attrs: {
      "label": "Financial Year",
      "icon-left": "calendar-today"
    },
    on: {
      "click": function click($event) {
        _vm.search.dueDates = _vm.eofyDates;
      }
    }
  }), _c('b-button', {
    attrs: {
      "label": "Clear",
      "icon-left": "close",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.search.dueDates = [];
      }
    }
  })], 1)])], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Subtotal",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.subTotalOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "subTotalOp", $$v);
      },
      expression: "search.subTotalOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Subtotal"
    },
    model: {
      value: _vm.search.subTotal,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "subTotal", $$v);
      },
      expression: "search.subTotal"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Total",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.totalOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "totalOp", $$v);
      },
      expression: "search.totalOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Total"
    },
    model: {
      value: _vm.search.total,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "total", $$v);
      },
      expression: "search.total"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Paid",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.amountPaidOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "amountPaidOp", $$v);
      },
      expression: "search.amountPaidOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Paid"
    },
    model: {
      value: _vm.search.amountPaid,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "amountPaid", $$v);
      },
      expression: "search.amountPaid"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Due",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.amountDueOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "amountDueOp", $$v);
      },
      expression: "search.amountDueOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Due"
    },
    model: {
      value: _vm.search.amountDue,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "amountDue", $$v);
      },
      expression: "search.amountDue"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label": (_vm$settings5 = _vm.settings) === null || _vm$settings5 === void 0 ? void 0 : _vm$settings5.labels.contacts,
      "label-position": ""
    }
  }, [_c('b-taginput', {
    ref: "searchContacts",
    attrs: {
      "data": _vm.filteredContacts,
      "field": "name",
      "autocomplete": "",
      "open-on-focus": true,
      "icon": "label",
      "placeholder": (_vm$settings6 = _vm.settings) === null || _vm$settings6 === void 0 ? void 0 : _vm$settings6.labels.contacts
    },
    on: {
      "typing": function typing(text) {
        return _vm.getFilteredContacts(text);
      },
      "input": function input($event) {
        _vm.setRef("searchContacts");

        _vm.getFilteredContacts("");
      },
      "focus": function focus($event) {
        _vm.setRef("searchContacts");

        _vm.getFilteredContacts("");
      }
    },
    model: {
      value: _vm.search.contacts,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "contacts", $$v);
      },
      expression: "search.contacts"
    }
  })], 1)], 1), _c('hr'), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v("Line Item Search")]), _c('b-field', {
    attrs: {
      "label": "",
      "grouped": "",
      "group-multiline": ""
    }
  }, [_c('b-field', {
    attrs: {
      "label": "Account Code",
      "label-position": ""
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Account Code"
    },
    model: {
      value: _vm.search.lineItems.accountCode,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "accountCode", $$v);
      },
      expression: "search.lineItems.accountCode"
    }
  }, [_vm.search.lineItems.accountCode !== null ? _c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("None")]) : _vm._e(), _vm._l(_vm.accounts, function (a) {
    return _c('option', {
      key: a.accountID,
      domProps: {
        "value": a.code
      }
    }, [_vm._v(_vm._s(a.code) + " " + _vm._s(_vm._f("truncate")(a.name)))]);
  })], 2)], 1), _c('b-field', {
    attrs: {
      "label": "Item Code",
      "label-position": ""
    }
  }, [_c('b-taginput', {
    attrs: {
      "placeholder": "Item Code",
      "icon": "label"
    },
    model: {
      value: _vm.search.lineItems.itemCode,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "itemCode", $$v);
      },
      expression: "search.lineItems.itemCode"
    }
  })], 1), _c('b-field', {
    attrs: {
      "label": "Description",
      "label-position": ""
    }
  }, [_c('b-taginput', {
    attrs: {
      "placeholder": "Description",
      "icon": "label"
    },
    model: {
      value: _vm.search.lineItems.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "description", $$v);
      },
      expression: "search.lineItems.description"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Quantity",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.lineItems.quantityOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "quantityOp", $$v);
      },
      expression: "search.lineItems.quantityOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Quantity"
    },
    model: {
      value: _vm.search.lineItems.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "quantity", $$v);
      },
      expression: "search.lineItems.quantity"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Unit Amount",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.lineItems.unitAmountOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "unitAmountOp", $$v);
      },
      expression: "search.lineItems.unitAmountOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Unit Amount"
    },
    model: {
      value: _vm.search.lineItems.unitAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "unitAmount", $$v);
      },
      expression: "search.lineItems.unitAmount"
    }
  })], 1), _c('b-field', {
    attrs: {
      "grouped": "",
      "label": "Line Amount",
      "label-position": ""
    }
  }, [_c('b-select', {
    model: {
      value: _vm.search.lineItems.lineAmountOp,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "lineAmountOp", $$v);
      },
      expression: "search.lineItems.lineAmountOp"
    }
  }, _vm._l(_vm.numOps, function (op, symbol) {
    return _c('option', {
      key: symbol,
      domProps: {
        "value": symbol
      }
    }, [_vm._v(_vm._s(symbol))]);
  }), 0), _c('b-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "max": _vm.maxNumber,
      "placeholder": "Line Amount"
    },
    model: {
      value: _vm.search.lineItems.lineAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.search.lineItems, "lineAmount", $$v);
      },
      expression: "search.lineItems.lineAmount"
    }
  })], 1)], 1), _c('hr'), _vm.searches && _vm.searches.length ? _c('b-field', {
    attrs: {
      "label": "Load Search",
      "label-position": "",
      "grouped": "",
      "group-multiline": ""
    }
  }, [_vm._l(_vm.searches, function (s, index) {
    return _c('b-field', {
      key: index,
      staticClass: "mb-3"
    }, [_c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "label": s.name,
        "type": "is-primary",
        "icon-left": "play"
      },
      on: {
        "click": function click($event) {
          _vm.search = _vm.cloneDeep(s);
          _vm.origSearch = _vm.cloneDeep(s);
        }
      }
    })], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "type": "is-success",
        "icon-left": "content-copy"
      },
      on: {
        "click": function click($event) {
          _vm.search = Object.assign({}, _vm.cloneDeep(s), {
            time: null,
            name: s.name + " copy"
          });
        }
      }
    })], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "type": "is-danger",
        "icon-left": "delete"
      },
      on: {
        "click": function click($event) {
          _vm.confirm(function () {
            return _vm.removeSearch(index);
          });
        }
      }
    })], 1)]);
  }), _c('b-button', {
    staticClass: "field",
    attrs: {
      "type": "is-danger",
      "icon-left": "new-box",
      "label": "New"
    },
    on: {
      "click": function click($event) {
        _vm.confirm(function () {
          return _vm.search = _vm.cloneDeep(_vm.freshSearch);
        });
      }
    }
  })], 2) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('b-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.search.name.length > 0,
      expression: "search.name.length > 0"
    }]
  }, [_c('b-tooltip', {
    attrs: {
      "always": "",
      "position": "is-right",
      "label": _vm.filteredData.length + " invoices"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.search.time || _vm.isEqual(_vm.search, _vm.freshSearch) || !_vm.isEqual(_vm.search, _vm.origSearch) || !!((_vm$maps$_vm$mapIndex = _vm.maps[_vm.mapIndex]) !== null && _vm$maps$_vm$mapIndex !== void 0 && _vm$maps$_vm$mapIndex.searches.find(function (s) {
        return _vm.isEqual(s, _vm.search);
      })),
      "type": "is-success",
      "icon-left": "filter",
      "label": !!((_vm$maps$_vm$mapIndex2 = _vm.maps[_vm.mapIndex]) !== null && _vm$maps$_vm$mapIndex2 !== void 0 && _vm$maps$_vm$mapIndex2.searches.find(function (s) {
        return _vm.isEqual(s, _vm.search);
      })) ? "\"".concat(_vm.search.name, "\" already selected") : "Add \"".concat(_vm.search.name, "\" to selected ").concat((_vm$settings7 = _vm.settings) === null || _vm$settings7 === void 0 ? void 0 : _vm$settings7.labels.searches)
    },
    on: {
      "click": function click($event) {
        var _vm$maps$_vm$mapIndex3;

        _vm.openSearches = false;
        (_vm$maps$_vm$mapIndex3 = _vm.maps[_vm.mapIndex]) === null || _vm$maps$_vm$mapIndex3 === void 0 ? void 0 : _vm$maps$_vm$mapIndex3.searches.push(_vm.search);
      }
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "column"
  }, [_c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Save",
      "label-position": ""
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search Name"
    },
    model: {
      value: _vm.search.name,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  }), _c('p', {
    staticClass: "control"
  }, [!!_vm.searches.find(function (p) {
    return p.time === _vm.search.time;
  }) ? _c('b-button', {
    staticClass: "field",
    attrs: {
      "label": "Update Search",
      "type": "is-success",
      "icon-left": "floppy",
      "disabled": !_vm.search.name.length || _vm.isEqual(_vm.search, _vm.origSearch)
    },
    on: {
      "click": function click($event) {
        _vm.searches.splice(_vm.searches.findIndex(function (p) {
          return p.time === _vm.search.time;
        }), 1, Object.assign({}, _vm.search));
      }
    }
  }) : _c('b-button', {
    staticClass: "field",
    attrs: {
      "label": "Save Search",
      "type": "is-success",
      "icon-left": "floppy",
      "disabled": !_vm.search.name.length || _vm.isEqual(_vm.search, _vm.freshSearch)
    },
    on: {
      "click": function click($event) {
        _vm.search = Object.assign({}, _vm.search, {
          time: _vm.time()
        });

        _vm.searches.push(_vm.search);

        _vm.origSearch = _vm.cloneDeep(_vm.search);
      }
    }
  })], 1)], 1)], 1)])], 1)])]), _c('b-modal', {
    attrs: {
      "scroll": "keep"
    },
    model: {
      value: _vm.openPlans,
      callback: function callback($$v) {
        _vm.openPlans = $$v;
      },
      expression: "openPlans"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s((_vm$settings8 = _vm.settings) === null || _vm$settings8 === void 0 ? void 0 : _vm$settings8.labels.plans))]), _c('b-field', [_c('b-select', {
    attrs: {
      "placeholder": "Commission Field"
    },
    model: {
      value: _vm.plan.planType,
      callback: function callback($$v) {
        _vm.$set(_vm.plan, "planType", $$v);
      },
      expression: "plan.planType"
    }
  }, _vm._l(_vm.planTypes, function (p) {
    return _c('option', {
      key: p.name,
      domProps: {
        "value": p
      }
    }, [_vm._v(_vm._s(p.name))]);
  }), 0), ((_vm$plan$planType = _vm.plan.planType) === null || _vm$plan$planType === void 0 ? void 0 : _vm$plan$planType.field) === "lineItem" ? _c('b-input', {
    attrs: {
      "placeholder": "Line Item Search"
    },
    model: {
      value: _vm.plan.lineItemSearch,
      callback: function callback($$v) {
        _vm.$set(_vm.plan, "lineItemSearch", $$v);
      },
      expression: "plan.lineItemSearch"
    }
  }) : _vm._e(), ((_vm$plan$planType2 = _vm.plan.planType) === null || _vm$plan$planType2 === void 0 ? void 0 : _vm$plan$planType2.field) === "lineItem" ? _c('b-select', {
    attrs: {
      "placeholder": "Line Item Field"
    },
    model: {
      value: _vm.plan.lineItemField,
      callback: function callback($$v) {
        _vm.$set(_vm.plan, "lineItemField", $$v);
      },
      expression: "plan.lineItemField"
    }
  }, _vm._l(["lineAmount", "lineMargin", "lineMarginCut"], function (f) {
    return _c('option', {
      key: f,
      domProps: {
        "value": f
      }
    }, [_vm._v(_vm._s(_vm.startCase(f).replace('Cut', '%')))]);
  }), 0) : _vm._e(), _c('b-datepicker', {
    attrs: {
      "placeholder": "Date range... (optional)",
      "range": ""
    },
    model: {
      value: _vm.plan.dates,
      callback: function callback($$v) {
        _vm.$set(_vm.plan, "dates", $$v);
      },
      expression: "plan.dates"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('b-button', {
    attrs: {
      "label": "Financial Year",
      "icon-left": "calendar-today"
    },
    on: {
      "click": function click($event) {
        _vm.plan.dates = _vm.eofyDates;
      }
    }
  }), _c('b-button', {
    attrs: {
      "label": "Clear",
      "icon-left": "close",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.plan.dates = [];
      }
    }
  })], 1)])], 1), _c('hr'), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v("Commission Tiers")]), _vm._l(_vm.plan.tiers, function (tier, index) {
    var _vm$plan$planType3, _vm$plan$lineItemFiel;

    return _c('b-field', {
      key: "tier" + index
    }, [_c('p', {
      staticClass: "control"
    }, [_c('b-field', {
      attrs: {
        "label": "From",
        "label-position": "on-border"
      }
    }, [_c('b-input', {
      attrs: {
        "placeholder": "e.g. 5000",
        "type": "number",
        "min": "1",
        "max": (_vm$plan$planType3 = _vm.plan.planType) !== null && _vm$plan$planType3 !== void 0 && _vm$plan$planType3.name.includes("%") || (_vm$plan$lineItemFiel = _vm.plan.lineItemField) !== null && _vm$plan$lineItemFiel !== void 0 && _vm$plan$lineItemFiel.includes("Cut") ? 100 : 1000000000
      },
      model: {
        value: tier.from,
        callback: function callback($$v) {
          _vm.$set(tier, "from", $$v);
        },
        expression: "tier.from"
      }
    })], 1)], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-field', {
      attrs: {
        "label": "Flat " + _vm.symbol,
        "label-position": "on-border"
      }
    }, [_c('b-input', {
      attrs: {
        "placeholder": "e.g. 250",
        "type": "number",
        "min": "0"
      },
      model: {
        value: tier.flat,
        callback: function callback($$v) {
          _vm.$set(tier, "flat", $$v);
        },
        expression: "tier.flat"
      }
    })], 1)], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-field', {
      attrs: {
        "label": "Cut %",
        "label-position": "on-border"
      }
    }, [_c('b-input', {
      attrs: {
        "placeholder": "e.g. 3.5",
        "type": "number",
        "min": "0",
        "max": "100",
        "width": "300"
      },
      model: {
        value: tier.cut,
        callback: function callback($$v) {
          _vm.$set(tier, "cut", $$v);
        },
        expression: "tier.cut"
      }
    }), _c('p', {
      staticClass: "control"
    }, [_vm.plan.tiers.length > 1 ? _c('b-button', {
      staticClass: "field",
      attrs: {
        "type": "is-danger",
        "icon-left": "delete"
      },
      on: {
        "click": function click($event) {
          _vm.confirm(function () {
            return _vm.plan.tiers.splice(index, 1);
          });
        }
      }
    }) : _vm._e()], 1)], 1)], 1)]);
  }), _c('b-button', {
    staticClass: "field",
    attrs: {
      "label": "Add Tier",
      "type": "is-primary",
      "icon-left": "plus"
    },
    on: {
      "click": function click($event) {
        return _vm.plan.tiers.push(Object.assign({}, _vm.freshTier));
      }
    }
  }), _c('hr'), _vm.plans && _vm.plans.length ? _c('b-field', {
    attrs: {
      "label": "Load Plan",
      "label-position": "",
      "grouped": "",
      "group-multiline": ""
    }
  }, [_vm._l(_vm.plans, function (p, index) {
    return _c('b-field', {
      key: index,
      staticClass: "mb-3"
    }, [_c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "label": p.name,
        "type": "is-primary",
        "icon-left": "play"
      },
      on: {
        "click": function click($event) {
          _vm.plan = _vm.cloneDeep(p);
          _vm.origPlan = _vm.cloneDeep(p);
        }
      }
    })], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "type": "is-success",
        "icon-left": "content-copy"
      },
      on: {
        "click": function click($event) {
          _vm.plan = Object.assign({}, _vm.cloneDeep(p), {
            time: null,
            name: p.name + " copy"
          });
        }
      }
    })], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      staticClass: "field",
      attrs: {
        "type": "is-danger",
        "icon-left": "delete"
      },
      on: {
        "click": function click($event) {
          _vm.confirm(function () {
            return _vm.removePlan(index);
          });
        }
      }
    })], 1)]);
  }), _c('b-button', {
    staticClass: "field",
    attrs: {
      "type": "is-danger",
      "icon-left": "new-box",
      "label": "New"
    },
    on: {
      "click": function click($event) {
        _vm.confirm(function () {
          return _vm.plan = _vm.cloneDeep(_vm.freshPlan);
        });
      }
    }
  })], 2) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('b-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.plan.name.length > 0,
      expression: "plan.name.length > 0"
    }]
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.plan.time || _vm.isEqual(_vm.plan, _vm.freshPlan) || !_vm.isEqual(_vm.plan, _vm.origPlan) || !!((_vm$maps$_vm$mapIndex4 = _vm.maps[_vm.mapIndex]) !== null && _vm$maps$_vm$mapIndex4 !== void 0 && _vm$maps$_vm$mapIndex4.plans.find(function (p) {
        return _vm.isEqual(p, _vm.plan);
      })),
      "type": "is-success",
      "icon-left": "filter",
      "label": !!((_vm$maps$_vm$mapIndex5 = _vm.maps[_vm.mapIndex]) !== null && _vm$maps$_vm$mapIndex5 !== void 0 && _vm$maps$_vm$mapIndex5.plans.find(function (p) {
        return _vm.isEqual(p, _vm.plan);
      })) ? "\"".concat(_vm.plan.name, "\" already selected") : "Add \"".concat(_vm.plan.name, "\" to selected ").concat((_vm$settings9 = _vm.settings) === null || _vm$settings9 === void 0 ? void 0 : _vm$settings9.labels.plans)
    },
    on: {
      "click": function click($event) {
        _vm.openPlans = false;

        _vm.$refs.mapPlans[0].addTag(_vm.plan);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "column"
  }, [_c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Save",
      "label-position": ""
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Plan Name"
    },
    model: {
      value: _vm.plan.name,
      callback: function callback($$v) {
        _vm.$set(_vm.plan, "name", $$v);
      },
      expression: "plan.name"
    }
  }), _c('p', {
    staticClass: "control"
  }, [_c('b-field', [!!_vm.plans.find(function (p) {
    return p.time === _vm.plan.time;
  }) ? _c('b-button', {
    staticClass: "field",
    attrs: {
      "label": "Update Plan",
      "type": "is-success",
      "icon-left": "floppy",
      "disabled": !_vm.plan.name.length || _vm.isEqual(_vm.plan, _vm.origPlan) || !_vm.plan.tiers.every(function (e) {
        return e.from && (e.flat || e.cut);
      }) || _vm.plan.planType.field === "lineItem" && (!_vm.plan.lineItemSearch || !_vm.plan.lineItemField)
    },
    on: {
      "click": function click($event) {
        _vm.plans.splice(_vm.plans.findIndex(function (p) {
          return p.time === _vm.plan.time;
        }), 1, Object.assign({}, _vm.plan));
      }
    }
  }) : _c('b-button', {
    staticClass: "field",
    attrs: {
      "label": "Save Plan",
      "type": "is-success",
      "icon-left": "floppy",
      "disabled": !_vm.plan.name.length || _vm.isEqual(_vm.plan, _vm.freshPlan) || !_vm.plan.tiers.every(function (e) {
        return e.from && (e.flat || e.cut);
      })
    },
    on: {
      "click": function click($event) {
        _vm.plan = Object.assign({}, _vm.plan, {
          time: _vm.time()
        });

        _vm.plans.push(_vm.plan);

        _vm.origPlan = _vm.cloneDeep(_vm.plan);
      }
    }
  })], 1)], 1)], 1)], 1)])], 2)])]), _c('section', {
    staticClass: "section"
  }, [_c('b-notification', {
    attrs: {
      "type": "is-primary",
      "has-icon": "",
      "icon": "human-greeting",
      "aria-close-label": "Close notification",
      "role": "alert"
    }
  }, [_c('p', [_vm._v("G'day " + _vm._s(_vm.user.displayName) + ", welcome to Commissionaire!")]), _c('p', [_vm._v("Define your " + _vm._s((_vm$settings10 = _vm.settings) === null || _vm$settings10 === void 0 ? void 0 : _vm$settings10.labels.searches) + "/" + _vm._s((_vm$settings11 = _vm.settings) === null || _vm$settings11 === void 0 ? void 0 : _vm$settings11.labels.plans) + "/" + _vm._s((_vm$settings12 = _vm.settings) === null || _vm$settings12 === void 0 ? void 0 : _vm$settings12.labels.contacts) + " combinations below and click the Commissions button to view.")])]), _vm._l(_vm.maps, function (map, index) {
    var _vm$settings13, _vm$settings16, _vm$settings19, _vm$getMapsContacts, _map$contacts, _map$searches, _map$plans, _map$contacts2, _map$contacts3, _map$searches2, _map$plans2;

    return _vm.maps ? _c('div', {
      key: "map" + index,
      staticClass: "columns"
    }, [_c('div', {
      staticClass: "column"
    }, [_c('b-taginput', {
      ref: "mapSearches",
      refInFor: true,
      attrs: {
        "data": _vm.filteredSearches,
        "field": "name",
        "autocomplete": "",
        "open-on-focus": true,
        "icon": "label",
        "placeholder": (_vm$settings13 = _vm.settings) === null || _vm$settings13 === void 0 ? void 0 : _vm$settings13.labels.searches,
        "selectable-footer": true
      },
      on: {
        "typing": _vm.getFilteredSearches,
        "input": function input($event) {
          _vm.setRef("mapSearches", index);

          _vm.mapSearches = _vm.cloneDeep(map.searches);

          _vm.getFilteredSearches("");

          _vm.search = _vm.mergeSearches(_vm.flatSearches);
        },
        "focus": function focus($event) {
          _vm.setRef("mapSearches", index);

          _vm.getFilteredSearches("");
        },
        "select-footer": function selectFooter($event) {
          _vm.openSearches = true;
          _vm.search = _vm.cloneDeep(_vm.freshSearch);
        }
      },
      scopedSlots: _vm._u([{
        key: "tag",
        fn: function fn(props) {
          return [_c('a', {
            on: {
              "click": function click($event) {
                _vm.search = props.tag;
                _vm.origSearch = _vm.cloneDeep(props.tag);
                _vm.openSearches = true;
              }
            }
          }, [_vm._v(_vm._s(props.tag.name))])];
        }
      }, {
        key: "empty",
        fn: function fn() {
          var _vm$settings14;

          return [_c('span', [_vm._v("No " + _vm._s((_vm$settings14 = _vm.settings) === null || _vm$settings14 === void 0 ? void 0 : _vm$settings14.labels.searches))])];
        },
        proxy: true
      }, {
        key: "footer",
        fn: function fn() {
          var _vm$settings15;

          return [_c('b-button', {
            attrs: {
              "expanded": "",
              "size": "is-small"
            },
            on: {
              "click": function click($event) {
                _vm.mapIndex = index;
              }
            }
          }, [_vm._v("Create/Edit " + _vm._s((_vm$settings15 = _vm.settings) === null || _vm$settings15 === void 0 ? void 0 : _vm$settings15.labels.searches) + "...")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: map.searches,
        callback: function callback($$v) {
          _vm.$set(map, "searches", $$v);
        },
        expression: "map.searches"
      }
    })], 1), _c('div', {
      staticClass: "column"
    }, [_c('b-taginput', {
      ref: "mapPlans",
      refInFor: true,
      attrs: {
        "data": _vm.filteredPlans,
        "field": "name",
        "autocomplete": "",
        "open-on-focus": true,
        "icon": "label",
        "placeholder": (_vm$settings16 = _vm.settings) === null || _vm$settings16 === void 0 ? void 0 : _vm$settings16.labels.plans,
        "selectable-footer": true
      },
      on: {
        "typing": _vm.getFilteredPlans,
        "input": function input($event) {
          _vm.setRef("mapPlans", index);

          _vm.getFilteredPlans("");
        },
        "focus": function focus($event) {
          _vm.setRef("mapPlans", index);

          _vm.getFilteredPlans("");
        },
        "select-footer": function selectFooter($event) {
          _vm.openPlans = true;
          _vm.plan = _vm.cloneDeep(_vm.freshPlan);
        }
      },
      scopedSlots: _vm._u([{
        key: "tag",
        fn: function fn(props) {
          return [_c('a', {
            on: {
              "click": function click($event) {
                _vm.plan = props.tag;
                _vm.origPlan = _vm.cloneDeep(props.tag);
                _vm.openPlans = true;
              }
            }
          }, [_vm._v(_vm._s(props.tag.name))])];
        }
      }, {
        key: "empty",
        fn: function fn() {
          var _vm$settings17;

          return [_c('span', [_vm._v("No " + _vm._s((_vm$settings17 = _vm.settings) === null || _vm$settings17 === void 0 ? void 0 : _vm$settings17.labels.plans))])];
        },
        proxy: true
      }, {
        key: "footer",
        fn: function fn() {
          var _vm$settings18;

          return [_c('b-button', {
            attrs: {
              "expanded": "",
              "size": "is-small"
            },
            on: {
              "click": function click($event) {
                _vm.mapIndex = index;
              }
            }
          }, [_vm._v("Create/Edit " + _vm._s((_vm$settings18 = _vm.settings) === null || _vm$settings18 === void 0 ? void 0 : _vm$settings18.labels.plans) + "...")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: map.plans,
        callback: function callback($$v) {
          _vm.$set(map, "plans", $$v);
        },
        expression: "map.plans"
      }
    })], 1), _c('div', {
      staticClass: "column"
    }, [_c('b-field', [_c('p', {
      staticClass: "control"
    }, [_c('b-taginput', {
      ref: "mapContacts",
      refInFor: true,
      attrs: {
        "data": _vm.getMaxTags(map) <= _vm.getMapsContacts().length ? _vm.getMapsContacts() : _vm.filteredContacts,
        "field": "name",
        "autocomplete": "",
        "open-on-focus": true,
        "icon": "label",
        "placeholder": (_vm$settings19 = _vm.settings) === null || _vm$settings19 === void 0 ? void 0 : _vm$settings19.labels.contacts,
        "maxtags": _vm.getMaxTags(map) || ((_vm$getMapsContacts = _vm.getMapsContacts()) === null || _vm$getMapsContacts === void 0 ? void 0 : _vm$getMapsContacts.length),
        "has-counter": false
      },
      on: {
        "typing": function typing(text) {
          return _vm.getFilteredContacts(text);
        },
        "input": function input($event) {
          _vm.setRef("mapContacts", index);

          _vm.getFilteredContacts("");
        },
        "focus": function focus($event) {
          _vm.setRef("mapContacts", index);

          _vm.getFilteredContacts("");
        }
      },
      scopedSlots: _vm._u([{
        key: "tag",
        fn: function fn(props) {
          return [!props.tag.emailAddress ? _c('b-tooltip', {
            attrs: {
              "position": "is-top",
              "type": "is-danger",
              "always": ""
            },
            scopedSlots: _vm._u([{
              key: "content",
              fn: function fn() {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "email",
                    "size": "is-small",
                    "type": "is-white"
                  }
                }), _c('span', {
                  staticClass: "has-text-white is-size-7"
                }, [_vm._v("No email")])];
              },
              proxy: true
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s(props.tag.name))])]) : _c('span', [_vm._v(_vm._s(props.tag.name))])];
        }
      }, {
        key: "empty",
        fn: function fn() {
          var _vm$settings20;

          return [_c('span', [_vm._v("No " + _vm._s((_vm$settings20 = _vm.settings) === null || _vm$settings20 === void 0 ? void 0 : _vm$settings20.labels.contacts))])];
        },
        proxy: true
      }], null, true),
      model: {
        value: map.contacts,
        callback: function callback($$v) {
          _vm.$set(map, "contacts", $$v);
        },
        expression: "map.contacts"
      }
    })], 1)])], 1), _c('div', {
      staticClass: "column"
    }, [_c('b-field', {
      attrs: {
        "position": "is-right"
      }
    }, [_c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      attrs: {
        "disabled": _vm.maps.length == 1,
        "type": "is-danger",
        "icon-left": "delete"
      },
      on: {
        "click": function click($event) {
          _vm.confirm(function () {
            return _vm.maps.splice(index, 1);
          });
        }
      }
    })], 1), _c('p', {
      staticClass: "control"
    }, [_c('b-checkbox-button', {
      attrs: {
        "disabled": !((_map$contacts = map.contacts) !== null && _map$contacts !== void 0 && _map$contacts.length && (_map$searches = map.searches) !== null && _map$searches !== void 0 && _map$searches.length && (_map$plans = map.plans) !== null && _map$plans !== void 0 && _map$plans.length),
        "native-value": true,
        "type": "is-success"
      },
      on: {
        "input": function input($event) {
          return _vm.mapViewToggle(map);
        }
      },
      model: {
        value: map.view,
        callback: function callback($$v) {
          _vm.$set(map, "view", $$v);
        },
        expression: "map.view"
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "cash-100"
      }
    }), _c('span', [_vm._v("Commissions")])], 1)], 1), ((_map$contacts2 = map.contacts) === null || _map$contacts2 === void 0 ? void 0 : _map$contacts2.length) > 1 ? _c('p', {
      staticClass: "control"
    }, [_c('b-checkbox-button', {
      attrs: {
        "disabled": !((_map$contacts3 = map.contacts) !== null && _map$contacts3 !== void 0 && _map$contacts3.length && (_map$searches2 = map.searches) !== null && _map$searches2 !== void 0 && _map$searches2.length && (_map$plans2 = map.plans) !== null && _map$plans2 !== void 0 && _map$plans2.length),
        "native-value": true,
        "type": "is-success"
      },
      model: {
        value: map.split,
        callback: function callback($$v) {
          _vm.$set(map, "split", $$v);
        },
        expression: "map.split"
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "chart-pie"
      }
    }), _c('span', [_vm._v("Split")])], 1)], 1) : _vm._e(), _c('p', {
      staticClass: "control"
    }, [_c('b-button', {
      attrs: {
        "disabled": map.contacts.length === 0 || !map.view || !map.contacts.every(function (c) {
          return c.emailAddress;
        }),
        "type": "is-primary"
      },
      on: {
        "click": function click($event) {
          var _vm$settings21;

          _vm.confirm(function () {
            return _vm.emailUsers(map.contacts);
          }, "Send email link to ".concat((_vm$settings21 = _vm.settings) === null || _vm$settings21 === void 0 ? void 0 : _vm$settings21.labels.contacts, "?"));
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "email",
        "type": "is-white"
      }
    })], 1)], 1)])], 1)]) : _vm._e();
  }), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('b-tooltip', {
    attrs: {
      "multilined": "",
      "position": "is-right"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$settings22;

        return [_c('span', [_vm._v("Update the quantity on your subscription plan to change the number of " + _vm._s((_vm$settings22 = _vm.settings) === null || _vm$settings22 === void 0 ? void 0 : _vm$settings22.labels.contacts))])];
      },
      proxy: true
    }], null, false, 734049576)
  }, [_c('a', {
    staticClass: "button is-ghost",
    attrs: {
      "href": "/plans"
    }
  }, [_vm._v(_vm._s(_vm.mapContacts.length) + "/" + _vm._s(_vm.maxContacts) + " " + _vm._s((_vm$settings23 = _vm.settings) === null || _vm$settings23 === void 0 ? void 0 : _vm$settings23.labels.contacts) + " used")])]), _c('br'), _c('b-button', {
    staticClass: "field",
    attrs: {
      "size": "is-small",
      "type": "is-ghost"
    },
    on: {
      "click": function click($event) {
        _vm.openSettings = true;
      }
    }
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "column has-text-right"
  }, [_c('b-button', {
    staticClass: "field",
    attrs: {
      "disabled": !_vm.maps.every(function (m) {
        return m.plans.length && m.searches.length && m.contacts.length;
      }),
      "label": "Add ".concat((_vm$settings24 = _vm.settings) === null || _vm$settings24 === void 0 ? void 0 : _vm$settings24.labels.searches, "/").concat((_vm$settings25 = _vm.settings) === null || _vm$settings25 === void 0 ? void 0 : _vm$settings25.labels.plans, "/").concat((_vm$settings26 = _vm.settings) === null || _vm$settings26 === void 0 ? void 0 : _vm$settings26.labels.contacts, " combination"),
      "type": "is-primary",
      "icon-left": "plus"
    },
    on: {
      "click": function click($event) {
        return _vm.maps.push(Object.assign({}, _vm.freshMap));
      }
    }
  }), _c('br'), _c('b-button', {
    staticClass: "field",
    attrs: {
      "disabled": !_vm.maps.every(function (m) {
        return m.plans.length && m.searches.length && m.contacts.length;
      }) || _vm.isEqual(_vm.maps, _vm.origMaps),
      "label": "Save combinations",
      "type": "is-success"
    },
    on: {
      "click": function click($event) {
        _vm.save({
          maps: _vm.maps
        });

        _vm.origMaps = _vm.cloneDeep(_vm.maps);
      }
    }
  })], 1)])], 2), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("Invoices"), _c('span', {
    staticClass: "subtitle"
  }, [_vm._v(" (" + _vm._s(_vm.filteredDataWithComms.length) + ")")]), _c('b-button', {
    attrs: {
      "size": "is-small",
      "type": "is-ghost"
    },
    on: {
      "click": function click($event) {
        return _vm.exportCSV();
      }
    }
  }, [_vm._v("Export CSV")])], 1)]), _c('div', {
    staticClass: "column has-text-right"
  }, [false ? _c('b-button', {
    staticClass: "mb-6",
    attrs: {
      "icon-left": "receipt"
    },
    on: {
      "click": function click($event) {
        return _vm.invoiceForm();
      }
    }
  }, [_vm._v("New Invoice")]) : _vm._e(), _vm.isWide ? _c('p', {
    staticClass: "subtitle is-size-7"
  }, [_vm._v("Hold the Shift key to scroll table sideways")]) : _vm._e()], 1)]), _c('b-collapse', {
    attrs: {
      "open": false
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('div', {
          staticClass: "has-text-right"
        }, [_c('b-button', {
          attrs: {
            "label": "Columns",
            "type": "is-ghost",
            "size": "is-small"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3986980627)
  }, [_c('div', {
    staticClass: "notification"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": ""
    }
  }, _vm._l(_vm.filteredVisible, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "control"
    }, [_c('b-checkbox', {
      model: {
        value: _vm.visible[key],
        callback: function callback($$v) {
          _vm.$set(_vm.visible, key, $$v);
        },
        expression: "visible[key]"
      }
    }, [_vm._v(_vm._s(_vm.startCase(key)))])], 1);
  }), 0)], 1)])]), _c('b-table', {
    attrs: {
      "data": _vm.filteredDataWithComms,
      "loading": _vm.loading,
      "scrollable": "",
      "paginated": "",
      "pagination-simple": "",
      "total": _vm.total,
      "per-page": _vm.perPage,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page",
      "default-sort-direction": _vm.defaultSortOrder,
      "default-sort": [_vm.sortField, _vm.sortOrder],
      "detailed": "",
      "detail-key": "invoiceID"
    },
    scopedSlots: _vm._u([{
      key: "detail",
      fn: function fn(props) {
        return [_c('p', {
          staticClass: "subtitle"
        }, [_vm._v("Line Items")]), _c('b-table', {
          attrs: {
            "data": props.row.lineItems
          }
        }, [_c('b-table-column', {
          attrs: {
            "field": "accountCode",
            "label": "Account Code"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.accountCode))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "itemCode",
            "label": "Item Code"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.itemCode))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "description",
            "label": "Description",
            "width": "33vw"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_c('div', {
                staticClass: "pre"
              }, [_vm._v(_vm._s(props.row.description))])];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "unitAmount",
            "label": "Unit Amount",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.unitAmount))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "unitCost",
            "label": "Unit Cost",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.unitCost))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "quantity",
            "label": "Quantity",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.quantity))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "lineAmount",
            "label": "Line Amount",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [props.row.lineAmount > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.lineAmount, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "lineCost",
            "label": "Line Cost",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [props.row.lineCost > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.lineCost, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "lineMargin",
            "label": "Line Margin",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [props.row.lineMargin > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.lineMargin, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "lineMarginCut",
            "label": "Line Margin %",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [props.row.lineMarginCut > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.lineMarginCut, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
            }
          }], null, true)
        })], 1)];
      }
    }], null, false, 1397966912)
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "invoiceNumber",
      "label": "Number",
      "visible": _vm.visible["invoiceNumber"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.invoiceNumber)) + " "), _c('a', {
          attrs: {
            "href": _vm.link(props.row),
            "target": "_blank"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "open-in-new"
          }
        })], 1)];
      }
    }], null, false, 1024628457)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "reference",
      "label": "Ref",
      "visible": _vm.visible["reference"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.reference)))];
      }
    }], null, false, 1001271009)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "contact.name",
      "label": "To",
      "visible": _vm.visible["contact.name"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.contact.name)))];
      }
    }], null, false, 3325421347)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "date",
      "label": "Date",
      "width": "150",
      "visible": _vm.visible["date"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.date ? _vm.dayjs(props.row.date).format('D MMM YYYY') : ''))];
      }
    }], null, false, 4077751741)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "dueDate",
      "label": "Due Date",
      "width": "150",
      "visible": _vm.visible["dueDate"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.dueDate ? _vm.dayjs(props.row.dueDate).format('D MMM YYYY') : ''))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v("Totals:")];
      }
    }], null, false, 2570957836)
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "amountPaid",
      "label": "Paid",
      "numeric": "",
      "visible": _vm.visible["amountPaid"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.amountPaid, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('amountPaid'), _vm.symbol)))];
      }
    }], null, false, 3244237657)
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "amountDue",
      "label": "Due",
      "numeric": "",
      "visible": _vm.visible["amountDue"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.amountDue, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('amountDue'), _vm.symbol)))];
      }
    }], null, false, 809492761)
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "subTotal",
      "label": "Subtotal",
      "numeric": "",
      "visible": _vm.visible["subTotal"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.subTotal, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('subTotal'), _vm.symbol)))];
      }
    }], null, false, 443074713)
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "totalTax",
      "label": "Tax",
      "numeric": "",
      "visible": _vm.visible["totalTax"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.totalTax, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('totalTax'), _vm.symbol)))];
      }
    }], null, false, 1923099385)
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "total",
      "label": "Total",
      "numeric": "",
      "visible": _vm.visible["total"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.total, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('total'), _vm.symbol)))];
      }
    }], null, false, 2841749273)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "status",
      "label": "Status",
      "visible": _vm.visible["status"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm.niceStatus(props.row.status)))];
      }
    }], null, false, 489772093)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "sentToContact",
      "label": "Sent",
      "visible": _vm.visible["sentToContact"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.sentToContact ? 'Sent' : ''))];
      }
    }], null, false, 4016228227)
  }), _c('b-table-column', {
    attrs: {
      "field": "cost",
      "label": "Cost",
      "numeric": "",
      "width": "100",
      "visible": _vm.visible["cost"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.cost > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.cost, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('cost'), _vm.symbol)))];
      }
    }], null, false, 458281305)
  }), _c('b-table-column', {
    attrs: {
      "field": "margin",
      "label": "Margin",
      "numeric": "",
      "width": "100",
      "visible": _vm.visible["margin"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.margin > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("currency")(props.row.margin, _vm.symbol)))]) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('margin'), _vm.symbol)))];
      }
    }], null, false, 1312771916)
  }), _c('b-table-column', {
    attrs: {
      "field": "marginCut",
      "label": "Margin %",
      "numeric": "",
      "width": "100",
      "visible": _vm.visible["marginCut"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.marginCut > 0 ? _c('span', [_vm._v(_vm._s(props.row.marginCut.toFixed(2)) + "%")]) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s((_vm.sumRows('margin') / _vm.sumRows('subTotal') * 100).toFixed(2)) + "%")];
      }
    }], null, false, 2662390604)
  }), _c('b-table-column', {
    attrs: {
      "label": "Commission",
      "width": "200",
      "field": "commission",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["commission"] && _vm.selectedPlans.length > 0
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.commission, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('commission'), _vm.symbol)))];
      }
    }], null, false, 93119097)
  }), _c('b-table-column', {
    attrs: {
      "label": "Cumulative",
      "width": "200",
      "field": "cumulative",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["cumulative"] && _vm.selectedPlans.length > 0
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.cumulative, _vm.symbol)))];
      }
    }], null, false, 3699254180)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Split",
      "width": "200",
      "visible": _vm.visible["split"] && _vm.selectedPlans.length > 0
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.split, function (split) {
          return _c('b-tag', {
            key: split
          }, [_vm._v(_vm._s(split))]);
        });
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm.selectedSplit[0] ? '✔' : '✖'))];
      }
    }], null, false, 264205115)
  }), _c('b-table-column', {
    attrs: {
      "label": "Commission Level 2",
      "width": "200",
      "field": "commission2",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["commission2"] && _vm.selectedPlans.length > 0 && !!_vm.selectedPlans.find(function (p) {
        return p.planType.field === "cumulative";
      })
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.commission2, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('commission2'), _vm.symbol)))];
      }
    }], null, false, 3607570137)
  }), _c('b-table-column', {
    attrs: {
      "label": "Cumulative Level 2",
      "width": "200",
      "field": "cumulative2",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["cumulative2"] && _vm.selectedPlans.length > 0 && !!_vm.selectedPlans.find(function (p) {
        return p.planType.field === "cumulative";
      })
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.cumulative2, _vm.symbol)))];
      }
    }], null, false, 3577466102)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Split Level 2",
      "width": "200",
      "visible": _vm.visible["split2"] && _vm.selectedPlans.length > 0 && !!_vm.selectedPlans.find(function (p) {
        return p.planType.field === "cumulative";
      })
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.split2, function (split2) {
          return _c('b-tag', {
            key: split2
          }, [_vm._v(_vm._s(split2))]);
        });
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm.selectedSplit[0] ? '✔' : '✖'))];
      }
    }], null, false, 1482067835)
  })], 1)], 1), _c('Footer')], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }