<template lang="pug">
#app
  router-view
</template>

<script>
export default {
  name: 'App',
  metaInfo () {
    return {
      titleTemplate: (title) => title ? `${title} | Commissionaire` : 'Commissionaire'
    }
  }
  // metaInfo: {
  //   title: '404',
  //   titleTemplate: '%s | Commissionaire'
  // }
}
</script>
