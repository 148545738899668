import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import './vee-validate'
import VueMeta from 'vue-meta'
// import { firestorePlugin } from 'vuefire'
// import Firebase from './plugins/firebase'
import Popup from '@/plugins/popup'
import axios from 'axios'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(Chartkick.use(Chart))

Vue.prototype.$http = axios

Vue.use(Popup)
// Vue.use(Firebase)
Vue.use(Buefy)
Vue.use(VueMeta)
// Vue.use(firestorePlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
