export default {
  install (Vue, options) {
    Vue.mixin({
      created: function () {
        if (this.$parent === undefined) { // run in root Vue only not VueComponent children (see console.log(this))
          const searchParams = new URLSearchParams(window.location.search)
          console.log('sub...?', searchParams.has('sub'))
        }
      }
    })
  }
}
