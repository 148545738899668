var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-navbar', {
    attrs: {
      "close-on-click": false
    },
    scopedSlots: _vm._u([{
      key: "brand",
      fn: function fn() {
        return [_c('b-navbar-item', [_c('img', {
          attrs: {
            "src": require("@/assets/commissionaire-logo.png"),
            "alt": "Commissionaire"
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "has-text-primary is-size-4 is-uppercase has-text-weight-bold"
        }, [_vm._v("Commissionaire")])])];
      },
      proxy: true
    }, {
      key: "start",
      fn: function fn() {
        return [_vm.user ? _c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('span', [_vm._v("View for " + _vm._s([_vm.user.name, _vm.user.emailAddress].join(', ')))])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "end",
      fn: function fn() {
        return [_c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('div', {
          staticClass: "buttons"
        }, [_c('a', {
          staticClass: "button is-primary",
          attrs: {
            "href": "/"
          }
        }, [_c('strong', [_vm._v("Sign up")])])])])];
      },
      proxy: true
    }])
  }), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('Chart', {
    attrs: {
      "invoices": _vm.invoices
    }
  })], 1)]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("Invoices"), _c('span', {
    staticClass: "subtitle"
  }, [_vm._v(" (" + _vm._s(_vm.invoices.length) + ")")]), _c('b-button', {
    attrs: {
      "size": "is-small",
      "type": "is-ghost"
    },
    on: {
      "click": function click($event) {
        return _vm.exportCSV();
      }
    }
  }, [_vm._v("Export CSV")])], 1), _vm.isWide ? _c('p', {
    staticClass: "has-text-right subtitle is-size-7"
  }, [_vm._v("Hold the Shift key to scroll table sideways")]) : _vm._e(), _c('b-collapse', {
    attrs: {
      "open": false
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('div', {
          staticClass: "has-text-right"
        }, [_c('b-button', {
          attrs: {
            "label": "Columns",
            "type": "is-ghost",
            "size": "is-small"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "notification"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": ""
    }
  }, _vm._l(_vm.visible, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "control"
    }, [_c('b-checkbox', {
      model: {
        value: _vm.visible[key],
        callback: function callback($$v) {
          _vm.$set(_vm.visible, key, $$v);
        },
        expression: "visible[key]"
      }
    }, [_vm._v(_vm._s(_vm.startCase(key)))])], 1);
  }), 0)], 1)])]), _c('b-table', {
    attrs: {
      "data": _vm.invoices,
      "loading": _vm.loading,
      "scrollable": "",
      "paginated": "",
      "pagination-simple": "",
      "total": _vm.total,
      "per-page": _vm.perPage,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page",
      "default-sort-direction": _vm.defaultSortOrder,
      "default-sort": [_vm.sortField, _vm.sortOrder],
      "detailed": "",
      "detail-key": "invoiceID"
    },
    scopedSlots: _vm._u([{
      key: "detail",
      fn: function fn(props) {
        return [_c('p', {
          staticClass: "subtitle"
        }, [_vm._v("Line Items")]), _c('b-table', {
          attrs: {
            "data": props.row.lineItems
          }
        }, [_c('b-table-column', {
          attrs: {
            "field": "accountCode",
            "label": "Account Code"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.accountCode))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "itemCode",
            "label": "Item Code"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.itemCode))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "description",
            "label": "Description",
            "width": "33vw"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_c('div', {
                staticClass: "pre"
              }, [_vm._v(_vm._s(props.row.description))])];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "unitAmount",
            "label": "Unit Amount",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.unitAmount))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "quantity",
            "label": "Quantity",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.quantity))];
            }
          }], null, true)
        }), _c('b-table-column', {
          attrs: {
            "field": "lineAmount",
            "label": "Line Amount",
            "numeric": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(props) {
              return [_vm._v(_vm._s(props.row.lineAmount))];
            }
          }], null, true)
        })], 1)];
      }
    }])
  }, [_c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "invoiceNumber",
      "label": "Number",
      "visible": _vm.visible["invoiceNumber"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.invoiceNumber)) + " "), _c('a', {
          attrs: {
            "href": _vm.link(props.row),
            "target": "_blank"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "open-in-new"
          }
        })], 1)];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "reference",
      "label": "Ref",
      "visible": _vm.visible["reference"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.reference)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "contact.name",
      "label": "To",
      "visible": _vm.visible["contact.name"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("truncate")(props.row.contact.name)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "date",
      "label": "Date",
      "width": "150",
      "visible": _vm.visible["date"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.date ? _vm.dayjs(props.row.date).format('D MMM YYYY') : ''))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "dueDate",
      "label": "Due Date",
      "width": "150",
      "visible": _vm.visible["dueDate"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.dueDate ? _vm.dayjs(props.row.dueDate).format('D MMM YYYY') : ''))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v("Totals:")];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "amountPaid",
      "label": "Paid",
      "numeric": "",
      "visible": _vm.visible["amountPaid"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.amountPaid, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('amountPaid'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "amountDue",
      "label": "Due",
      "numeric": "",
      "visible": _vm.visible["amountDue"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.amountDue, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('amountDue'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "subTotal",
      "label": "Subtotal",
      "numeric": "",
      "visible": _vm.visible["subTotal"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.subTotal, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('subTotal'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "totalTax",
      "label": "Tax",
      "numeric": "",
      "visible": _vm.visible["totalTax"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.totalTax, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('totalTax'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "sortable": "",
      "field": "total",
      "label": "Total",
      "numeric": "",
      "visible": _vm.visible["total"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.total, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('total'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "status",
      "label": "Status",
      "visible": _vm.visible["status"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm.niceStatus(props.row.status)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "sortable": "",
      "field": "sentToContact",
      "label": "Sent",
      "visible": _vm.visible["sentToContact"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props.row.sentToContact ? 'Sent' : ''))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "label": "Commission",
      "width": "200",
      "field": "commission",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["commission"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.commission, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('commission'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "label": "Cumulative",
      "width": "200",
      "field": "cumulative",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["cumulative"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.cumulative, _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Split",
      "width": "200",
      "visible": _vm.visible["split"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.split, function (split) {
          return _c('b-tag', {
            key: split
          }, [_vm._v(_vm._s(split))]);
        });
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "label": "Commission Level 2",
      "width": "200",
      "field": "commission2",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["commission2"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.commission2, _vm.symbol)))];
      }
    }, {
      key: "subheading",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(_vm.sumRows('commission2'), _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "label": "Cumulative Level 2",
      "width": "200",
      "field": "cumulative2",
      "numeric": "",
      "sortable": "",
      "visible": _vm.visible["cumulative2"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(_vm._s(_vm._f("currency")(props.row.cumulative2, _vm.symbol)))];
      }
    }])
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Split Level 2",
      "width": "200",
      "visible": _vm.visible["split2"]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.split2, function (split2) {
          return _c('b-tag', {
            key: split2
          }, [_vm._v(_vm._s(split2))]);
        });
      }
    }])
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }