<template lang="pug">
b-navbar(
  :close-on-click='false'
)
  template(#brand)
    b-navbar-item
      img(src='@/assets/commissionaire-logo.png' alt='Commissionaire')
      | &nbsp;
      .has-text-primary.is-size-4.is-uppercase.has-text-weight-bold Commissionaire
  template(#start)

    //- b-navbar-item(tag='div')
    //-   span G'day {{ user.displayName }}
    //- b-navbar-item(tag='div')
    //-   span {{ user.email }}

    //- b-navbar-item(href='#')
    //-   | Home
    //- b-navbar-item(href='#')
    //-   | Documentation
    //- b-navbar-dropdown(label='Info')
    //-   b-navbar-item(href='#')
    //-     | About
    //-   b-navbar-item(href='#')
    //-     | Contact
  template(#end)
    //- b-navbar-item(tag='div')
    //-   .buttons
    //-     a.button.is-primary
    //-       strong Sign up
    //-     a.button.is-light
    //-       | Log in

    b-navbar-item(tag='div')
      span Connected to Xero as {{ user.displayName }}

    b-navbar-item(tag='div')
      b-field(
      )
        //- horizontal
        //- label='Organisation'
        b-select(
          v-model='activeTenantId'
          @input='$emit("updateTenantId", activeTenantId)'
        )
          option(
            v-for='t in tenants'
            :key='t.tenantId'
            :value='t.tenantId'
          ) {{ t.tenantName }}

    b-navbar-item(tag='div')
      b-field
        //- b-switch(
        //-   @input='toggleDarkMode()'
        //-   :rounded='false'
        //-   :type='"is-primary"'
        //-   :passive-type='"is-primary"'
        //- )
        b-button(
          @click='toggleDarkMode()'
        )
          b-icon(
            v-if='darkMode'
            icon='weather-sunny'
          )
          b-icon(
            v-else
            icon='weather-night'
          )

    //- b-navbar-item(tag='div')
    //-   form(method='POST' action='/api/stripe/portal')
    //-     input(type='hidden' name='uid' :value='user.uid')
    //-     button.button(
    //-       type='submit'
    //-       @click='loading()'
    //-     )
    //-       b-icon(icon='credit-card')
    //-       span Manage Subscription

</template>

<script>
import darkModeMixin from '@/mixins/darkMode'
import { auth } from '@/firebase'
import { LoadingProgrammatic as Loading } from 'buefy'
export default {
  props: [
    'tenantId',
    'tenants'
  ],
  mixins: [darkModeMixin],
  data () {
    return {
      activeTenantId: this.tenantId
    }
  },
  computed: {
    user: () => auth.currentUser
  },
  methods: {
    loading: () => Loading.open()
  }
}
</script>
