<template lang="pug">
div#header.container
  b-navbar(
    :close-on-click='false'
  )
    //- :mobile-burger='false'
    template(slot='brand')
      //- b-navbar-item(href='/')
      router-link.navbar-item(
        to='/'
      )
        img(src='@/assets/commissionaire-logo.png' alt='')
        | &nbsp;
        .has-text-primary.is-size-4.is-uppercase.has-text-weight-bold Commissionaire
    template(slot='end')
      b-navbar-item(
        v-if='user'
        tag='div'
      )
        .buttons
          router-link.button.is-primary(
            v-if='$route.name !== "Plans"'
            to='/app'
          ) Click here to go to the Dashboard
          //- b-button {{ $route.name }}
          //- .button.is-primary.is-light(
          //-   v-show='darkMode'
          //-   @click='toggleDarkMode'
          //- )
          //-   b-icon(
          //-     pack='fas'
          //-     icon='sun'
          //-   )
          //- .button.is-primary.is-light(
          //-   v-show='!darkMode'
          //-   @click='toggleDarkMode'
          //- )
          //-   b-icon(
          //-     pack='fas'
          //-     icon='moon'
          //-   )
      b-navbar-item(
        v-else
        tag='div'
      )
        //- a.mr-2(href='/api/xero/connect')
        //-   figure.image
        //-     img.connect(
        //-       src='@/assets/connect-blue.svg'
        //-     )
        .buttons(v-if='ready')
          //- .button.is-primary(
          //-   @click='isCardModalActive = true'
          //- )
          //-   strong Sign up
          router-link.button.is-primary(
            v-if='user'
            to='/plans'
          )
            strong Sign up
          router-link.button.is-primary(
            v-else
            to='/login'
          )
            strong Sign in with Xero
        .buttons(v-else)
          .button.is-primary(
            @click='$emit("update:isCardModalActive", true)'
          )
            //- @click='isCardModalActive = true'
            strong Sign up
</template>

<script>
export default {
  props: ['user', 'ready', 'isCardModalActive']
}
</script>
