import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'
// const LZString = require('lz-string')
// const decode = (data) => { return JSON.parse(LZString.decompress(data)) }
// const encode = (data) => { return LZString.compress(JSON.stringify(data)) }
// const decode = (data) => JSON.parse(data)
// const encode = (data) => JSON.stringify(data)

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // userData: {},
    settings: {},
    maps: {},
    contacts: {},
    invoices: {},
    organisations: {},
    accounts: {},
    items: {},
    tenants: [],
    xeroTenantId: null,
    searches: {},
    plans: {},
    darkMode: false,
    cache: {},
    user: null
  },
  getters: {
    settings: (state) => {
      console.log('state.xeroTenantId', state.xeroTenantId)
      return state.xeroTenantId ? state.settings[state.xeroTenantId] : {}
    },
    maps: (state) => {
      console.log('state.xeroTenantId', state.xeroTenantId)
      return state.xeroTenantId ? state.maps[state.xeroTenantId] : []
    },
    searches: (state) => {
      console.log('state.xeroTenantId', state.xeroTenantId)
      console.log('searches?')
      return state.xeroTenantId ? state.searches[state.xeroTenantId] : []
    },
    plans: (state) => {
      console.log('state.xeroTenantId', state.xeroTenantId)
      return state.xeroTenantId ? state.plans[state.xeroTenantId] : []
    },
    invoices: (state) => {
      console.log('state.xeroTenantId', state.xeroTenantId)
      return state.xeroTenantId ? state.invoices[state.xeroTenantId] : []
    },
    contacts: (state) => {
      return state.xeroTenantId ? state.contacts[state.xeroTenantId] : []
    },
    // userData: (state) => {
    //   return state.xeroTenantId ? state.userData[state.xeroTenantId] : []
    // },
    organisations: (state) => {
      return state.xeroTenantId ? state.organisations[state.xeroTenantId] : []
    },
    accounts: (state) => {
      return state.xeroTenantId ? state.accounts[state.xeroTenantId] : []
    },
    items: (state) => {
      return state.xeroTenantId ? state.items[state.xeroTenantId] : []
    }
  },
  mutations: {
    user: (state, payload) => {
      state.user = payload
    },
    cache: (state, payload) => {
      state.cache = payload
    },
    darkMode: (state, payload) => {
      state.darkMode = !state.darkMode
    },
    contacts: (state, payload) => {
      // state.contacts[state.xeroTenantId] = payload
      Vue.set(state.contacts, state.xeroTenantId, payload)
    },
    settings: (state, payload) => {
      // state.settings[state.xeroTenantId] = payload
      Vue.set(state.settings, state.xeroTenantId, payload)
    },
    maps: (state, payload) => {
      // state.maps[state.xeroTenantId] = payload
      Vue.set(state.maps, state.xeroTenantId, payload)
    },
    searches: (state, payload) => {
      // state.searches[state.xeroTenantId] = payload
      console.log('searches? 2')
      Vue.set(state.searches, state.xeroTenantId, payload)
    },
    plans: (state, payload) => {
      // state.plans[state.xeroTenantId] = payload
      Vue.set(state.plans, state.xeroTenantId, payload)
    },
    invoices: (state, payload) => {
      // state.invoices[state.xeroTenantId] = payload
      Vue.set(state.invoices, state.xeroTenantId, payload)
    },
    organisations: (state, payload) => {
      // state.organisations[state.xeroTenantId] = payload
      Vue.set(state.organisations, state.xeroTenantId, payload)
    },
    accounts: (state, payload) => {
      Vue.set(state.accounts, state.xeroTenantId, payload)
    },
    items: (state, payload) => {
      Vue.set(state.items, state.xeroTenantId, payload)
    },
    tenants: (state, payload) => {
      state.tenants = payload
      state.xeroTenantId ??= state.tenants[0]?.tenantId
    },
    xeroTenantId: (state, payload) => {
      state.xeroTenantId = payload
    },
    replaceItem: (state, { things, item }) => {
      // const foundIndex = state.invoices[state.xeroTenantId].findIndex(x => x.invoiceID === payload.invoiceID)
      const idField = things.slice(0, -1) + 'ID'
      console.log('replace item', 'idField', idField, 'item[idField]', item[idField], 'item', item)
      const foundIndex = state[things][state.xeroTenantId].findIndex(x => x[idField] === item[idField])
      // state.invoices[state.xeroTenantId][foundIndex] = payload
      // Vue.set(state.invoices[state.xeroTenantId], foundIndex, payload)
      Vue.set(state[things][state.xeroTenantId], foundIndex, item)
    }
  },
  actions: {
    toggleDarkMode: ({ commit }) => {
      commit('darkMode')
    },
    saveLocal: ({ commit }, data) => {
      for (const [key, value] of Object.entries(data)) {
        // console.log(`${key}: ${value}`)
        console.log({ key, value })
        commit(key, value)
      }
    },
    // replaceInvoice: ({ commit }, invoice) => {
    //   commit('replaceInvoice', invoice)
    // }
    replaceItem: ({ commit }, data) => {
      commit('replaceItem', data)
    }
  },
  plugins: [vuexLocal.plugin]
})

export default store
