<template lang="pug">
#plans
  b-loading(
    v-model='isLoading'
  )

  Header(:user='user')
  //- .container(v-if='subscribed')
  //-   form(ref='form' method='POST' action='/api/stripe/portal')
  //-     input(type='hidden' name='uid' :value='user.uid')
  //-     button.button(
  //-       type='submit'
  //-       @click='loading()'
  //-     )
  //-       b-icon(icon='credit-card')
  //-       span Manage Subscription

  //- .container(v-else)
  section.section
    .container
      h1.title Plans
      .block.
        All plans start with a 7 day free trial.
      .block.
        Purchase a yearly plan to get 12 months for the price of 10!
      .block.
        The number of seats you choose determines how many Xero contacts you can assign commissions to,
        so pick a number that best suits the size of your sales team.
        If you later find you need more or less seats, you can change this by modifying your subscription and updating the quantity.
      //- pre uid = {{ user?.uid }}
      //- pre products = {{ products }}
      template(
        v-for='product in products'
      )
        p.subtitle.mt-6 {{ product.name }}
        p {{ product.description }}
        .columns.is-multiline.is-mobile.mb-6.mt-4
          //- :key='product.id'
          .column.is-narrow(
            v-for='price in product.prices'
            :key='price.id'
          )
            form(
              :ref='"form" + price.id'
              action='/api/stripe/checkout'
              method='POST'
            )
              input(type='hidden' name='priceId' :value='price.id')
              input(type='hidden' name='redirect' :value='redirect')
              input(type='hidden' name='uid' :value='user?.uid')

              .card
                //- header.card-header
                  //- p.card-header-title {{ product.name }}  {{ price.recurring.interval }}ly

                .card-content
                  .content
                    //- pre product = {{ product }}
                    //- pre price = {{ price }}
                    //- p {{ product.description }}

                    p.is-size-1 ${{ price.unit_amount / 100 * price.quantity }}
                      span.is-size-5 / {{ price.recurring.interval }}
                    p
                      b-field(
                        label='Seats'
                        label-position='on-border'
                      )
                        b-input(
                          type='number'
                          name='quantity'
                          v-model='price.quantity'
                          min='1'
                        )
                        p.control
                          button.button.is-primary(
                            @click='submit("form" + price.id)'
                          ) Select

                //- footer.card-footer
                //-   p.card-footer-item
                //-     b-field(
                //-       label='Seats'
                //-       label-position='on-border'
                //-     )
                //-       b-input(
                //-         type='number'
                //-         name='quantity'
                //-         :value='1'
                //-         min='1'
                //-       )
                //-   p.card-footer-item
                //-     button.button.is-primary(
                //-     ) Select

    //- h1.title {{ user.displayName }}
    //- h2.subtitle {{ user.email }}
    //- pre {{ user }}

    //- form(method='POST' action='/api/stripe/portal')
    //-   input(type='text' name='uid' :value='user.uid')
    //-   button(type='submit') Manage billing

    //- hr

    //- .price-table-container
    //-   //- pre {{ products }}
    //-   section(v-for='product in products')
    //-     .name {{ product.name }}
    //-     form(v-for='price in product.prices' action='/api/stripe/checkout' method='POST')
    //-       input(type='text' name='priceId' :value='price.id')
    //-       input(type='text' name='redirect' :value='redirect')
    //-       input(type='number' name='quantity' :value='12')
    //-       input(type='text' name='uid' :value='user.uid')
    //-       .price ${{ price.unit_amount / 100 }}
    //-       .duration per {{ price.recurring.interval }}
    //-       button Select
</template>

<script>
// import { apiGet, apiCall } from '@/api'
// import axios from 'axios'
import { apiGet } from '@/api'
// import { auth, db, getDocs, collection, doc, getDoc, getCustomClaim } from '@/firebase'
import { auth, db, getDocs, collection, getCustomClaim } from '@/firebase'
import Header from '@/components/Header'

export default {
  components: {
    Header
  },
  metaInfo: {
    title: 'Plans'
  },
  async beforeRouteEnter (to, from, next) {
    const user = await auth.currentUser
    console.log('plans user', user)
    if (user) {
      const stripe = await getCustomClaim('stripe')
      // next((vm) => { vm.subscribed = ['active', 'trialing'].includes(stripe?.status) })
      if (['active', 'trialing'].includes(stripe?.status)) {
        // return await axios.post('/api/stripe/portal', {
        //   uid: user.uid
        // })
        // return await axios.get('/api/stripe/portal', { params: { uid: user.uid } })
        // return next({
        //   path: '/api/stripe/portal',
        //   query: { uid: user.uid }
        // })
        // NOTE: this next worked while all the above did not (had to also change /portal express route from .post to .all)
        return window.location.assign(`/api/stripe/portal?uid=${user.uid}`)
      }
    }
    next()
  },
  data () {
    return {
      isLoading: true,
      products: [],
      redirect: ''
      // subscribed: false
    }
  },
  computed: {
    user: () => auth.currentUser
  },
  methods: {
    submit (ref) {
      console.log('ref', ref)
      // console.log(this.$refs.form)
      // this.$refs.form.submit()
      console.log(this.$refs[ref])
      // this.$refs[ref].submit()
    },
    async loadProducts () {
      // const products = []
      let productsSnapshot = await getDocs(collection(db, 'products'))
      if (productsSnapshot.empty) {
        console.log('NO products')
        const products = await apiGet('stripe', 'products', { active: true })
        const prices = await apiGet('stripe', 'prices', { active: true })
        console.log({ products, prices })
        productsSnapshot = await getDocs(collection(db, 'products'))
      } else {
        console.log('YES products')
      }
      console.log('productsSnapshot.size', productsSnapshot.size)

      const products = productsSnapshot.docs.map(snap => snap.data())
      await Promise.all(
        products.map(async (product) => {
          const pricesSnapshot = await getDocs(collection(db, 'products', product.id, 'prices'))
          console.log('pricesSnapshot.size', pricesSnapshot.size)
          product.prices = pricesSnapshot.docs.map(snap => snap.data()).map((price) => { return { ...price, quantity: 1 } })
          this.products.push(product)
        })
      )
      this.isLoading = false
      console.log('this.products', this.products)
    }
  },
  async mounted () {
    // if (this.subscribed) {
    //   const user = await auth.currentUser
    //   console.log('uid', user.uid)
    //   alert(user.displayName)
    //   const stripe = await getCustomClaim('stripe')
    //   alert(stripe.status)
    //   if (['active', 'trialing'].includes(stripe?.status)) {
    //     // await axios.post('/api/stripe/portal', {
    //     //   uid: user.uid
    //     // })
    //     window.location.assign(`/api/stripe/portal?uid=${user.uid}`)
    //   }
    // }

    this.redirect = new URL(location.href).searchParams.get('redirect') || '/'
    await this.loadProducts()

    // const user = await auth.currentUser
    // console.log('user', user)

    // // const userPromise = auth.currentUser
    // // console.log('userPromise', userPromise)

    // if (user) {
    //   const userRef = doc(db, 'users', user.uid)
    //   const userSnap = await getDoc(userRef)
    //   if (userSnap.exists()) {
    //     console.log('user doc data', userSnap.data())
    //     const { stripeId, subscription } = userSnap.data()
    //     console.log({ stripeId, subscription })
    //     // this.subscribed = ['active', 'trialing'].includes(subscription.status)
    //   } else {
    //     console.log('no such user doc!')
    //   }
    // }
  }
}
</script>
