var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    attrs: {
      "close-on-click": false
    },
    scopedSlots: _vm._u([{
      key: "brand",
      fn: function fn() {
        return [_c('b-navbar-item', [_c('img', {
          attrs: {
            "src": require("@/assets/commissionaire-logo.png"),
            "alt": "Commissionaire"
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "has-text-primary is-size-4 is-uppercase has-text-weight-bold"
        }, [_vm._v("Commissionaire")])])];
      },
      proxy: true
    }, {
      key: "start",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "end",
      fn: function fn() {
        return [_c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('span', [_vm._v("Connected to Xero as " + _vm._s(_vm.user.displayName))])]), _c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('b-field', [_c('b-select', {
          on: {
            "input": function input($event) {
              return _vm.$emit("updateTenantId", _vm.activeTenantId);
            }
          },
          model: {
            value: _vm.activeTenantId,
            callback: function callback($$v) {
              _vm.activeTenantId = $$v;
            },
            expression: "activeTenantId"
          }
        }, _vm._l(_vm.tenants, function (t) {
          return _c('option', {
            key: t.tenantId,
            domProps: {
              "value": t.tenantId
            }
          }, [_vm._v(_vm._s(t.tenantName))]);
        }), 0)], 1)], 1), _c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('b-field', [_c('b-button', {
          on: {
            "click": function click($event) {
              return _vm.toggleDarkMode();
            }
          }
        }, [_vm.darkMode ? _c('b-icon', {
          attrs: {
            "icon": "weather-sunny"
          }
        }) : _c('b-icon', {
          attrs: {
            "icon": "weather-night"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }