import axios from 'axios'
import { functions, httpsCallable } from '@/firebase'
// const qs = require('qs')

const apiCall = async (thing, params) => {
  const func = httpsCallable(functions, thing)
  const { data } = await func(params)
  return data
}

const apiGet = async (api, thing, params) => {
  // const [api, end] = thing.split('/')
  try {
    console.log('api get', `/api/${api}/${thing}`, 'params', params)
    const { data } = await axios.get(`/api/${api}/${thing}`, {
      params
      // paramsSerializer: (params) => qs.stringify(params, { indices: false })
    })
    return data
  } catch (error) {
    logError(error)
    if (error.response?.status === 401) {
      // alert(error)
      const redirect = params.redirect
      console.log('api 401 redirect', redirect)
      connectRedirect(api, redirect)
    }
  }
}

const connectRedirect = (api, redirect = window.location.href) => {
  console.log({ api, redirect })
  window.location.assign(`/api/${api}/connect?redirect=${redirect}`)
}

// https://stackoverflow.com/a/56998042/67675
const logError = (error) => {
  if (error.response) { // status outside 2xx
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
  } else if (error.request) { // no response, error.request is XMLHttpRequest/http.ClientRequest instance in browser/node
    console.log(error.request)
  } else { // error while setting up request
    console.log('Error', error.message)
  }
  console.log(error)
}

export { apiGet, apiCall }
