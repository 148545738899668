var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer has-background-light"
  }, [_c('div', {
    staticClass: "content has-text-centered"
  }, [_vm.user ? _c('p') : _vm._e(), _c('form', {
    attrs: {
      "method": "POST",
      "action": "/api/stripe/portal"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "uid"
    },
    domProps: {
      "value": _vm.user.uid
    }
  }), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.loading();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "credit-card"
    }
  }), _c('span', [_vm._v("Manage Subscription")])], 1)]), _c('p'), _vm._m(0), _vm._m(1), _c('p', [_c('span', [_vm._v("© " + _vm._s(_vm.year))])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Commissionaire")]), _vm._v(" by "), _c('a', {
    attrs: {
      "href": "https://www.linkedin.com/in/stewheckenberg",
      "target": "_blank"
    }
  }, [_vm._v("Stew Heckenberg ")]), _c('br')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "is-size-7"
  }, [_c('strong', [_vm._v("Questions?")]), _vm._v(" Email stew at commissionaire dot io")]);
}]

export { render, staticRenderFns }