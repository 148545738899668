<template lang="pug">
#invoices(v-if='!loading')
  //- b-loading(v-model='loading')
  //- pre {{ plan.planType && plan.planType.name }}
  Nav(
    v-if='activeTenantId'
    :tenantId='activeTenantId'
    @updateTenantId='updateActiveTenantId'
    :tenants='tenants'
  )

  section.section
    Chart(:invoices='filteredDataWithComms' :fieldInit='"subTotal"' :dateFormatInit='"YY"' :dateFieldInit='"dueDate"')

  b-modal(
    v-model='openSettings'
    scroll='keep'
  )
    .card
      .card-content
        p.title Settings
        hr
        p.subtitle Labels
        b-field(
          v-for='(value, name) in settings?.labels'
          :key='name'
          :label='capitalize(name)'
          horizontal
        )
          b-input(
            v-model='settings?.labels[name]'
          )
        #csv(v-if='false')
          hr
          p.subtitle Line Item Costs CSV
          b-field.file.is-primary(:class="{'has-name': !!file}")
            b-upload.file-label(v-model='file' accept='.csv' required validationmessage='Please select a file' @input='csvUpload')
              span.file-cta
                b-icon.file-icon(icon='upload')
                span.file-label Click to upload (Only .csv)
              span.file-name(v-if='file')
                | {{ file.name }}
        hr
        //- pre {{ settings }}
        b-field(
          grouped
          position='is-right'
        )
          b-button(
            @click='openSettings = false; save({ settings })'
            type='is-success'
          ) Save
  //- section.section

    //- SEARCHES

    //- b-field
    //-   b-checkbox-button(
    //-     v-for='(s, index) in searches'
    //-     :key='"search" + index'
    //-     v-model='selectedSearches'
    //-     :native-value='s'
    //-     type='is-success'
    //-     @input='search = mergeSearches(selectedSearches)'
    //-   ) {{ s.name }}

    //- pre selectedSearches {{ selectedSearches }}
    //- pre search {{ search }}

    //- b-button(@click='openSearches = true; search = cloneDeep(freshSearch)') Searches

  b-modal(
    v-model='openSearches'
    scroll='keep'
    @close='search = mergeSearches(flatSearches)'
  )
    .card
      .card-content
        p.title {{ settings?.labels.searches }}
        p.subtitle Invoice Search
        b-field(
          label=''
          grouped
          group-multiline
        )
          b-field(
            label='Type'
            label-position=''
          )
            b-select(v-model='search.invoiceType')
              option(value='ACCREC') Accounts Receivable
              option(value='ACCPAY') Accounts Payable
          b-field(
            label='Status'
            label-position=''
          )
            b-taginput(
              ref='statuses'
              v-model='search.statuses'
              :data='filteredStatuses'
              autocomplete
              :open-on-focus='true'
              icon='label'
              placeholder='Statuses',
              @typing='(text) => getFilteredStatuses(text)'
              @input='setRef("statuses"); getFilteredStatuses("")'
              @focus='setRef("statuses"); getFilteredStatuses("")'
            )
              template(v-slot='props')
                | {{ niceStatus(props.option) }}
              template(#selected='props')
                b-tag(
                  v-for='(status, index) in props.tags'
                  :key='index'
                  rounded
                  ellipsis
                  closable
                  @close='$refs.statuses.removeTag(index, $event)'
                )
                  | {{ niceStatus(status) }}
          b-field(
            label='Number'
            label-position=''
          )
            b-taginput(
              placeholder='Number'
              icon='label',
              v-model='search.invoiceNumber'
            )
          b-field(
            label='Reference'
            label-position=''
          )
            b-taginput(
              placeholder='Reference'
              icon='label'
              v-model='search.reference'
            )
          b-field(
            label='Date'
          )
            b-datepicker(
              placeholder='Select dates...'
              v-model='search.dates'
              range
            )
              .buttons
                b-button(
                  label='Financial Year'
                  icon-left='calendar-today'
                  @click='search.dates = eofyDates'
                )
                b-button(
                  label='Clear'
                  icon-left='close'
                  outlined
                  @click='search.dates = []'
                )
          b-field(
            label='Due Date'
          )
            b-datepicker(
              placeholder='Select dates...'
              v-model='search.dueDates'
              range
            )
              .buttons
                b-button(
                  label='Financial Year'
                  icon-left='calendar-today'
                  @click='search.dueDates = eofyDates'
                )
                b-button(
                  label='Clear'
                  icon-left='close'
                  outlined
                  @click='search.dueDates = []'
                )
          b-field(
            grouped
            label='Subtotal'
            label-position=''
          )
            b-select(
              v-model='search.subTotalOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Subtotal'
              v-model='search.subTotal'
            )
          b-field(
            grouped
            label='Total'
            label-position=''
          )
            b-select(
              v-model='search.totalOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Total'
              v-model='search.total'
            )
          b-field(
            grouped
            label='Paid'
            label-position=''
          )
            b-select(
              v-model='search.amountPaidOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Paid'
              v-model='search.amountPaid'
            )
          b-field(
            grouped
            label='Due'
            label-position=''
          )
            b-select(
              v-model='search.amountDueOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Due'
              v-model='search.amountDue'
            )
          //- b-field(
          //-   label='Discounted'
          //-   label-position=''
          //- )
          //-   b-switch(
          //-     v-model='search.isDiscounted'
          //-   )
          //- b-field(
          //-   label='Errors'
          //-   label-position=''
          //- )
          //-   b-switch(
          //-     v-model='search.hasErrors'
          //-   )
          b-field(
            :label='settings?.labels.contacts'
            label-position=''
          )
            b-taginput(
              ref='searchContacts'
              v-model='search.contacts'
              :data='filteredContacts'
              field='name'
              autocomplete
              :open-on-focus='true'
              icon='label'
              :placeholder='settings?.labels.contacts',
              @typing='(text) => getFilteredContacts(text)'
              @input='setRef("searchContacts"); getFilteredContacts("")'
              @focus='setRef("searchContacts"); getFilteredContacts("")'
            )

        hr

        p.subtitle Line Item Search

        b-field(
          label=''
          grouped
          group-multiline
        )
          b-field(
            label='Account Code'
            label-position=''
          )
            b-select(
              placeholder='Account Code'
              v-model='search.lineItems.accountCode'
            )
              option(
                :value='null'
                v-if='search.lineItems.accountCode !== null'
              ) None
              option(
                v-for='a in accounts'
                :key='a.accountID'
                :value='a.code'
              ) {{ a.code }} {{ a.name | truncate }}
          b-field(
            label='Item Code'
            label-position=''
          )
            b-taginput(
              placeholder='Item Code'
              icon='label'
              v-model='search.lineItems.itemCode'
            )
          b-field(
            label='Description'
            label-position=''
          )
            b-taginput(
              placeholder='Description'
              icon='label'
              v-model='search.lineItems.description'
            )
          b-field(
            grouped
            label='Quantity'
            label-position=''
          )
            b-select(
              v-model='search.lineItems.quantityOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Quantity'
              v-model='search.lineItems.quantity'
            )
          b-field(
            grouped
            label='Unit Amount'
            label-position=''
          )
            b-select(
              v-model='search.lineItems.unitAmountOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Unit Amount'
              v-model='search.lineItems.unitAmount'
            )
          b-field(
            grouped
            label='Line Amount'
            label-position=''
          )
            b-select(
              v-model='search.lineItems.lineAmountOp'
            )
              option(
                v-for='(op, symbol) in numOps'
                :key='symbol'
                :value='symbol'
              ) {{ symbol }}
            b-input(
              type='number'
              min='0'
              :max='maxNumber'
              placeholder='Line Amount'
              v-model='search.lineItems.lineAmount'
            )
          //- b-field(
          //-   grouped
          //-   label='Tax Amount'
          //-   label-position=''
          //- )
          //-   b-select(
          //-     v-model='search.lineItems.taxAmountOp'
          //-   )
          //-     option(
          //-       v-for='(op, symbol) in numOps'
          //-       :key='symbol'
          //-       :value='symbol'
          //-     ) {{ symbol }}
          //-   b-input(
          //-     type='number'
          //-     min='0'
          //-     max='1000000'
          //-     placeholder='Line Amount'
          //-     v-model='search.lineItems.taxAmount'
          //-   )

          //- b-switch(v-model='includeArchived') Include Archived
          //- b-button(
          //-   @click='loadAsyncData'
          //- ) Search

        hr
        b-field(
          v-if='searches && searches.length'
          label='Load Search'
          label-position=''
          grouped
          group-multiline
        )
          b-field.mb-3(
            v-for='(s, index) in searches'
            :key='index'
          )
            p.control
              b-button.field(
                :label='s.name'
                type='is-primary'
                icon-left='play'
                @click='search = cloneDeep(s); origSearch = cloneDeep(s)'
              )
            p.control
              b-button.field(
                type='is-success'
                icon-left='content-copy'
                @click='search = { ...cloneDeep(s), time: null, name: s.name + " copy" }'
              )
            p.control
              b-button.field(
                type='is-danger'
                icon-left='delete'
                @click='confirm(() => removeSearch(index))'
              )
                //- @click='confirm(() => searches.splice(index, 1))'
          b-button.field(
            type='is-danger'
            icon-left='new-box'
            label='New'
            @click='confirm(() => search = cloneDeep(freshSearch))'
          )

        hr
        //- pre !!maps[mapIndex].searches.find(s => isEqual(s, search)) {{ !!maps[mapIndex].searches.find(s => isEqual(s, search)) }}
        //- pre maps[mapIndex].searches.find(s => isEqual(s, search)) {{ maps[mapIndex].searches.find(s => isEqual(s, search)) }}
        //- pre search {{ search }}
        //- pre isEqual {{ isEqual(search, cloneDeep(search)) }}
        //- pre maps[mapIndex].searches {{ maps[mapIndex].searches }}
        //- pre maps[mapIndex].searches.includes(search) {{ maps[mapIndex].searches.includes(search) }}
        //- pre includes(maps[mapIndex].searches, search) {{ includes(maps[mapIndex].searches, search) }}
        .columns
          .column
            b-field(v-show='search.name.length > 0')
              b-tooltip(
                always
                position='is-right'
                :label='filteredData.length + " invoices"'
              )
                b-button(
                  :disabled='!search.time || isEqual(search, freshSearch) || !isEqual(search, origSearch) || !!maps[mapIndex]?.searches.find(s => isEqual(s, search))'
                  @click='openSearches = false; maps[mapIndex]?.searches.push(search)'
                  type='is-success'
                  icon-left='filter'
                  :label='!!maps[mapIndex]?.searches.find(s => isEqual(s, search)) ? `"${search.name}" already selected` : `Add "${search.name}" to selected ${settings?.labels.searches}`'
                )
                //- ) Select Search
              //- b-tag(
              //-   type='is-ghost'
              //- ) {{ filteredData.length }} invoices
              //- ) Show {{ filteredData.length }} results
          .column
            b-field(
              horizontal
              label='Save'
              label-position=''
            )
              //- v-if='!isEqual({ ...freshSearch, name: search.name }, search)'
              b-input(
                placeholder='Search Name'
                v-model='search.name'
              )
              p.control
                b-button.field(
                  v-if='!!searches.find(p => p.time === search.time)'
                  label='Update Search'
                  type='is-success'
                  icon-left='floppy'
                  @click='searches.splice(searches.findIndex(p => p.time === search.time), 1, { ...search })'
                  :disabled='!search.name.length || isEqual(search, origSearch)'
                )
                  //- v-if='search.time'
                b-button.field(
                  v-else
                  label='Save Search'
                  type='is-success'
                  icon-left='floppy'
                  @click='search = { ...search, time: time() }; searches.push(search); origSearch = cloneDeep(search)'
                  :disabled='!search.name.length || isEqual(search, freshSearch)'
                )

    //- PLANS

    //- b-field
    //-   b-checkbox-button(
    //-     v-for='(p, index) in plans'
    //-     :key='"plan" + index'
    //-     v-model='selectedPlans'
    //-     :native-value='p'
    //-     type='is-success'
    //-   ) {{ p.name }}
    //-     //- @input='mergeSelectedPlans'

    //- pre plan = {{ plan }}
    //- pre selectedPlans = {{ selectedPlans }}

    //- b-button(@click='openPlans = true; plan = cloneDeep(freshPlan)') Plans

  b-modal(v-model='openPlans' scroll='keep')
    .card
      .card-content
        p.title {{ settings?.labels.plans }}
        b-field
          b-select(
            v-model='plan.planType'
            placeholder='Commission Field'
          )
            option(
              v-for='p in planTypes'
              :key='p.name'
              :value='p'
            ) {{ p.name }}
          //- b-switch(
          //- b-checkbox-button(
          //-   v-model='plan.cumulative'
          //-   :native-value='true'
          //-   type='is-success'
          //- ) Cumulative
          b-input(
            v-if='plan.planType?.field === "lineItem"'
            v-model='plan.lineItemSearch'
            placeholder='Line Item Search'
          )
          b-select(
            v-if='plan.planType?.field === "lineItem"'
            v-model='plan.lineItemField'
            placeholder='Line Item Field'
          )
            option(
              v-for='f in ["lineAmount", "lineMargin", "lineMarginCut"]'
              :key='f'
              :value='f'
            ) {{ startCase(f).replace('Cut', '%') }}
          b-datepicker(
            placeholder='Date range... (optional)'
            v-model='plan.dates'
            range
          )
            .buttons
              b-button(
                label='Financial Year'
                icon-left='calendar-today'
                @click='plan.dates = eofyDates'
              )
              b-button(
                label='Clear'
                icon-left='close'
                outlined
                @click='plan.dates = []'
              )

        hr

        p.subtitle Commission Tiers

        b-field(
          v-for='(tier, index) in plan.tiers'
          :key='"tier" + index'
        )
          p.control
            b-field(
              label='From'
              label-position='on-border'
            )
              b-input(
                placeholder='e.g. 5000'
                v-model='tier.from'
                type='number'
                min='1'
                :max='plan.planType?.name.includes("%") || plan.lineItemField?.includes("Cut") ? 100 : 1000000000'
              )
                //- :min='plan.planType?.name.includes("%") || plan.lineItemField?.includes("Cut") ? 1 : 0'
          p.control
            b-field(
              :label='"Flat " + symbol'
              label-position='on-border'
            )
              b-input(
                placeholder='e.g. 250'
                v-model='tier.flat'
                type='number'
                min='0'
              )
          p.control
            b-field(
              label='Cut %'
              label-position='on-border'
            )
              b-input(
                placeholder='e.g. 3.5'
                v-model='tier.cut'
                type='number'
                min='0'
                max='100'
                width='300'
              )
              p.control
                b-button.field(
                  v-if='plan.tiers.length > 1'
                  type='is-danger'
                  icon-left='delete'
                  @click='confirm(() => plan.tiers.splice(index, 1))'
                )
        b-button.field(
          label='Add Tier'
          type='is-primary'
          icon-left='plus'
          @click='plan.tiers.push({ ...freshTier })'
        )
        //- b-button.field(
        //-   label='Clear Plan'
        //-   type='is-danger'
        //-   icon-left='cross'
        //-   @click='plan = cloneDeep(freshPlan)'
        //- )

        hr

        b-field(
          v-if='plans && plans.length'
          label='Load Plan'
          label-position=''
          grouped
          group-multiline
        )
          b-field.mb-3(
            v-for='(p, index) in plans'
            :key='index'
          )
            p.control
              b-button.field(
                :label='p.name'
                type='is-primary'
                icon-left='play'
                @click='plan = cloneDeep(p); origPlan = cloneDeep(p)'
              )
            p.control
              b-button.field(
                type='is-success'
                icon-left='content-copy'
                @click='plan = { ...cloneDeep(p), time: null, name: p.name + " copy" }'
              )
            p.control
              b-button.field(
                type='is-danger'
                icon-left='delete'
                @click='confirm(() => removePlan(index))'
              )
                //- @click='confirm(() => plans.splice(index, 1))'
          b-button.field(
            type='is-danger'
            icon-left='new-box'
            label='New'
            @click='confirm(() => plan = cloneDeep(freshPlan))'
          )

        hr
        .columns
          .column
            b-field(v-show='plan.name.length > 0')
              b-button(
                :disabled='!plan.time || isEqual(plan, freshPlan) || !isEqual(plan, origPlan) || !!maps[mapIndex]?.plans.find(p => isEqual(p, plan))'
                @click='openPlans = false; $refs.mapPlans[0].addTag(plan)'
                type='is-success'
                icon-left='filter'
                :label='!!maps[mapIndex]?.plans.find(p => isEqual(p, plan)) ? `"${plan.name}" already selected` : `Add "${plan.name}" to selected ${settings?.labels.plans}`'
              )
                //- @click='openPlans = false; maps[mapIndex].plans.push(plan); log("mapPlans ref", $refs.mapPlans[0]); $refs.mapPlans[0].focus()'
              //- ) Select Plan
                //- :disabled='isEqual(plan, freshPlan)'
          .column

            b-field(
              horizontal
              label='Save'
              label-position=''
            )
              //- v-if='!isEqual({ ...freshPlan, name: plan.name }, plan)'
              b-input(
                placeholder='Plan Name'
                v-model='plan.name'
              )
              p.control
                b-field
                  b-button.field(
                    v-if='!!plans.find(p => p.time === plan.time)'
                    label='Update Plan'
                    type='is-success'
                    icon-left='floppy'
                    @click='plans.splice(plans.findIndex(p => p.time === plan.time), 1, { ...plan })'
                    :disabled='!plan.name.length || isEqual(plan, origPlan) || !plan.tiers.every(e => e.from && (e.flat || e.cut)) || (plan.planType.field === "lineItem" && (!plan.lineItemSearch || !plan.lineItemField))'
                  )
                    //- v-if='plan.time'
                  b-button.field(
                    v-else
                    label='Save Plan'
                    type='is-success'
                    icon-left='floppy'
                    @click='plan = { ...plan, time: time() }; plans.push(plan); origPlan = cloneDeep(plan)'
                    :disabled='!plan.name.length || isEqual(plan, freshPlan) || !plan.tiers.every(e => e.from && (e.flat || e.cut))'
                  )

            //- b-field(
            //-   horizontal
            //-   v-if='!isEqual({ ...freshSearch, name: search.name }, search)'
            //-   label='Save'
            //-   label-position=''
            //- )
            //-   b-input(
            //-     placeholder='Search Name'
            //-     v-model='search.name'
            //-   )
            //-   p.control
            //-     b-button.field(
            //-       v-if='search.time'
            //-       label='Update Search'
            //-       type='is-success'
            //-       icon-left='floppy'
            //-       @click='searches.splice(searches.findIndex(p => p.time === search.time), 1, { ...search })'
            //-       :disabled='!search.name.length'
            //-     )
            //-     b-button.field(
            //-       v-else
            //-       label='Add Search'
            //-       type='is-success'
            //-       icon-left='floppy'
            //-       @click='searches.push({ ...search, time: time() })'
            //-       :disabled='!search.name.length'
            //-     )

  section.section
    b-notification(
      type='is-primary'
      has-icon
      icon='human-greeting'
      aria-close-label='Close notification'
      role='alert'
    )
      p G'day {{ user.displayName }}, welcome to Commissionaire!
      p Define your {{ settings?.labels.searches }}/{{ settings?.labels.plans }}/{{ settings?.labels.contacts }} combinations below and click the Commissions button to view.

    //- p.title Views
    //- MAPS

    .columns(
      v-if='maps'
      v-for='(map, index) in maps'
      :key='"map" + index'
    )
      .column
        b-taginput(
          ref='mapSearches'
          v-model='map.searches'
          :data='filteredSearches'
          field='name'
          autocomplete
          :open-on-focus='true'
          icon='label'
          :placeholder='settings?.labels.searches',
          @typing='getFilteredSearches'
          @input='setRef("mapSearches", index); mapSearches = cloneDeep(map.searches); getFilteredSearches(""); search = mergeSearches(flatSearches)'
          @focus='setRef("mapSearches", index); getFilteredSearches("")'
          @select-footer='openSearches = true; search = cloneDeep(freshSearch)'
          :selectable-footer='true'
        )
          template(#tag='props')
            a(
              @click='search = props.tag; origSearch = cloneDeep(props.tag); openSearches = true'
            ) {{ props.tag.name }}
          template(#empty)
            span No {{ settings?.labels.searches }}
          template(#footer)
            //- a.has-text-success(@click='openSearches = true; search = cloneDeep(freshSearch)') Manage Searches
            //- a Create Search...
            b-button(
              @click='mapIndex = index'
              expanded
              size='is-small'
            ) Create/Edit {{ settings?.labels.searches }}...
      .column
        b-taginput(
          ref='mapPlans'
          v-model='map.plans'
          :data='filteredPlans'
          field='name'
          autocomplete
          :open-on-focus='true'
          icon='label'
          :placeholder='settings?.labels.plans',
          @typing='getFilteredPlans'
          @input='setRef("mapPlans", index); getFilteredPlans("")'
          @focus='setRef("mapPlans", index); getFilteredPlans("")'
          @select-footer='openPlans = true; plan = cloneDeep(freshPlan)'
          :selectable-footer='true'
        )
          template(#tag='props')
            a(
              @click='plan = props.tag; origPlan = cloneDeep(props.tag); openPlans = true'
            ) {{ props.tag.name }}
          template(#empty)
            span No {{ settings?.labels.plans }}
          template(#footer)
            //- a Create Plan...
            b-button(
              @click='mapIndex = index'
              expanded
              size='is-small'
            ) Create/Edit {{ settings?.labels.plans }}...
      .column
        b-field
          //- p.control {{ getMaxTags(map) }}
          p.control
            b-taginput(
              ref='mapContacts'
              v-model='map.contacts'
              :data='getMaxTags(map) <= getMapsContacts().length ? getMapsContacts() : filteredContacts'
              field='name'
              autocomplete
              :open-on-focus='true'
              icon='label'
              :placeholder='settings?.labels.contacts',
              @typing='(text) => getFilteredContacts(text)'
              @input='setRef("mapContacts", index); getFilteredContacts("")'
              @focus='setRef("mapContacts", index); getFilteredContacts("")'
              :maxtags='getMaxTags(map) || getMapsContacts()?.length'
              :has-counter='false'
            )
              //- :maxtags='maxTags + map.contacts.length'
              template(#tag='props')
                //- b-tooltip(
                //-   v-if='props.tag.emailAddress && map.view'
                //-   position='is-top'
                //-   always
                //- )
                //-   span {{ props.tag.name }}
                //-   template(v-slot:content)
                //-     a(
                //-       @click='confirm(() => emailUser(props.tag), `Send email link to ${trimEnd(settings?.labels.contacts, "s")}?`)'
                //-     )
                //-       //- @click='confirm(() => emailLink(props.tag), `Send email link to ${settings?.labels.contacts}?`)'
                //-       b-icon(icon='email' size='is-small' type='is-white')
                //- span(v-else) {{ props.tag.name }}
                //- span {{ props.tag.name }}
                b-tooltip(
                  v-if='!props.tag.emailAddress'
                  position='is-top'
                  type='is-danger'
                  always
                )
                  span {{ props.tag.name }}
                  template(v-slot:content)
                    b-icon(icon='email' size='is-small' type='is-white')
                    span.has-text-white.is-size-7 No email
                span(v-else) {{ props.tag.name }}

                //- span(v-else) {{ props.tag.name }}
                //- b-tooltip(
                //-   v-if='props.tag.emailAddress'
                //-   position='is-top'
                //- )
                //-   a(
                //-     @click='confirm(() => emailLink(props.tag), "Send email link?")'
                //-   ) {{ props.tag.name }}
                //-   template(v-slot:content)
                //-     span Send email link
                //- span(v-else) {{ props.tag.name }}
              template(#empty)
                span No {{ settings?.labels.contacts }}

          //- p.control
          //-   b-checkbox-button(
          //-     :disabled='map.contacts?.length < 2'
          //-     v-model='map.split'
          //-     :native-value='true'
          //-     type='is-success'
          //-   )
          //-     b-icon(icon='chart-pie')
          //-     span Split
      .column
        b-field(
          position='is-right'
        )
          //- p.control(
          //-   v-if='maps.length > 1'
          //- )
          p.control
            b-button(
              :disabled='maps.length == 1'
              type='is-danger'
              icon-left='delete'
              @click='confirm(() => maps.splice(index, 1))'
            )
              //- :disabled='maps.length < 2'
          //- p.control
          //-   b-button.field(
          //-     v-if='map.contacts.length && map.searches.length && map.plans.length'
          //-     type='is-success'
          //-     icon-left='eye'
          //-     @click='mapViewToggle(map)'
          //-     label='View'
          //-   )

          p.control
            b-checkbox-button(
              :disabled='!(map.contacts?.length && map.searches?.length && map.plans?.length)'
              v-model='map.view'
              :native-value='true'
              type='is-success'
              @input='mapViewToggle(map)'
            )
              b-icon(icon='cash-100')
              span Commissions
              //- p.control(
              //-   v-if='map.contacts?.length > 1'
              //- )

          p.control(
            v-if='map.contacts?.length > 1'
          )
            b-checkbox-button(
              :disabled='!(map.contacts?.length && map.searches?.length && map.plans?.length)'
              v-model='map.split'
              :native-value='true'
              type='is-success'
            )
              b-icon(icon='chart-pie')
              span Split

          p.control
            b-button(
              :disabled='map.contacts.length === 0 || !map.view || !map.contacts.every(c => c.emailAddress)'
              type='is-primary'
              @click='confirm(() => emailUsers(map.contacts), `Send email link to ${settings?.labels.contacts}?`)'
            )
              //- @click='confirm(() => emailUsers(map.contacts), `Send email link to ${trimEnd(settings?.labels.contacts, "s")}?`)'
              b-icon(icon='email' type='is-white')

    //- pre {{ map(flatMaps.searches, 'name') }}
    //- pre {{ map(flatMaps.plans, 'name') }}
    //- pre {{ map(flatMaps.contacts, 'name') }}
    //- pre split {{ flatMaps.split }}

    .columns
      .column
        b-tooltip(
          multilined
          position='is-right'
        )
          a.button.is-ghost(
            href='/plans'
          ) {{ mapContacts.length }}/{{ maxContacts }} {{ settings?.labels.contacts }} used
          template(v-slot:content)
            span Update the quantity on your subscription plan to change the number of {{ settings?.labels.contacts }}
        br
        b-button.field(
          @click='openSettings = true'
          size='is-small'
          type='is-ghost'
        ) Settings

      .column.has-text-right
        b-button.field(
          :disabled='!maps.every(m => m.plans.length && m.searches.length && m.contacts.length)'
          :label='`Add ${settings?.labels.searches }/${settings?.labels.plans}/${settings?.labels.contacts} combination`'
          type='is-primary'
          icon-left='plus'
          @click='maps.push({ ...freshMap })'
        )
          //- v-if='maps.every(m => m.plans.length && m.searches.length && m.contacts.length)'
          //- label='Add View'
        br
        b-button.field(
          :disabled='!maps.every(m => m.plans.length && m.searches.length && m.contacts.length) || isEqual(maps, origMaps)'
          label='Save combinations'
          type='is-success'
          @click='save({ maps }); origMaps = cloneDeep(maps)'
        )

  //- section.section
  //-   //- CHECKED ROWS
  //-   pre {{ checkedRows }}
  //-   b-field(grouped group-multiline)
  //-     b-button.field(label='Clear Checked Rows' type='is-danger' icon-left='close' @click='checkedRows = []')
  //-     b-button.field(label='Add Custom Column' type='is-success' icon-left='plus' @click='openCustomColumns = true')

  section.section

    //- FIELDS

    //- pre cost plans {{ selectedPlans.filter(p => p.planType?.field == "cost").length > 0 }}
    //- pre cost plans {{ hasMarginPlanSelected }}

    .columns
      .column
        p.title Invoices
          span.subtitle  ({{ filteredDataWithComms.length }})
          b-button(
            @click='exportCSV()'
            size='is-small'
            type='is-ghost'
          ) Export CSV
      .column.has-text-right
        b-button.mb-6(
          v-if='false'
          @click='invoiceForm()'
          icon-left='receipt'
        ) New Invoice
        p.subtitle.is-size-7(
          v-if='isWide'
        ) Hold the Shift key to scroll table sideways
    //- pre isWide = {{ isWide }}
      //- pre selected plans {{ selectedPlans.length }}
      //- pre selected searches {{ selectedSearches.length }}

    b-collapse(:open='false')
      template(#trigger)
        .has-text-right
          b-button(
            label='Columns'
            type='is-ghost'
            size='is-small'
          )
      .notification
        .content
          //- pre {{ visible }}
          b-field(
            grouped
            group-multiline
          )
            .control(
              v-for='(value, key) in filteredVisible'
              :key='key'
            )
              b-checkbox(
                v-model='visible[key]'
              ) {{ startCase(key) }}

    b-table(
      :data='filteredDataWithComms'
      :loading='loading'
      scrollable
      paginated
      pagination-simple
      :total='total'
      :per-page='perPage'
      aria-next-label='Next page'
      aria-previous-label='Previous page'
      aria-page-label='Page'
      aria-current-label='Current page'
      :default-sort-direction='defaultSortOrder'
      :default-sort='[sortField, sortOrder]'
      detailed
      detail-key='invoiceID'
    )
      //- :checked-rows.sync='checkedRows'
      //- checkable
      //- checkbox-position='right'
      //- :key='tableRefresh'
      //- backend-pagination
      //- backend-sorting
      //- @page-change='onPageChange'
      //- @sort='onSort'
      b-table-column(
        centered
        sortable
        field='invoiceNumber'
        label='Number'
        v-slot='props'
        :visible='visible["invoiceNumber"]'
      )
        | {{ props.row.invoiceNumber | truncate }}
        |
        a(:href='link(props.row)' target='_blank')
          b-icon(icon='open-in-new')
      b-table-column(
        centered
        sortable
        field='reference'
        label='Ref'
        v-slot='props'
        :visible='visible["reference"]'
      )
        | {{ props.row.reference | truncate }}
      b-table-column(
        centered
        sortable
        field='contact.name'
        label='To'
        v-slot='props'
        :visible='visible["contact.name"]'
      )
        | {{ props.row.contact.name | truncate }}
      b-table-column(
        centered
        sortable
        field='date'
        label='Date'
        v-slot='props'
        width='150'
        :visible='visible["date"]'
      )
        | {{ props.row.date ? dayjs(props.row.date).format('D MMM YYYY') : '' }}
      b-table-column(
        centered
        sortable
        field='dueDate'
        label='Due Date'
        width='150'
        :visible='visible["dueDate"]'
      )
        template(v-slot='props')
          | {{ props.row.dueDate ? dayjs(props.row.dueDate).format('D MMM YYYY') : '' }}
        template(#subheading='props')
          | Totals:
      b-table-column(
        sortable
        field='amountPaid'
        label='Paid'
        numeric
        :visible='visible["amountPaid"]'
      )
        template(v-slot='props')
          | {{ props.row.amountPaid | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('amountPaid') | currency(symbol) }}
      b-table-column(
        sortable
        field='amountDue'
        label='Due'
        numeric
        :visible='visible["amountDue"]'
      )
        template(v-slot='props')
          | {{ props.row.amountDue | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('amountDue') | currency(symbol) }}
      b-table-column(
        sortable
        field='subTotal'
        label='Subtotal'
        numeric
        :visible='visible["subTotal"]'
      )
        template(v-slot='props')
          | {{ props.row.subTotal | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('subTotal') | currency(symbol) }}
      b-table-column(
        sortable
        field='totalTax'
        label='Tax'
        numeric
        :visible='visible["totalTax"]'
      )
        template(v-slot='props')
          | {{ props.row.totalTax | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('totalTax') | currency(symbol) }}
      b-table-column(
        sortable
        field='total'
        label='Total'
        numeric
        :visible='visible["total"]'
      )
        template(v-slot='props')
          | {{ props.row.total | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('total') | currency(symbol) }}
      b-table-column(
        centered
        sortable
        field='status'
        label='Status'
        v-slot='props'
        :visible='visible["status"]'
      )
        | {{ niceStatus(props.row.status) }}
      b-table-column(
        centered
        sortable
        field='sentToContact'
        label='Sent'
        v-slot='props'
        :visible='visible["sentToContact"]'
      )
        | {{ props.row.sentToContact ? 'Sent' : '' }}
      //- b-table-column(
      //-   field='custom'
      //-   label='Custom'
      //-   v-slot='props'
      //-   numeric
      //- )
      //-   //- | {{ props.row.custom }}
      //-   b-input(v-model='props.row.custom')
      b-table-column(
        field='cost'
        label='Cost'
        numeric
        width='100'
        :visible='visible["cost"]'
      )
        template(v-slot='props')
          span(v-if='props.row.cost > 0') {{ props.row.cost | currency(symbol) }}
          span(v-else) -
        template(#subheading='props')
          | {{ sumRows('cost') | currency(symbol) }}
        //- :visible='visible["cost"] && !!selectedPlans.find(p => p.planType.field === "cost")'

        //- :visible='visible["cost"] && plan?.planType?.name == "Margin"'
        //- v-if='plan.planType && plan.planType.name == "Margin"'
        //- :field='plan.planType.field'
        //- :label='capitalize(plan.planType.field)'

        //- b-input(
        //-   v-if='selectedPlans.filter(p => dayjs(props.row.date).isBetween(p.dates[0], p.dates[1], null, "[]")).length > 0'
        //-   v-model='props.row.cost'
        //-   class='cost'
        //- )
          //- :disabled='!plan || !plan.planType || !plan.planType.name != "Margin"'
      b-table-column(
        field='margin'
        label='Margin'
        numeric
        width='100'
        :visible='visible["margin"]'
      )
        template(v-slot='props')
          span(v-if='props.row.margin > 0') {{ props.row.margin | currency(symbol) }}
          span(v-else) -
        template(#subheading='props')
          | {{ sumRows('margin') | currency(symbol) }}

      b-table-column(
        field='marginCut'
        label='Margin %'
        numeric
        width='100'
        :visible='visible["marginCut"]'
      )
        template(v-slot='props')
          span(v-if='props.row.marginCut > 0') {{ props.row.marginCut.toFixed(2) }}%
          span(v-else) -
        template(#subheading='props')
          | {{ (sumRows('margin') / sumRows('subTotal') * 100).toFixed(2) }}%

      b-table-column(
        label='Commission'
        width='200'
        field='commission'
        numeric
        sortable
        :visible='visible["commission"] && selectedPlans.length > 0'
      )
        //- sortable
        //- v-if='selectedPlans.length'
        //- field='commission'
        template(v-slot='props')
          //- | {{ sum(getComms(props.row, selectedPlans)) | currency(symbol) }}
          | {{ props.row.commission | currency(symbol) }}
          //- b-tag(
          //-   v-for='(c, index) in getComms(props.row, selectedPlans)'
          //-   :key='"plan" + index'
          //- ) {{ c | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('commission') | currency(symbol) }}

      b-table-column(
        label='Cumulative'
        width='200'
        field='cumulative'
        numeric
        sortable
        :visible='visible["cumulative"] && selectedPlans.length > 0'
      )
        template(v-slot='props')
          | {{ props.row.cumulative | currency(symbol) }}

      b-table-column(
        centered
        label='Split'
        width='200'
        :visible='visible["split"] && selectedPlans.length > 0'
      )
        template(v-slot='props')
          b-tag(
            v-for='split in props.row.split'
            :key='split'
          ) {{ split }}
        template(#subheading='props')
          | {{ selectedSplit[0] ? '✔' : '✖' }}

      b-table-column(
        label='Commission Level 2'
        width='200'
        field='commission2'
        numeric
        sortable
        :visible='visible["commission2"] && selectedPlans.length > 0 && !!selectedPlans.find(p => p.planType.field === "cumulative")'
      )
        template(v-slot='props')
          | {{ props.row.commission2 | currency(symbol) }}
        template(#subheading='props')
          | {{ sumRows('commission2') | currency(symbol) }}

      b-table-column(
        label='Cumulative Level 2'
        width='200'
        field='cumulative2'
        numeric
        sortable
        :visible='visible["cumulative2"] && selectedPlans.length > 0 && !!selectedPlans.find(p => p.planType.field === "cumulative")'
      )
        template(v-slot='props')
          | {{ props.row.cumulative2 | currency(symbol) }}

      b-table-column(
        centered
        label='Split Level 2'
        width='200'
        :visible='visible["split2"] && selectedPlans.length > 0 && !!selectedPlans.find(p => p.planType.field === "cumulative")'
      )
        template(v-slot='props')
          b-tag(
            v-for='split2 in props.row.split2'
            :key='split2'
          ) {{ split2 }}
        template(#subheading='props')
          | {{ selectedSplit[0] ? '✔' : '✖' }}

      template(#detail='props')
        //- pre {{ props.row.lineItems }}
        //- pre {{ props.row }}
        p.subtitle Line Items
        b-table(
          :data='props.row.lineItems'
        )
          b-table-column(
            field='accountCode'
            label='Account Code'
            v-slot='props'
          )
            | {{ props.row.accountCode }}
          b-table-column(
            field='itemCode'
            label='Item Code'
            v-slot='props'
          )
            | {{ props.row.itemCode }}
          b-table-column(
            field='description'
            label='Description'
            v-slot='props'
            width='33vw'
          )
            .pre {{ props.row.description }}
          b-table-column(
            field='unitAmount'
            label='Unit Amount'
            v-slot='props'
            numeric
          )
            | {{ props.row.unitAmount }}
          b-table-column(
            field='unitCost'
            label='Unit Cost'
            v-slot='props'
            numeric
          )
            | {{ props.row.unitCost }}
          b-table-column(
            field='quantity'
            label='Quantity'
            v-slot='props'
            numeric
          )
            | {{ props.row.quantity }}
          b-table-column(
            field='lineAmount'
            label='Line Amount'
            v-slot='props'
            numeric
          )
            span(v-if='props.row.lineAmount > 0') {{ props.row.lineAmount | currency(symbol) }}
            span(v-else) -
          b-table-column(
            field='lineCost'
            label='Line Cost'
            v-slot='props'
            numeric
          )
            span(v-if='props.row.lineCost > 0') {{ props.row.lineCost | currency(symbol) }}
            span(v-else) -
          b-table-column(
            field='lineMargin'
            label='Line Margin'
            v-slot='props'
            numeric
          )
            span(v-if='props.row.lineMargin > 0') {{ props.row.lineMargin | currency(symbol) }}
            span(v-else) -
          b-table-column(
            field='lineMarginCut'
            label='Line Margin %'
            v-slot='props'
            numeric
          )
            span(v-if='props.row.lineMarginCut > 0') {{ props.row.lineMarginCut | currency(symbol) }}
            span(v-else) -

  Footer
</template>

<script>
// import { encode } from 'messagepack'
import { stringify as zipsonStringify } from 'zipson'
import Chart from '@/components/Chart'
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import { apiGet } from '@/api'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
// import throttle from 'lodash/throttle'
import capitalize from 'lodash/capitalize'
import defaults from 'lodash/defaults'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import flatten from 'lodash/flatten'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import sum from 'lodash/sum'
import toNumber from 'lodash/toNumber'
import trimEnd from 'lodash/trimEnd'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import values from 'lodash/values'
import { mapState, mapGetters, mapActions } from 'vuex'
import { auth, db, doc, deleteDoc, collection, query, where, onSnapshot, getCustomClaim, setDoc, sendLinkEmail, getDoc } from '@/firebase'
import getSymbolFromCurrency from 'currency-symbol-map'
// import { getDoc } from 'firebase/firestore'
import fileDownload from 'js-file-download'
import axios from 'axios'
import dayjs from 'dayjs'
import currency from 'currency.js'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
const { parse } = require('json2csv')
// const { sendEmail } = require('@/functions/sendgrid')
// const YAML = require('json-to-pretty-yaml')
const csv = require('csvtojson').csv()

export default {
  metaInfo: {
    title: 'Dashboard'
  },
  components: {
    Chart,
    Nav,
    Footer
  },
  data () {
    return {
      file: null,
      unsubscribe: null,
      saveCount: 0,
      isWide: false,
      origSearch: null,
      origPlan: null,
      origMaps: null,
      mapIndex: 0,
      visible: {
        invoiceNumber: true,
        reference: true,
        'contact.name': false,
        date: true,
        dueDate: true,
        amountPaid: false,
        amountDue: false,
        subTotal: true,
        totalTax: false,
        total: true,
        status: true,
        sentToContact: false,
        cost: true,
        margin: true,
        marginCut: true,
        commission: true,
        cumulative: true,
        split: true,
        commission2: true,
        cumulative2: true,
        split2: true
      },
      tagInput: null,
      data: [],
      total: 0,
      loading: false,
      sortField: 'date',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      // page: 1,
      perPage: 50,
      invoiceStatuses: [
        'DRAFT',
        'SUBMITTED',
        'DELETED',
        'AUTHORISED',
        'PAID',
        'VOIDED'
      ],
      defaultStatuses: [
        'SUBMITTED',
        'AUTHORISED',
        'PAID'
      ],
      filteredStatuses: [],
      // includeArchived: false,
      filteredContacts: [],
      filteredPlans: [],
      filteredSearches: [],
      // checkedRows: [],
      activeTenantId: '',
      plan: {
        name: '',
        planType: null,
        // cumulative: false,
        dates: [], // this.eofyDates,
        tiers: [
          {
            from: '',
            flat: '',
            cut: ''
          }
        ]
      },
      // maps: [
      //   {
      //     searches: [],
      //     plans: [],
      //     contacts: [],
      //     split: false,
      //     view: false
      //   }
      // ],
      freshSettings: {
        labels: {
          searches: 'Searches',
          plans: 'Plans',
          contacts: 'Contacts'
        }
      },
      freshMap: {
        searches: [],
        plans: [],
        contacts: [],
        split: false,
        view: false
      },
      mapSearches: [],
      maxContacts: 0, // TODO replace with subscription seats
      planTypes: [
        {
          name: 'Subtotal',
          field: 'subTotal'
        },
        {
          name: 'Total',
          field: 'total'
        },
        {
          name: 'Paid',
          field: 'amountPaid'
        },
        {
          name: 'Margin',
          field: 'cost'
        },
        {
          name: 'Margin %',
          field: 'cost'
        },
        {
          name: 'Cumulative',
          field: 'cumulative'
        },
        {
          name: 'Line Item',
          field: 'lineItem'
        }
      ],
      freshPlan: null,
      search: {
        name: '',
        invoiceType: 'ACCREC',
        statuses: [],
        invoiceNumber: [],
        reference: [],
        total: '',
        totalOp: '==',
        subTotal: '',
        subTotalOp: '==',
        amountPaid: '',
        amountPaidOp: '==',
        amountDue: '',
        amountDueOp: '==',
        isDiscounted: false,
        // hasErrors: false,
        dates: [],
        dueDates: [],
        contacts: [],
        lineItems: {
          lineItemID: '',
          itemCode: [],
          description: [],
          quantity: '',
          quantityOp: '==',
          unitAmount: '',
          unitAmountOp: '==',
          accountCode: null, // needs to be null (not empty string) for b-select to display placeholder
          taxType: '', // OUTPUT INPUT EXEMPTEXPENSES EXEMPTOUTPUT BASEEXCLUDED GSTONIMPORTS <-- Australia
          // taxAmount: '',
          // taxAmountOp: '==',
          lineAmount: '',
          lineAmountOp: '==',
          tracking: [] // [{ TrackingCategoryID, Name, Option },...]
          // custom: ''
        }
      },
      freshSearch: null,
      numOps: {
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
        '<': (a, b) => a < b,
        '<=': (a, b) => a <= b,
        '==': (a, b) => a === b
      },
      // dayOps: {
      //   '>': (a, b) => dayjs(a).isAfter(dayjs(b)),
      //   '>=': (a, b) => dayjs(a).isSameOrAfter(dayjs(b)),
      //   '<': (a, b) => dayjs(a).isBefore(dayjs(b)),
      //   '<=': (a, b) => dayjs(a).isSameOrBefore(dayjs(b)),
      //   '==': (a, b) => dayjs(a).isSame(dayjs(b))
      // }
      eofyDates: [],
      // searches: []
      // openOnFocus: true
      openSettings: false,
      openSearches: false,
      openPlans: false,
      openCustomColumns: false,
      selectedPlans: [],
      selectedSearches: [],
      selectedContacts: [],
      selectedSplit: false,
      maxNumber: 1000000000
    }
  },
  watch: {
    // async file (file, oldFile) {
    //   console.log('file changed', file)
    //   console.log('text', await file.text())
    // },
    xeroTenantId (val, oldVal) {
      console.log('xero tenant id changed', val)
      if (this.unsubscribe) {
        console.log('unsubscribe old listener')
        this.unsubscribe()
      }
      console.log('setup new listener')
      this.setupWebhookListener()
    },
    // visible: {
    //   deep: true,
    //   handler (visible, oldVisible) {
    //     console.log('watch visible')
    //     // this.isWide = this.wide()
    //   }
    // },
    plans (plans, oldPlans) {
      console.log('watch', { plans, oldPlans }, 'equal?', isEqual(plans, oldPlans))
      if (plans) this.save({ plans })
      // this.plan = cloneDeep(this.freshPlan)
    },
    searches (searches, oldSearches) {
      console.log('watch', { searches, oldSearches }, 'equal?', isEqual(searches, oldSearches))
      if (searches) this.save({ searches })
      // this.search = cloneDeep(this.freshSearch)
    },
    flatMaps (flatMaps, oldFlatMaps) {
      console.log('testing')
      this.search = this.mergeSearches(flatMaps.searches)
      this.selectedPlans = flatMaps.plans
      this.selectedSearches = flatMaps.searches
      this.selectedContacts = flatMaps.contacts
      this.selectedSplit = flatMaps.split
    }
  },
  computed: {
    user: () => auth.currentUser,
    // hasMarginPlanSelected () {
    //   return this.selectedPlans.filter(p => p.planType?.field === 'cost').length > 0
    // },
    filteredVisible () {
      const planFields = ['commission', 'cumulative', 'split', 'commission2', 'cumulative2', 'split2']
      // if (!this.hasMarginPlanSelected) planFields.push('cost')
      // console.log('plan fields', planFields)
      const cumulativeFields = ['commission2', 'cumulative2', 'split2']

      return pickBy(this.visible, (v, k) => {
        if (this.selectedPlans.length === 0 && planFields.includes(k)) return false
        if (!this.selectedPlans.find(p => p.planType.field === 'cumulative') && cumulativeFields.includes(k)) return false
        return true
        // return k === 'cost' ? this.hasMarginPlanSelected : (this.selectedPlans.length > 0 || !planFields.includes(k))
        // if (k === 'cost') {
        //   return this.hasMarginPlanSelected
        // } else {
        //   return this.selectedPlans.length > 0 || !planFields.includes(k)
        // }
        // switch (k) {
        //   case 'cost':
        //     return !!this.selectedPlans.find(p => p.planType.field === 'cost')
        //   case 'cumulative':
        //     return
        //   case 'subTotal':
        //   case 'total':
        //   case 'amountPaid':
        //     return this.selectedPlans.length > 0 || !planFields.includes(k)
        // }
      })
    },
    flatSearches () {
      return uniq(flatten(map(this.maps, 'searches')))
    },
    flatMaps () {
      return {
        searches: uniq(flatten(map(filter(this.maps, 'view'), 'searches'))),
        plans: uniq(flatten(map(filter(this.maps, 'view'), 'plans'))),
        contacts: uniq(flatten(map(filter(this.maps, 'view'), 'contacts'))),
        split: uniq(flatten(map(filter(this.maps, 'view'), 'split')))
      }
    },
    maxTags () {
      return this.maxContacts - this.mapContacts.length
      // maxTags[index] = maxContacts - mapContacts.length + map.contacts.length
    },
    mapContacts () {
      // return uniqBy(flatMap(this.maps.map(m => m.contacts)), 'contactID')
      return uniqBy(flatMap(map(this.maps, 'contacts')), 'contactID')
    },
    sortedStatuses () {
      return sortBy(this.invoiceStatuses, (s) => this.niceStatus(s))
    },
    sortedContacts () {
      // return sortBy(this.contacts, 'name')
      return sortBy(this.contacts, (c) => c.name?.toLowerCase())
    },
    sortedPlans () {
      return sortBy(this.plans, (p) => p.name?.toLowerCase())
    },
    sortedSearches () {
      // return sortBy(this.contacts, 'name')
      return sortBy(this.searches, (s) => s.name?.toLowerCase())
    },
    filteredDataWithComms () {
      let commission = 0
      let commission2 = 0
      return sortBy(this.filteredData, 'date').map((row) => {
        row.cumulative = 0
        row.commission = 0
        row.cumulative2 = 0
        row.commission2 = 0
        if (this.selectedPlans.length) {
          row.commission = sum(this.getComms(row, this.selectedPlans.filter(p => p.planType.field !== 'cumulative')))
          commission += row.commission
          row.cumulative = commission
          row.commission2 = sum(this.getComms(row, this.selectedPlans.filter(p => p.planType.field === 'cumulative')))
          commission2 += row.commission2
          row.cumulative2 = commission2
        }
        const split = this.selectedSplit[0]
        console.log('split', this.selectedSplit[0])
        if (this.selectedContacts.length) {
          const comm = split ? row.commission / this.selectedContacts.length : row.commission
          const comm2 = split ? row.commission2 / this.selectedContacts.length : row.commission2
          row.contacts = cloneDeep(this.selectedContacts)
          row.split = map(this.selectedContacts, (c) => {
            return `${this.currency(comm)} | ${c.name}`
          }) // .join()
          row.split2 = map(this.selectedContacts, (c) => {
            return `${this.currency(comm2)} | ${c.name}`
          }) // .join()
        }
        row.lineItems = row.lineItems.map((i) => {
          const unitCost = this.items.find(l => i.itemCode === l.code)?.purchaseDetails?.unitPrice || 0
          const lineCost = unitCost * i.quantity
          const lineMargin = lineCost > 0 ? i.lineAmount - lineCost : 0
          const lineMarginCut = lineMargin > 0 ? lineMargin / i.lineAmount * 100 : 0
          return { ...i, unitCost, lineCost, lineMargin, lineMarginCut }
        })

        row.cost = sumBy(row.lineItems, 'lineCost')
        row.margin = row.cost > 0 ? row.subTotal - row.cost : 0
        row.marginCut = row.margin > 0 ? row.margin / row.subTotal * 100 : 0
        return row
      })
    },
    filteredData () {
      if (!this.data?.length) return []
      return this.data
        .filter(invoice => invoice.type === this.search.invoiceType)
        .filter(invoice => invoice.isDiscounted === this.search.isDiscounted)
        // .filter(invoice => invoice.hasErrors === this.search.hasErrors)
        .filter(
          invoice => this.search.dates.length === 0 ||
          dayjs(invoice.date).isBetween(this.search.dates[0], this.search.dates[1], null, '[]')
        )
        .filter(
          invoice => this.search.dueDates.length === 0 ||
          dayjs(invoice.dueDate).isBetween(this.search.dueDates[0], this.search.dueDates[1], null, '[]')
        )
        .filter(
          invoice => this.search.contacts.length === 0 ||
          this.search.contacts.map(c => c.name).includes(invoice.contact?.name)
        )
        // .filter(
        //   invoice => this.search.contact.name.length === 0 ||
        //   invoice.contact.name?.toLowerCase().indexOf(this.search.contact.name) > -1
        // )
        .filter(
          // invoice => this.search.statuses.length === 0 ||
          // this.search.statuses.includes(invoice.status)
          invoice => this.search.statuses.length === 0
            ? this.defaultStatuses.includes(invoice.status)
            : this.search.statuses.includes(invoice.status)
        )
        .filter(
          invoice => this.search.invoiceNumber.length === 0 ||
          this.search.invoiceNumber.some(n => invoice.invoiceNumber?.toLowerCase().indexOf(n.toLowerCase()) > -1)
        )
        .filter(
          invoice => this.search.reference.length === 0 ||
          this.search.reference.some(r => invoice.reference?.toLowerCase().indexOf(r.toLowerCase()) > -1)
          // invoice.reference?.toLowerCase().indexOf(this.search.reference.toLowerCase()) > -1
        )
        .filter(
          invoice => this.search.subTotal === '' ||
          this.numOps[this.search.subTotalOp](Number(invoice.subTotal), Number(this.search.subTotal))
        )
        .filter(
          invoice => this.search.total === '' ||
          this.numOps[this.search.totalOp](Number(invoice.total), Number(this.search.total))
        )
        .filter(
          invoice => this.search.amountPaid === '' ||
          this.numOps[this.search.amountPaidOp](Number(invoice.amountPaid), Number(this.search.amountPaid))
        )
        .filter(
          invoice => this.search.amountDue === '' ||
          this.numOps[this.search.amountDueOp](Number(invoice.amountDue), Number(this.search.amountDue))
        )
        .filter(
          invoice => this.search.lineItems.itemCode.length === 0 ||
          invoice.lineItems.some(lineItem => this.search.lineItems.itemCode.some(c => lineItem.itemCode?.toLowerCase().indexOf(c.toLowerCase()) > -1))
        )
        .filter(
          invoice => this.search.lineItems.description.length === 0 ||
          invoice.lineItems.some(lineItem => this.search.lineItems.description.some(d => lineItem.description?.toLowerCase().indexOf(d.toLowerCase()) > -1))
        )
        .filter(
          invoice => this.search.lineItems.accountCode === null ||
          invoice.lineItems.some(lineItem => lineItem.accountCode === this.search.lineItems.accountCode)
        )
        .filter(
          invoice => this.search.lineItems.quantity === '' ||
          invoice.lineItems.some(lineItem =>
            this.numOps[this.search.lineItems.quantityOp](Number(lineItem.quantity), Number(this.search.lineItems.quantity))
          )
        )
        .filter(
          invoice => this.search.lineItems.lineAmount === '' ||
          invoice.lineItems.some(lineItem =>
            this.numOps[this.search.lineItems.lineAmountOp](Number(lineItem.lineAmount), Number(this.search.lineItems.lineAmount))
          )
        )
        .filter(
          invoice => !this.search.lineItems.unitAmount ||
          invoice.lineItems.some(lineItem =>
            this.numOps[this.search.lineItems.unitAmountOp](Number(lineItem.unitAmount), Number(this.search.lineItems.unitAmount))
          )
        )
        // .filter(
        //   invoice => !this.search.lineItems.taxAmount ||
        //   invoice.lineItems.some(lineItem =>
        //     this.numOps[this.search.lineItems.taxAmountOp](Number(lineItem.taxAmount), Number(this.search.lineItems.taxAmount))
        //   )
        // )
    },
    // dayjs: () => dayjs,
    ...mapState([
      'tenants',
      'xeroTenantId',
      'cache'
    ]),
    org () {
      return this.organisations?.find(o => o.organisationID === this.xeroTenantId)
    },
    symbol () {
      return getSymbolFromCurrency(this.org?.baseCurrency)
      // return '' // no symbol for now as it crowds ui
    },
    ...mapGetters([
      'contacts',
      'invoices',
      'organisations',
      'accounts',
      'items',
      'searches',
      'plans',
      'maps',
      'settings'
    ])
  },
  methods: {
    capitalize: capitalize,
    cloneDeep: cloneDeep,
    defaults: defaults,
    flatMap: flatMap,
    flatten: flatten,
    includes: includes,
    isEqual: isEqual,
    map: map,
    merge: merge,
    omitBy: omitBy,
    pickBy: pickBy,
    sortBy: sortBy,
    startCase: startCase,
    sum: sum,
    trimEnd: trimEnd,
    uniq: uniq,
    uniqBy: uniqBy,
    // dateBetweenDates (date, dates) {
    //   return dayjs(date).isBetween(dates[0], dates[1], null, '[]')
    // },
    dayjs: dayjs,
    // wide () {
    //   const tableWidth = document.querySelector('.table')?.offsetWidth
    //   const wrapperWidth = document.querySelector('.table-wrapper')?.offsetWidth
    //   return tableWidth > wrapperWidth
    // },
    async invoiceForm (form) {
      const lineItems = [{
        description: 'Foobar',
        quantity: 1.0,
        unitAmount: 20.0,
        accountCode: '200'
      }]

      const invoice = {
        type: 'ACCREC',
        contact: {
          contactID: this.contacts[0].contactID
        },
        date: '2022-10-10',
        dueDate: '2022-10-28',
        lineItems: lineItems,
        reference: 'Foo Bar Baz',
        status: 'DRAFT'
      }

      const invoices = [invoice]

      const { xeroTenantId } = this
      console.log('post invoices data', { xeroTenantId, invoices })
      const response = await axios.post('/api/xero/invoices', { xeroTenantId, invoices })
      console.log('post invoices response', response)
    },
    async csvUpload (file) {
      console.log('csv upload file', file)
      console.log('this.file', this.file)
      const text = await file.text()
      console.log('text', text)
      const json = await csv.fromString(text)
      console.log('csv json', json)
    },
    getMapsContacts () {
      return uniq(flatten(map(this.maps, 'contacts')))
    },
    getMaxTags (map) {
      return this.maxTags + map.contacts.length
    },
    async saveUserData (user) {
    // makeUserData (user) {
      const invoices = this.filteredDataWithComms
        .filter(i => i.contacts?.find(c => user.contactID === c.contactID))
        .map(i => { // remove splits that don't contain user name but not good if users names match
          const split = i.split.filter(s => s.endsWith(` | ${user.name}`))
          const split2 = i.split2.filter(s => s.endsWith(` | ${user.name}`))
          return { ...i, split, split2 }
        })
      // const userData = { [user.contactID]: invoices }
      // console.log('userData', userData)
      // this.save({ userData }, true)
      console.log({ user, invoices })
      const smallUser = pick(user, ['emailAddress', 'name'])
      smallUser.invoices = zipsonStringify(invoices)

      // user.invoices = JSON.stringify(invoices)
      await setDoc(doc(db, 'orgs', this.xeroTenantId, 'userData', user.contactID), { user: smallUser }, { merge: true })
      // return invoices
      // await setDocs(collection(db, 'userData', user.contactID, 'invoices'), invoices, { merge: true })
      // await setDoc(doc(db, 'userData', user.contactID), { foo: 'bar' }, { merge: true })
      // const total = invoices.length
      // // this.saveCount = 1
      // let count = 1
      // // const toast = this.$buefy.toast.open({ message: 'Loading... (please wait)', indefinite: true })
      // // const toast = this.$buefy.toast.open({ message: `Invoice saved ${this.saveCount} of ${total}`, indefinite: true })
      // const toast = this.$buefy.toast.open({ message: `Invoice saved ${count} of ${total}`, indefinite: true })
      // await Promise.all(
      //   invoices.map(async (invoice) => {
      //     // console.log('setDoc invoice', invoice)
      //     await setDoc(doc(db, 'orgs', this.xeroTenantId, 'userData', user.contactID, 'invoices', invoice.invoiceID), invoice, { merge: true })
      //     // this.saveCount++
      //     // this.$set(this, 'saveCount', this.saveCount + 1)
      //     // console.log('save count', this.saveCount, )
      //     // count++
      //     console.log('save count', count)
      //     toast.message = `Invoice saved ${count++} of ${total}`
      //     // this.$buefy.toast.open({ message: `Invoice saved ${count++} of ${total}` })
      //   })
      // )
      // toast.close()
      // console.log('setDoc { user }', { user })
      // await setDoc(doc(db, 'orgs', this.xeroTenantId, 'userData', user.contactID), { user }, { merge: true })
      // console.log('filteredDataWithComms', this.filteredDataWithComms)
    },
    async emailUsers (users) {
      console.log('email users', users)
      // await Promise.all(
      users.map(async (user) => {
        // console.log('email users save user data')
        await this.saveUserData(user)
        // const invoices = this.makeUserData(user)
        // const data = encodeURIComponent(JSON.stringify(invoices))
        // const data = Buffer.from(JSON.stringify(invoices)).toString('base64')
        // const data = encode(invoices)
        console.log('send link email')
        const email = user.emailAddress
        if (email) {
          const userId = user.contactID
          const orgId = this.xeroTenantId
          console.log('email users send link email')
          await sendLinkEmail(email, userId, orgId)
          this.$buefy.toast.open({ message: `Email sent to ${email}`, type: 'is-success' })
        } else {
          this.$buefy.toast.open({ message: `Email NOT sent to ${user.name}, no email set`, type: 'is-danger' })
        }
      })
      // )
      console.log('after email users')
    },
    // async emailUser (user) {
    //   await this.saveUserData(user)
    //   console.log('email user', user)
    //   const email = user.emailAddress
    //   console.log('email =', email)
    //   if (email) {
    //     const url = `${window.location.origin}/view?user=${user.contactID}&org=${this.xeroTenantId}`
    //     console.log({ url, origin: window.location.origin, email })
    //     const result = await apiCall('sendEmail', { text: url })
    //     console.log('email user result', result)
    //     // const { data: { result, error } } = await axios.post('/api/misc/email', { text: YAML.stringify(user) }, {
    //     //   auth: {
    //     //     username: 'hCbmu6HyvbcqJC4g',
    //     //     password: 'g573geyUWY4vRdQQ'
    //     //   }
    //     // })
    //     // console.log('emailUser', { result, error })
    //   }
    // },
    async emailForm (event) {
      const { to, from, subject, text } = Object.fromEntries(new FormData(event.target))
      console.log({ to, from, subject, text })
      const { data: { result, error } } = await axios.post('/api/misc/email', { to, from, subject, text })
      console.log('emailForm', { result, error })
    },
    // async email ({ to, from, subject, text, data }) {
    //   await sendEmail({
    //     subject: 'Dashboard Link',
    //     text: data ? YAML.stringify(data) : text
    //   })
    // },
    async emailLink (user) {
      console.log('email link user', user)
      const email = user.emailAddress
      console.log('link email =', email)
      if (email) {
        await sendLinkEmail(email, user.contactID)
        this.$buefy.toast.open({ message: `Email sent to ${email}` })
      } else {
        alert('Contact has no email set')
      }
    },
    currency (value) {
      return this.$options.filters.currency(value, this.symbol)
    },
    exportCSV () {
      const fields = Object.keys(this.visible)
      const filename = kebabCase(this.org.name)
      try {
        const csv = parse(this.filteredDataWithComms, { fields })
        // console.log(csv)
        fileDownload(csv, `${filename}.csv`, 'text/csv')
      } catch (err) {
        console.error(err)
      }
    },
    removeSearch (index) {
      this.maps[this.mapIndex].searches.splice(this.maps[this.mapIndex].searches.findIndex(s => s.time === this.searches[index].time), 1)
      this.searches.splice(index, 1)
    },
    removePlan (index) {
      this.maps[this.mapIndex].plans.splice(this.maps[this.mapIndex].plans.findIndex(s => s.time === this.plans[index].time), 1)
      this.plans.splice(index, 1)
    },
    log: console.log,
    confirm (onConfirm, message = 'Are you sure?') {
      this.$buefy.dialog.confirm({
        message,
        onConfirm
      })
    },
    mapViewToggle (map) {
      this.maps.forEach((m) => { if (m !== map) m.view = false }) // toggle off other maps view
    },
    getComms (row, plans) {
      // - ) {{ props.row[p.planType.field] }} | {{ p.planType.field }}
      return plans.map((p) => {
        // if (!this.dateBetweenDays(row.date, p.dates)) return 0
        if (p.dates.length && !dayjs(row.date).isBetween(p.dates[0], p.dates[1], null, '[]')) return 0

        const field = p.planType.field
        const name = p.planType.name
        const tiers = sortBy(p.tiers, 'from').reverse()
        // const cumulative = p.cumulative

        let amount = 0
        // if (field === 'cost') {
        switch (field) {
          case 'cost': {
            if (name.includes('%')) {
              // amount = (toNumber(row.subTotal) - toNumber(row.cost)) / 100
              // amount = row.margin / row.subTotal * 100
              amount = row.marginCut
            } else {
            // if (name.includes('$')) {
            //   // amount = toNumber(row.subTotal) - toNumber(row.cost)
              amount = row.margin
            }
            console.log('margin cost amount', amount)
            break
          }
          case 'lineItem': {
            const lineItemSearch = p.lineItemSearch
            const lineItemField = p.lineItemField
            console.log('lineItemSearch', lineItemSearch)
            const lineItems = row.lineItems.filter((i) => {
              return values(i)
                .filter(v => isString(v))
                .some(v => v.toLowerCase().includes(lineItemSearch.toLowerCase()))
            })
            if (lineItemField === 'lineMarginCut') {
              amount = sumBy(lineItems, 'lineMargin') / sumBy(lineItems, 'lineAmount') * 100
            } else {
              amount = sumBy(lineItems, lineItemField)
            }
            console.log('lineItemField amount', lineItemField, amount)
            break
          }
          default:
            amount = toNumber(row[field])
        }

        // const amount = toNumber(value)
        // console.log({ field, tiers, cumulative, amount })
        let comm = 0
        let flatDone = false
        let cutDone = false
        tiers.forEach((t) => {
          if (amount >= t.from && t.flat && !flatDone) {
            comm = comm + toNumber(t.flat)
            flatDone = true
          }
          if (amount >= t.from && t.cut && !cutDone) {
            comm = comm + (amount * toNumber(t.cut) / 100)
            cutDone = true
          }
        })
        return comm
      })
    },
    mergeSearches (searches) {
      console.log('merge searches', searches)
      return merge(cloneDeep(this.freshSearch), ...searches.map(o => pickBy(o)))
    },
    setRef (refName, index) {
      console.log({ refName, index })
      if (index >= 0) {
        console.log('ref YES index', this.$refs[refName][index])
        this.ref = this.$refs[refName][index]
      } else {
        console.log('ref NO index', this.$refs[refName])
        this.ref = this.$refs[refName]
      }
    },
    time () {
      return +new Date()
    },
    setupWideCheck () {
      // this.isWide = this.wide()
      // window.addEventListener('resize', debounce(() => {
      //   console.log('table resize')
      //   // this.isWide = this.wide()
      // }, 150))
      const ro = new ResizeObserver(entries => {
        console.log('resize', entries)
        const tableWidth = document.querySelector('.table')?.offsetWidth
        const wrapperWidth = document.querySelector('.table-wrapper')?.offsetWidth
        this.isWide = tableWidth > wrapperWidth
      })
      ro.observe(document.querySelector('.table'))
      ro.observe(document.querySelector('.table-wrapper'))
    },
    async setupWebhookListener () {
      // query invoice events
      // const q = query(collection(db, 'orgs', this.xeroTenantId, 'events'), where('eventCategory', '==', 'INVOICE'))
      const q = query(collection(db, 'orgs', this.xeroTenantId, 'events'), where('eventCategory', 'in', ['INVOICE', 'CONTACT']))

      // subscribe to webhook events
      this.unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          console.log('event:', change.type)
          const { resourceId, eventCategory } = change.doc.data()
          const { xeroTenantId } = this
          console.log({ resourceId, eventCategory, xeroTenantId })
          switch (change.type) {
            case 'added':
            case 'modified': {
              // if (eventCategory === 'INVOICE') {
              //   console.log('invoice event')
              //   const { invoices } = await apiGet('xero', 'invoices', { xeroTenantId, iDs: [resourceId] })
              //   if (invoices.length) {
              //     const invoice = invoices[0]
              //     this.replaceInvoice(invoice)
              //   }
              // }
              // if (eventCategory === 'CONTACT') {
              //   console.log('contact event')
              //   const { contacts } = await apiGet('xero', 'contacts', { xeroTenantId, iDs: [resourceId] })
              //   if (contacts.length) {
              //     const contact = contacts[0]
              //     this.replaceInvoice(contact)
              //   }
              // }
              const things = eventCategory.toLowerCase() + 's'
              console.log('event for', things, resourceId)
              const result = await apiGet('xero', things, { xeroTenantId, iDs: [resourceId] })
              console.log('result', things, result)
              if (result[things]?.length) {
                const item = result[things][0]
                console.log('item', item)
                this.replaceItem({ things, item })
              }
              await deleteDoc(doc(db, 'orgs', this.xeroTenantId, 'events', change.doc.id))
              break
            }
            case 'removed':
            default:
              console.log('unhandled event', change.type)
          }
        })
      })

      // // subscribe from webhook events on navigation (not sure this actually works)
      // window.addEventListener('beforeunload', (event) => {
      //   unsubscribe()
      //   console.log('unsubscribe')
      // })
    },
    async save (data) {
      // this.loading = true
      console.log('save', data)
      this.saveLocal(data)
      this.saveFirestore(data)
    },
    saveFirestore: debounce(async function (data) {
      console.log('saveFirestore', data)
      console.log('setDoc data', data)
      await setDoc(doc(db, 'orgs', this.xeroTenantId), data, { merge: true })
      // this.loading = false

      for (const [key, value] of Object.entries(data)) {
        // console.log(`${key}: ${value}`)
        console.log({ key, value })
        // this.$buefy.toast.open({ message: `Saved ${capitalize(key)}` })
        this.$buefy.toast.open({ message: 'Saved to database' })
      }
    }, 5000),
    // cacheLocal: debounce(function (name) {
    //   console.log('cache local', name, this[name])
    //   console.log('cache', this.cache)
    //   const cache = { ...this.cache, [name]: this[name] }
    //   this.saveLocal({ cache })
    // }, 3000),
    async load () {
      this.loading = true
      const toast = this.$buefy.toast.open({ message: 'Loading... (please wait)', indefinite: true })
      // const loading = this.$buefy.loading.open()

      // if (isEmpty(this.tenants)) this.saveLocal(await apiGet('xero', 'tenants'))
      this.saveLocal(await apiGet('xero', 'tenants'))
      const xeroTenantId = this.activeTenantId = this.xeroTenantId

      if (isEmpty(this.invoices)) this.saveLocal(await apiGet('xero', 'invoices/all', { xeroTenantId }))
      this.data = this.invoices

      if (isEmpty(this.contacts)) this.saveLocal(await apiGet('xero', 'contacts', { xeroTenantId }))
      if (isEmpty(this.organisations)) this.saveLocal(await apiGet('xero', 'organisations', { xeroTenantId }))
      if (isEmpty(this.accounts)) this.saveLocal(await apiGet('xero', 'accounts', { xeroTenantId }))
      // if (isEmpty(this.items)) this.saveLocal(await apiGet('xero', 'items', { xeroTenantId }))
      this.saveLocal(await apiGet('xero', 'items', { xeroTenantId }))
      console.log('items', this.items)
      // console.log('this.searches isEmpty?', isEmpty(this.searches))
      // console.log('firestore searches', (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.searches)
      // console.log('test assignment searches', (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.searches || [])
      if (isEmpty(this.searches)) this.saveLocal({ searches: (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.searches || [] })
      if (isEmpty(this.plans)) this.saveLocal({ plans: (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.plans || [] })
      if (isEmpty(this.maps)) this.saveLocal({ maps: (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.maps || [cloneDeep(this.freshMap)] })
      if (isEmpty(this.settings)) this.saveLocal({ settings: (await getDoc(doc(db, 'orgs', xeroTenantId))).data()?.settings || cloneDeep(this.freshSettings) })
      console.log('this.settings', this.settings)
      // this.plan = { ...this.plan, ...this.cache.plan }

      this.maps.forEach((m) => { m.view = false })
      this.origMaps = cloneDeep(this.maps)

      // init contacts for autocomplete
      this.filteredContacts = this.sortedContacts
      this.filteredSearches = this.sortedSearches
      this.setupEofyDates()

      this.loading = false
      toast.close()
      // loading.close()
    },
    setupEofyDates () {
      const day = this.org.financialYearEndDay
      const month = this.org.financialYearEndMonth
      const year = dayjs().year()

      const eofy = `${year}-${month}-${day}`
      const eofyLast = `${year - 1}-${month}-${day}`
      const eofyNext = `${year + 1}-${month}-${day}`

      if (dayjs().isBetween(eofyLast, eofy, null, '[]')) {
        this.eofyDates = [dayjs(eofyLast).toDate(), dayjs(eofy).toDate()]
      }
      if (dayjs().isBetween(eofy, eofyNext, null, '[]')) {
        this.eofyDates = [dayjs(eofy).toDate(), dayjs(eofyNext).toDate()]
      }
      console.log('eofyDates', this.eofyDates)
    },
    async updateActiveTenantId (tenantId) {
      this.activeTenantId = tenantId
      console.log('active tenant id', this.activeTenantId)
      // this.search = { ...this.freshSearch }
      this.search = cloneDeep(this.freshSearch)
      // this.maps = [cloneDeep(this.freshMap)]
      this.saveLocal({ xeroTenantId: this.activeTenantId })
      await this.load()
    },
    getFilteredSearches (text) {
      const searches = this.ref.tags
      this.ref.tags = sortBy(searches, (s) => s.name?.toLowerCase())
      this.filteredSearches = this.sortedSearches
        .filter((option) => {
          // return !searches.includes(option)
          return !searches.find(s => isEqual(s, option))
        })
        .filter((search) => {
          const someHaveLineItems = this.mapSearches.some(s => !isEqual(s.lineItems, this.freshSearchLineItems))
          const someHaveTotal = this.mapSearches.some(s => s.total)
          // if (someHaveLineItems) return !search.total
          if (someHaveLineItems) return !search.total // if any searches have line items then only give me ones without totals
          if (someHaveTotal) return isEqual(search.lineItems, this.freshSearchLineItems) // if any have totals then only give me ones without lineitems
          return true
        }).filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        })
    },
    getFilteredPlans (text) {
      const plans = this.ref.tags
      this.ref.tags = sortBy(plans, (p) => p.name?.toLowerCase())
      this.filteredPlans = this.sortedPlans
        .filter((option) => {
          // return !plans.includes(option)
          return !plans.find(p => isEqual(p, option))
        })
        .filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        })
    },
    getFilteredContacts (text) {
      const contacts = this.ref.tags
      this.ref.tags = sortBy(contacts, (c) => c.name?.toLowerCase())
      this.filteredContacts = this.sortedContacts
        .filter((option) => {
          // return !contacts.includes(option)
          return !contacts.find(c => isEqual(c, option))
        })
        .filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        })
    },
    getFilteredStatuses (text) {
      const statuses = this.ref.tags
      this.ref.tags = sortBy(statuses, (s) => this.niceStatus(s))
      this.filteredStatuses = this.sortedStatuses
        .filter((option) => {
          // return !statuses.includes(option)
          return !statuses.find(s => isEqual(s, option))
        })
        .filter((option) => {
          return this.niceStatus(option)
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        })
    },
    niceStatus (status) {
      switch (status) {
        case 'SUBMITTED':
          return 'Awaiting Approval'
        case 'AUTHORISED':
          return 'Awaiting Payment'
        default:
          return capitalize(status)
      }
    },
    link (invoice) {
      return 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + invoice.invoiceID
    },
    sumRows (field) {
      return sumBy(this.filteredDataWithComms, field)
    },
    ...mapActions(['saveLocal', 'replaceItem'])
  },
  filters: {
    truncate (value, length = 12) {
      return value?.length > length
        ? value.substr(0, length) + '...'
        : value
    },
    currency (value, symbol) {
      return currency(value, { symbol }).format()
    }
  },
  async beforeRouteEnter (to, from, next) {
    const stripe = await getCustomClaim('stripe')
    // this.maxContacts = stripe?.seats

    // setup auth token claim so query works with db security
    await getCustomClaim('xero_tenant_ids')

    next((vm) => {
      vm.maxContacts = stripe?.seats
    })
  },
  async mounted () {
    // init statuses for autocomplete
    this.filteredStatuses = this.sortedStatuses

    // save fresh copies for reset
    this.freshSearch = cloneDeep(this.search)
    this.freshSearchLineItems = cloneDeep(this.search.lineItems)
    this.freshPlan = cloneDeep(this.plan)
    this.freshTier = cloneDeep(this.plan.tiers[0])
    // this.freshMap = cloneDeep(this.map)

    // load data from cache/api/db
    await this.load()

    // const q = query(collection(db, 'orgs', this.xeroTenantId, 'events')) //, where('eventCategory', '==', 'INVOICE'))
    // onSnapshot(q, (querySnapshot) => {
    //   const events = []
    //   querySnapshot.forEach((doc) => {
    //     events.push(doc.data())
    //   })
    //   console.log('Current events:', events)
    // })

    // const q = query(collection(db, "cities"), where("state", "==", "CA"));

    // wait for auth token/claim xero_tenant_ids before snapshot listener below
    this.setupWebhookListener()
    this.setupWideCheck()
  }
}
</script>

<style lang="stylus">
pre
  max-height 25vh
.table td
  vertical-align middle !important
.table
  scrollbar-x-position top
.cost
  width 100px
.pre
  white-space pre-wrap
// input.input
//   width calc(100% - 1px) !important
// .taginput .taginput-container.is-focusable
//   border-top-right-radius 0 !important
//   border-bottom-right-radius 0 !important
.input
  min-width 100px
</style>
