<template lang="pug">
div

  b-navbar(
    :close-on-click='false'
  )
    template(#brand)
      b-navbar-item
        img(src='@/assets/commissionaire-logo.png' alt='Commissionaire')
        | &nbsp;
        .has-text-primary.is-size-4.is-uppercase.has-text-weight-bold Commissionaire
    template(#start)
      b-navbar-item(tag='div' v-if='user')
        span View for {{ [user.name, user.emailAddress].join(', ') }}
    template(#end)
      b-navbar-item(tag='div')
        .buttons
          a.button.is-primary(
            href='/'
          )
            strong Sign up

  //- Nav
  section.section
    .container
      Chart(:invoices='invoices')
      //- Chart(:height='100' :data='chart.data' :options='chart.options')
  section.section
    .container
      p.title Invoices
        span.subtitle  ({{ invoices.length }})
        b-button(
          @click='exportCSV()'
          size='is-small'
          type='is-ghost'
        ) Export CSV
      p.has-text-right.subtitle.is-size-7(
        v-if='isWide'
      ) Hold the Shift key to scroll table sideways

      b-collapse(:open='false')
        template(#trigger)
          .has-text-right
            b-button(
              label='Columns'
              type='is-ghost'
              size='is-small'
            )
        .notification
          .content
            b-field(
              grouped
              group-multiline
            )
              .control(
                v-for='(value, key) in visible'
                :key='key'
              )
                b-checkbox(
                  v-model='visible[key]'
                ) {{ startCase(key) }}

      b-table(
        :data='invoices'
        :loading='loading'
        scrollable
        paginated
        pagination-simple
        :total='total'
        :per-page='perPage'
        aria-next-label='Next page'
        aria-previous-label='Previous page'
        aria-page-label='Page'
        aria-current-label='Current page'
        :default-sort-direction='defaultSortOrder'
        :default-sort='[sortField, sortOrder]'
        detailed
        detail-key='invoiceID'
      )
        //- :checked-rows.sync='checkedRows'
        //- checkable
        //- checkbox-position='right'
        //- :key='tableRefresh'
        //- backend-pagination
        //- backend-sorting
        //- @page-change='onPageChange'
        //- @sort='onSort'
        b-table-column(
          centered
          sortable
          field='invoiceNumber'
          label='Number'
          v-slot='props'
          :visible='visible["invoiceNumber"]'
        )
          | {{ props.row.invoiceNumber | truncate }}
          |
          a(:href='link(props.row)' target='_blank')
            b-icon(icon='open-in-new')
        b-table-column(
          centered
          sortable
          field='reference'
          label='Ref'
          v-slot='props'
          :visible='visible["reference"]'
        )
          | {{ props.row.reference | truncate }}
        b-table-column(
          centered
          sortable
          field='contact.name'
          label='To'
          v-slot='props'
          :visible='visible["contact.name"]'
        )
          | {{ props.row.contact.name | truncate }}
        b-table-column(
          centered
          sortable
          field='date'
          label='Date'
          v-slot='props'
          width='150'
          :visible='visible["date"]'
        )
          | {{ props.row.date ? dayjs(props.row.date).format('D MMM YYYY') : '' }}
        b-table-column(
          centered
          sortable
          field='dueDate'
          label='Due Date'
          width='150'
          :visible='visible["dueDate"]'
        )
          template(v-slot='props')
            | {{ props.row.dueDate ? dayjs(props.row.dueDate).format('D MMM YYYY') : '' }}
          template(#subheading='props')
            | Totals:
        b-table-column(
          sortable
          field='amountPaid'
          label='Paid'
          numeric
          :visible='visible["amountPaid"]'
        )
          template(v-slot='props')
            | {{ props.row.amountPaid | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('amountPaid') | currency(symbol) }}
        b-table-column(
          sortable
          field='amountDue'
          label='Due'
          numeric
          :visible='visible["amountDue"]'
        )
          template(v-slot='props')
            | {{ props.row.amountDue | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('amountDue') | currency(symbol) }}
        b-table-column(
          sortable
          field='subTotal'
          label='Subtotal'
          numeric
          :visible='visible["subTotal"]'
        )
          template(v-slot='props')
            | {{ props.row.subTotal | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('subTotal') | currency(symbol) }}
        b-table-column(
          sortable
          field='totalTax'
          label='Tax'
          numeric
          :visible='visible["totalTax"]'
        )
          template(v-slot='props')
            | {{ props.row.totalTax | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('totalTax') | currency(symbol) }}
        b-table-column(
          sortable
          field='total'
          label='Total'
          numeric
          :visible='visible["total"]'
        )
          template(v-slot='props')
            | {{ props.row.total | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('total') | currency(symbol) }}
        b-table-column(
          centered
          sortable
          field='status'
          label='Status'
          v-slot='props'
          :visible='visible["status"]'
        )
          | {{ niceStatus(props.row.status) }}
        b-table-column(
          centered
          sortable
          field='sentToContact'
          label='Sent'
          v-slot='props'
          :visible='visible["sentToContact"]'
        )
          | {{ props.row.sentToContact ? 'Sent' : '' }}
        //- b-table-column(
        //-   field='custom'
        //-   label='Custom'
        //-   v-slot='props'
        //-   numeric
        //- )
        //-   //- | {{ props.row.custom }}
        //-   b-input(v-model='props.row.custom')

        b-table-column(
          label='Commission'
          width='200'
          field='commission'
          numeric
          sortable
          :visible='visible["commission"]'
        )
          template(v-slot='props')
            | {{ props.row.commission | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('commission') | currency(symbol) }}

        b-table-column(
          label='Cumulative'
          width='200'
          field='cumulative'
          numeric
          sortable
          :visible='visible["cumulative"]'
        )
          template(v-slot='props')
            | {{ props.row.cumulative | currency(symbol) }}

        b-table-column(
          centered
          label='Split'
          width='200'
          :visible='visible["split"]'
        )
          template(v-slot='props')
            b-tag(
              v-for='split in props.row.split'
              :key='split'
            ) {{ split }}

        b-table-column(
          label='Commission Level 2'
          width='200'
          field='commission2'
          numeric
          sortable
          :visible='visible["commission2"]'
        )
          template(v-slot='props')
            | {{ props.row.commission2 | currency(symbol) }}
          template(#subheading='props')
            | {{ sumRows('commission2') | currency(symbol) }}

        b-table-column(
          label='Cumulative Level 2'
          width='200'
          field='cumulative2'
          numeric
          sortable
          :visible='visible["cumulative2"]'
        )
          template(v-slot='props')
            | {{ props.row.cumulative2 | currency(symbol) }}

        b-table-column(
          centered
          label='Split Level 2'
          width='200'
          :visible='visible["split2"]'
        )
          template(v-slot='props')
            b-tag(
              v-for='split2 in props.row.split2'
              :key='split2'
            ) {{ split2 }}

        template(#detail='props')
          //- pre {{ props.row.lineItems }}
          //- pre {{ props.row }}
          p.subtitle Line Items
          b-table(
            :data='props.row.lineItems'
          )
            b-table-column(
              field='accountCode'
              label='Account Code'
              v-slot='props'
            )
              | {{ props.row.accountCode }}
            b-table-column(
              field='itemCode'
              label='Item Code'
              v-slot='props'
            )
              | {{ props.row.itemCode }}
            b-table-column(
              field='description'
              label='Description'
              v-slot='props'
              width='33vw'
            )
              .pre {{ props.row.description }}
            b-table-column(
              field='unitAmount'
              label='Unit Amount'
              v-slot='props'
              numeric
            )
              | {{ props.row.unitAmount }}
            b-table-column(
              field='quantity'
              label='Quantity'
              v-slot='props'
              numeric
            )
              | {{ props.row.quantity }}
            b-table-column(
              field='lineAmount'
              label='Line Amount'
              v-slot='props'
              numeric
            )
              | {{ props.row.lineAmount }}

</template>

<script>
import { parse as zipsonParse } from 'zipson'
// import { decode } from 'messagepack'
// import Nav from '@/components/Nav'
import Chart from '@/components/Chart'
// import { db, getDoc, getDocs, collection, doc } from '@/firebase'
import { db, getDoc, doc } from '@/firebase'
import dayjs from 'dayjs'
import currency from 'currency.js'
import capitalize from 'lodash/capitalize'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import fileDownload from 'js-file-download'
const { parse } = require('json2csv')

export default {
  components: {
    Chart
    // Nav
  },
  data () {
    return {
      user: null,
      name: 'Foo',
      isWide: false,
      invoices: [],
      total: 0,
      loading: true,
      sortField: 'date',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      // page: 1,
      perPage: 50,
      visible: {
        invoiceNumber: true,
        reference: true,
        'contact.name': false,
        date: true,
        dueDate: true,
        amountPaid: false,
        amountDue: false,
        subTotal: true,
        totalTax: false,
        total: true,
        status: true,
        sentToContact: false,
        // cost: true,
        commission: true,
        cumulative: true,
        split: true,
        commission2: true,
        cumulative2: true,
        split2: true
      }
    //   chart: {
    //     data: {
    //       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //       datasets: [
    //         {
    //           label: 'GitHub Commits',
    //           backgroundColor: '#f87979',
    //           data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
    //         }
    //       ]
    //     },
    //     options: {
    //       responsive: true,
    //       maintainAspectRatio: true,
    //       aspectRatio: 1.6
    //     }
    //   }
    }
  },
  filters: {
    truncate (value, length = 12) {
      return value?.length > length
        ? value.substr(0, length) + '...'
        : value
    },
    currency (value, symbol) {
      return currency(value, { symbol }).format()
    }
  },
  watch: {
    field (val, oldVal) {
      console.log('field now', val, 'was', oldVal)
    }
  },
  computed: {
    symbol () {
      // return getSymbolFromCurrency(this.org?.baseCurrency)
      return '' // no symbol for now as it crowds ui
    }
  },
  methods: {
    startCase: startCase,
    capitalize: capitalize,
    dayjs: dayjs,
    niceStatus (status) {
      switch (status) {
        case 'SUBMITTED':
          return 'Awaiting Approval'
        case 'AUTHORISED':
          return 'Awaiting Payment'
        default:
          return capitalize(status)
      }
    },
    link (invoice) {
      return 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + invoice.invoiceID
    },
    sumRows (field) {
      return sumBy(this.invoices, field)
    },
    exportCSV () {
      const fields = Object.keys(this.visible)
      const filename = kebabCase(this.user.name)
      try {
        const csv = parse(this.invoices, { fields })
        // console.log(csv)
        fileDownload(csv, `${filename}.csv`, 'text/csv')
      } catch (err) {
        console.error(err)
      }
    },
    setupWideCheck () {
      // this.isWide = this.wide()
      // window.addEventListener('resize', debounce(() => {
      //   console.log('table resize')
      //   // this.isWide = this.wide()
      // }, 150))
      const ro = new ResizeObserver(entries => {
        console.log('resize', entries)
        const tableWidth = document.querySelector('.table')?.offsetWidth
        const wrapperWidth = document.querySelector('.table-wrapper')?.offsetWidth
        this.isWide = tableWidth > wrapperWidth
      })
      ro.observe(document.querySelector('.table'))
      ro.observe(document.querySelector('.table-wrapper'))
    }
  },
  async mounted () {
    const searchParams = new URLSearchParams(window.location.search)
    const user = searchParams.get('user')
    const org = searchParams.get('org')
    // const data = searchParams.get('data')
    // if (user && org && data) {
    if (user && org) {
      // const invoices = JSON.parse(decodeURIComponent(data))
      // const invoices = JSON.parse(Buffer.from(data, 'base64').toString('utf-8'))
      // const invoices = decode(data)
      // console.log('invoices', invoices)
      // this.invoices = invoices

      console.log('org', org)
      console.log('user', user)

      const userData = (await getDoc(doc(db, 'orgs', org, 'userData', user))).data()
      if (userData) {
        console.log('userData', userData)
        this.user = userData.user
        this.invoices = zipsonParse(this.user.invoices)
        this.loading = false
        this.setupWideCheck()
      } else {
        console.log('no userData')
      }
      // this.invoices = JSON.parse(userData.invoices)
      // console.log('user data', userData)
      // if (userData) {
      //   this.user = userData.user
      //   const invoicesSnap = (await getDocs(collection(db, 'orgs', org, 'userData', user, 'invoices')))
      //   const invoices = invoicesSnap.docs.map(snap => snap.data())
      //   console.log('user invoices', invoices)
      //   this.invoices = invoices
      //   this.loading = false
      // }
    }
  }
}
</script>

<style lang="stylus">
h1
  color green
</style>
