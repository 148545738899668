var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "plans"
    }
  }, [_c('b-loading', {
    model: {
      value: _vm.isLoading,
      callback: function callback($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  }), _c('Header', {
    attrs: {
      "user": _vm.user
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v("Plans")]), _c('div', {
    staticClass: "block"
  }, [_vm._v("All plans start with a 7 day free trial.")]), _c('div', {
    staticClass: "block"
  }, [_vm._v("Purchase a yearly plan to get 12 months for the price of 10!")]), _c('div', {
    staticClass: "block"
  }, [_vm._v("The number of seats you choose determines how many Xero contacts you can assign commissions to, so pick a number that best suits the size of your sales team. If you later find you need more or less seats, you can change this by modifying your subscription and updating the quantity.")]), _vm._l(_vm.products, function (product) {
    return [_c('p', {
      staticClass: "subtitle mt-6"
    }, [_vm._v(_vm._s(product.name))]), _c('p', [_vm._v(_vm._s(product.description))]), _c('div', {
      staticClass: "columns is-multiline is-mobile mb-6 mt-4"
    }, _vm._l(product.prices, function (price) {
      var _vm$user;

      return _c('div', {
        key: price.id,
        staticClass: "column is-narrow"
      }, [_c('form', {
        ref: "form" + price.id,
        refInFor: true,
        attrs: {
          "action": "/api/stripe/checkout",
          "method": "POST"
        }
      }, [_c('input', {
        attrs: {
          "type": "hidden",
          "name": "priceId"
        },
        domProps: {
          "value": price.id
        }
      }), _c('input', {
        attrs: {
          "type": "hidden",
          "name": "redirect"
        },
        domProps: {
          "value": _vm.redirect
        }
      }), _c('input', {
        attrs: {
          "type": "hidden",
          "name": "uid"
        },
        domProps: {
          "value": (_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.uid
        }
      }), _c('div', {
        staticClass: "card"
      }, [_c('div', {
        staticClass: "card-content"
      }, [_c('div', {
        staticClass: "content"
      }, [_c('p', {
        staticClass: "is-size-1"
      }, [_vm._v("$" + _vm._s(price.unit_amount / 100 * price.quantity)), _c('span', {
        staticClass: "is-size-5"
      }, [_vm._v("/ " + _vm._s(price.recurring.interval))])]), _c('p', [_c('b-field', {
        attrs: {
          "label": "Seats",
          "label-position": "on-border"
        }
      }, [_c('b-input', {
        attrs: {
          "type": "number",
          "name": "quantity",
          "min": "1"
        },
        model: {
          value: price.quantity,
          callback: function callback($$v) {
            _vm.$set(price, "quantity", $$v);
          },
          expression: "price.quantity"
        }
      }), _c('p', {
        staticClass: "control"
      }, [_c('button', {
        staticClass: "button is-primary",
        on: {
          "click": function click($event) {
            return _vm.submit("form" + price.id);
          }
        }
      }, [_vm._v("Select")])])], 1)], 1)])])])])]);
    }), 0)];
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }