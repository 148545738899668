var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "home"
    }
  }, [_c('b-modal', {
    attrs: {
      "has-modal-card": ""
    },
    model: {
      value: _vm.isCardModalActive,
      callback: function callback($$v) {
        _vm.isCardModalActive = $$v;
      },
      expression: "isCardModalActive"
    }
  }, [_c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('div', {
    staticClass: "modal-card",
    staticStyle: {
      "width": "auto",
      "max-width": "100vw"
    }
  }, [_c('section', {
    staticClass: "modal-card-body px-6"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "is-size-3"
  }, [_vm._v("G'day! You've caught us mid-upgrade.")]), _c('p', [_vm._v("We're busily adding new features and functionality to Commissionaire. Things are progressing smoothly and it should be ready to help you calculate your commissions soon. If you'd like to receive a reminder when it's ready, please enter your details below:")])]), _c('div', {
    staticClass: "content"
  }), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Industry"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return [_c('b-field', {
                staticClass: "my-3",
                attrs: {
                  "label": "Industry",
                  "horizontal": "",
                  "type": {
                    "is-danger": errors[0],
                    "is-primary": valid
                  },
                  "message": errors
                }
              }, [_c('b-select', {
                attrs: {
                  "placeholder": "Select industry"
                },
                model: {
                  value: _vm.form.industry,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "industry", $$v);
                  },
                  expression: "form.industry"
                }
              }, [_c('option', [_vm._v("Art")]), _c('option', [_vm._v("Fashion")]), _c('option', [_vm._v("Music")]), _c('option', [_vm._v("Pharmaceutical")]), _c('option', [_vm._v("Real Estate")]), _c('option', [_vm._v("Technology")]), _c('option', [_vm._v("Travel")]), _c('option', [_vm._v("Other")])])], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Team Size"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return [_c('b-field', {
                staticClass: "my-3",
                attrs: {
                  "label": "Sales Team Size",
                  "horizontal": "",
                  "type": {
                    "is-danger": errors[0],
                    "is-primary": valid
                  },
                  "message": errors
                }
              }, [_c('b-slider', {
                staticClass: "px-3",
                attrs: {
                  "min": 1,
                  "max": 100,
                  "indicator": "",
                  "tooltip": false,
                  "custom-formatter": function customFormatter(val) {
                    return val === 100 ? val + '+' : val.toString();
                  }
                },
                model: {
                  value: _vm.form.teamSize,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "teamSize", $$v);
                  },
                  expression: "form.teamSize"
                }
              }, [_c('b-slider-tick', {
                attrs: {
                  "value": 1
                }
              }, [_vm._v("1")]), _c('b-slider-tick', {
                attrs: {
                  "value": 10
                }
              }, [_vm._v("10")]), _c('b-slider-tick', {
                attrs: {
                  "value": 25
                }
              }, [_vm._v("25")]), _c('b-slider-tick', {
                attrs: {
                  "value": 50
                }
              }, [_vm._v("50")]), _c('b-slider-tick', {
                attrs: {
                  "value": 100
                }
              }, [_vm._v("100+")])], 1)], 1)];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "Email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors,
                  valid = _ref4.valid;
              return [_c('b-field', {
                staticClass: "my-3",
                attrs: {
                  "label": "Email Address",
                  "horizontal": "",
                  "type": {
                    "is-danger": errors[0],
                    "is-primary": valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "placeholder": "Enter your email address",
                  "type": "email"
                },
                model: {
                  value: _vm.form.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "email", $$v);
                  },
                  expression: "form.email"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-field', {
          staticClass: "my-3",
          attrs: {
            "horizontal": ""
          }
        }, [_c('b-button', {
          attrs: {
            "label": "Signup",
            "type": "is-primary"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.submit);
            }
          }
        })], 1)];
      }
    }])
  })], 1)])])]), _c('Header', {
    attrs: {
      "user": _vm.user,
      "ready": _vm.ready,
      "is-card-modal-active": _vm.isCardModalActive
    },
    on: {
      "update:isCardModalActive": function updateIsCardModalActive($event) {
        _vm.isCardModalActive = $event;
      },
      "update:is-card-modal-active": function updateIsCardModalActive($event) {
        _vm.isCardModalActive = $event;
      }
    }
  }), _c('section', {
    staticClass: "hero is-medium has-background-primary"
  }, [_c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('h1', {
    staticClass: "title has-text-white"
  }, [_vm._v("Manage commissions with ease")]), _c('h2', {
    staticClass: "subtitle has-text-white"
  }, [_vm._v("Calculate commissions based on invoice subtotal, total, paid amounts, or cumulative splits")]), _c('div', {
    staticClass: "buttons"
  }, [_vm.ready ? _c('router-link', {
    staticClass: "button is-primary is-inverted",
    attrs: {
      "to": "/plans"
    }
  }, [_vm._v("Sign up for Commissionaire")]) : _c('div', {
    staticClass: "button is-primary is-inverted",
    on: {
      "click": function click($event) {
        _vm.isCardModalActive = true;
      }
    }
  }, [_vm._v("Sign up for Commissionaire")]), _c('a', {
    staticClass: "button is-white is-outlined",
    attrs: {
      "href": _vm.xeroLink,
      "target": "_blank",
      "lang": "en",
      "media": "all"
    }
  }, [_vm._v("Try Xero for free")])], 1)]), _vm._m(0)])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "columns is-variable is-8"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("About Commissionaire")]), _c('p', [_vm._v("Commissionaire lets you keep track of sales and visualise commissions.")]), _c('ul', [_c('li', [_vm._v("Calculate commissions based on invoice subtotal, total, paid amounts, or cumulative splits.")]), _c('li', [_vm._v("Keep track of staged projects easily. See totals of how much has been received versus how much is owing per sale, presented as an easy to read bar chart and table.")]), _c('li', [_vm._v("See both past and future expected payments. Optionally record commissions on sales to see upcoming expected income for commission-based businesses.")]), _c('li', [_vm._v("Take the guesswork out of knowing whether your sales will hit target next month. See at a glance all upcoming payments and spot any outstanding amounts.")]), _c('li', [_vm._v("Keep track of split commissions between agents or other parties  "), _c('b-tag', [_vm._v("Split plan only")])], 1)]), _c('p', [_vm.ready ? _c('router-link', {
    attrs: {
      "to": "/plans"
    }
  }, [_vm._v("Sign up for Commissionaire")]) : _c('a', {
    on: {
      "click": function click($event) {
        _vm.isCardModalActive = true;
      }
    }
  }, [_vm._v("Sign up for Commissionaire")]), _vm._v(".")], 1)])]), _c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("About Xero")]), _c('p', [_vm._v("Xero is world-leading online accounting software built for small business.")]), _vm._m(1), _c('p', [_c('a', {
    attrs: {
      "href": _vm.xeroLink,
      "target": "_blank",
      "lang": "en",
      "media": "all"
    }
  }, [_vm._v("Try Xero for free")]), _vm._v(".")])])])])])]), _vm._m(2), _vm._m(3), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "buttons is-centered"
  }, [_vm.ready ? _c('router-link', {
    staticClass: "button is-primary",
    attrs: {
      "to": "/plans"
    }
  }, [_vm._v("Sign up for Commissionaire")]) : _c('div', {
    staticClass: "button is-primary",
    on: {
      "click": function click($event) {
        _vm.isCardModalActive = true;
      }
    }
  }, [_vm._v("Sign up for Commissionaire")]), _c('a', {
    staticClass: "button is-primary is-outlined",
    attrs: {
      "href": _vm.xeroLink,
      "target": "_blank",
      "lang": "en",
      "media": "all"
    }
  }, [_vm._v("Try Xero for free")])], 1)])]), _c('footer', {
    staticClass: "footer has-background-light"
  }, [_c('div', {
    staticClass: "content has-text-centered"
  }, [_vm._m(4), _vm._m(5), _c('p', [_c('span', [_vm._v("© " + _vm._s(_vm.year))])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "video-container"
  }, [_c('iframe', {
    staticClass: "video",
    attrs: {
      "id": "video",
      "data-src": "//www.youtube.com/embed/RnMmfwzluak?rel=0&showinfo=0&controls=0&enablejsapi=1&loop=0&autoplay=0&mute=0",
      "frameborder": "0",
      "allowfullscreen": ""
    }
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_vm._v("Get a real-time view of your cashflow. Log in anytime, anywhere on your Mac, PC, tablet or phone to get a real-time view of your cash flow. It’s small business accounting software that’s simple, smart and occasionally magical.")]), _c('li', [_vm._v("Run your business on the go. Use our mobile app to reconcile, send invoices, or create expense claims - from anywhere.")]), _c('li', [_vm._v("Get paid faster with online invoicing. Send online invoices to your customers - and get updated when they’re opened.")]), _c('li', [_vm._v("Reconcile in seconds. Xero imports and categorises your latest bank transactions. Just click ok to reconcile.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section has-background-primary",
    attrs: {
      "id": "connected"
    }
  }, [_c('div', {
    staticClass: "columns is-centered"
  }, [_c('div', {
    staticClass: "column is-one-third"
  }, [_c('figure', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/xero-connected-xl.png")
    }
  })])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section",
    attrs: {
      "id": "help"
    }
  }, [_c('div', {
    staticClass: "columns is-centered"
  }, [_c('div', {
    staticClass: "column is-one-third"
  }, [_c('div', {
    staticClass: "content has-text-centered"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("How does Commissionaire work?")]), _c('ul', {
    staticClass: "how"
  }, [_c('li', [_vm._v("Sign up for Xero and create some invoices")]), _c('li', [_vm._v("Sign up for Commissionaire and login")]), _c('li', [_vm._v("Click the Connect to Xero button")]), _c('li', [_vm._v("Commissionaire reads invoices from Xero")]), _c('li', [_vm._v("Invoices are grouped into sales by reference")]), _c('li', [_vm._v("Sales are presented as both a table and chart")]), _c('li', [_vm._v("Use the navigation to filter and sort sales")]), _c('li', [_vm._v("Add commission amounts next to each invoice")]), _c('li', [_vm._v("Totals for commissions and sales are automatic")])]), _c('h2', {
    staticClass: "title"
  }, [_vm._v("How can I upgrade or cancel my subscription?")]), _c('ul', {
    staticClass: "how"
  }, [_c('li', [_vm._v("Login and connect to Xero then click the link at the bottom of the screen")])]), _c('h2', {
    staticClass: "title"
  }, [_vm._v("How can I report a bug or suggest a feature?")]), _c('ul', {
    staticClass: "how"
  }, [_c('li', [_vm._v("Send an email to stew at this domain with any problems or ideas")])])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Commissionaire")]), _vm._v(" by "), _c('a', {
    attrs: {
      "href": "https://www.linkedin.com/in/stewheckenberg/",
      "target": "_blank"
    }
  }, [_vm._v("Stew Heckenberg")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "is-size-7"
  }, [_c('strong', [_vm._v("Questions?")]), _vm._v(" Email stew at commissionaire dot io")]);
}]

export { render, staticRenderFns }