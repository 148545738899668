<template lang="pug">
#home
  //- pre signups = {{ signups }}
  b-modal(v-model='isCardModalActive' has-modal-card)
    form(action='')
      .modal-card(
        style='width: auto; max-width: 100vw'
      )
        //- header.modal-card-head
        //-   p.modal-card-title Signup
        //-   button.delete(type='button' @click="isCardModalActive = false")
        section.modal-card-body.px-6
          .content
            p.is-size-3 G'day! You've caught us mid-upgrade.
            p.
              We're busily adding new features and functionality to Commissionaire.
              Things are progressing smoothly and it should be ready to help you calculate your commissions soon.
              If you'd like to receive a reminder when it's ready, please enter your details below:
          .content
          ValidationObserver(
            ref='observer'
            v-slot='{ handleSubmit }'
          )
            ValidationProvider(
              rules='required'
              name='Industry'
              v-slot='{ errors, valid }'
            )
              b-field.my-3(
                label='Industry'
                horizontal
                :type='{ "is-danger": errors[0], "is-primary": valid }'
                :message='errors'
              )
                b-select(
                  placeholder='Select industry'
                  v-model='form.industry'
                )
                  option Art
                  option Fashion
                  option Music
                  option Pharmaceutical
                  option Real Estate
                  option Technology
                  option Travel
                  option Other
            ValidationProvider(
              rules='required'
              name='Team Size'
              v-slot='{ errors, valid }'
            )
              b-field.my-3(
                label='Sales Team Size'
                horizontal
                :type='{ "is-danger": errors[0], "is-primary": valid }'
                :message='errors'
              )
                b-slider.px-3(
                  :min='1'
                  :max='100'
                  indicator
                  :tooltip='false'
                  :custom-formatter="(val) => (val === 100) ? val + '+' : val.toString()"
                  v-model='form.teamSize'
                )
                  b-slider-tick(:value='1') 1
                  b-slider-tick(:value='10') 10
                  b-slider-tick(:value='25') 25
                  b-slider-tick(:value='50') 50
                  b-slider-tick(:value='100') 100+
                //- b-numberinput(
                //-   v-model='form.teamSize'
                //-   placeholder='Select team size'
                //-   :min='1'
                //-   controls-alignment='left'
                //-   controls-position='compact'
                //- )
                //- b-select(
                //-   placeholder='Select team size'
                //-   v-model='form.teamSize'
                //- )
                //-   option 1
                //-   option 2-10
                //-   option 10-20
                //-   option 20-50
                //-   option 50+
            ValidationProvider(
              rules='required|email'
              name='Email'
              v-slot='{ errors, valid }'
            )
              b-field.my-3(
                label='Email Address'
                horizontal
                :type='{ "is-danger": errors[0], "is-primary": valid }'
                :message='errors'
              )
                b-input(
                  placeholder='Enter your email address'
                  type='email'
                  v-model='form.email'
                )
            b-field.my-3(
              horizontal
            )
              b-button(label='Signup' type='is-primary' @click='handleSubmit(submit)')
        //- footer.modal-card-foot
        //-   //- b-button(label='Close' @click="isCardModalActive = false")
        //-   b-button.is-pulled-right(label='Signup' type='is-primary' @click='signup')
  Header(:user='user' :ready='ready' :is-card-modal-active.sync='isCardModalActive')
  section.hero.is-medium.has-background-primary
    .hero-body
      .container
        .columns
          .column
            h1.title.has-text-white Manage commissions with ease
            //- h2.subtitle.has-text-white See future and past commissions and group invoices together as sales
            h2.subtitle.has-text-white Calculate commissions based on invoice subtotal, total, paid amounts, or cumulative splits
            //- Visualise your foward commissions by month, quarter or year. Compare these to your past sales to monitor trends.
            //-Account for multi due-date part payments for a single sale which improves accurate sales reporting.
            .buttons
              router-link.button.is-primary.is-inverted(
                v-if='ready'
                to='/plans'
              ) Sign up for Commissionaire
              .button.is-primary.is-inverted(
                v-else
                @click='isCardModalActive = true'
              ) Sign up for Commissionaire
              a.button.is-white.is-outlined(
                :href='xeroLink'
                target='_blank'
                lang='en'
                media='all'
              ) Try Xero for free
          .column
            .video-container
              iframe.video(
                id='video'
                data-src='//www.youtube.com/embed/RnMmfwzluak?rel=0&showinfo=0&controls=0&enablejsapi=1&loop=0&autoplay=0&mute=0',
                frameborder='0',
                allowfullscreen
              )
                //- src='//www.youtube.com/embed/WslgmF4d8FQ?rel=0&showinfo=0&controls=0&enablejsapi=1&loop=0&autoplay=0&mute=0',
            //- figure.image
            //-   img(
            //-     src='~/assets/screenshot.png'
            //-   )
  section.section
    .container
      .columns.is-variable.is-8
        .column
          .content
            h2.title About Commissionaire
            p Commissionaire lets you keep track of sales and visualise commissions.
            ul
              li
                | Calculate commissions based on invoice subtotal, total, paid amounts, or cumulative splits.
                //- | Sync your invoices automatically from Xero and group them into sales. For example, invoices "123 Deposit" and "123 Balance" are listed together under sale "123" and their total, received and owing amounts are summed.
              li
                | Keep track of staged projects easily. See totals of how much has been received versus how much is owing per sale, presented as an easy to read bar chart and table.
              li
                | See both past and future expected payments. Optionally record commissions on sales to see upcoming expected income for commission-based businesses.
              li
                | Take the guesswork out of knowing whether your sales will hit target next month. See at a glance all upcoming payments and spot any outstanding amounts.
              li
                | Keep track of split commissions between agents or other parties
                | &nbsp;
                b-tag Split plan only
            p
              router-link(
                v-if='ready'
                to='/plans'
              ) Sign up for Commissionaire
              a(
                v-else
                @click='isCardModalActive = true'
              ) Sign up for Commissionaire
              | .

        .column
          .content
            h2.title About Xero
            p Xero is world-leading online accounting software built for small business.
            ul
              li
                | Get a real-time view of your cashflow. Log in anytime, anywhere on your Mac, PC, tablet or phone to get a real-time view of your cash flow. It&rsquo;s small business accounting software that&rsquo;s simple, smart and occasionally magical.
              li
                | Run your business on the go. Use our mobile app to reconcile, send invoices, or create expense claims - from anywhere.
              li
                | Get paid faster with online invoicing. Send online invoices to your customers - and get updated when they&rsquo;re opened.
              li
                | Reconcile in seconds. Xero imports and categorises your latest bank transactions. Just click ok to reconcile.
            p
              a(
                :href='xeroLink'
                target='_blank'
                lang='en'
                media='all'
              ) Try Xero for free
              | .

  section.section.has-background-primary#connected
    .columns.is-centered
      .column.is-one-third
        figure.image
          img(
            src='../assets/xero-connected-xl.png'
          )
  section.section#help
    .columns.is-centered
      .column.is-one-third
        .content.has-text-centered
          h2.title How does Commissionaire work?
          ul.how
            li Sign up for Xero and create some invoices
            li Sign up for Commissionaire and login
            li Click the Connect to Xero button
            li Commissionaire reads invoices from Xero
            li Invoices are grouped into sales by reference
            li Sales are presented as both a table and chart
            li Use the navigation to filter and sort sales
            li Add commission amounts next to each invoice
            li Totals for commissions and sales are automatic
          h2.title How can I upgrade or cancel my subscription?
          ul.how
            li Login and connect to Xero then click the link at the bottom of the screen
          h2.title How can I report a bug or suggest a feature?
          ul.how
            li Send an email to stew at this domain with any problems or ideas

  section.section
    .container
      .buttons.is-centered
        router-link.button.is-primary(
          v-if='ready'
          to='/plans'
        ) Sign up for Commissionaire
        .button.is-primary(
          v-else
          @click='isCardModalActive = true'
        ) Sign up for Commissionaire
        a.button.is-primary.is-outlined(
          :href='xeroLink'
          target='_blank'
          lang='en'
          media='all'
        ) Try Xero for free

  footer.footer.has-background-light
    .content.has-text-centered
      p #[strong Commissionaire] by #[a(href='https://www.linkedin.com/in/stewheckenberg/' target='_blank') Stew Heckenberg]
      p.is-size-7 #[strong Questions?] Email stew at commissionaire dot io
      //- p.is-size-7
      //-   router-link(to='/privacy') Privacy Policy
      //- p.is-size-7 #[strong Found a bug?] Please send an email to stew at this domain
      p
        span &copy; {{ year }}
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { db, doc, setDoc, logEvent, analytics, signInXero } from '@/firebase'
import { mapState } from 'vuex'
import Header from '@/components/Header'
// import darkModeMixin from '@/mixins/darkMode'
// import axios from 'axios'
// const YAML = require('json-to-pretty-yaml')

export default {
  // async beforeRouteEnter (to, from, next) {
  //   const user = await auth.currentUser
  //   next((vm) => { vm.user = user })
  // },
  name: 'Home',
  metaInfo: {
    title: 'Home'
  },
  components: {
    Header,
    ValidationObserver,
    ValidationProvider
  },
  // mixins: [darkModeMixin],
  data () {
    return {
      ready: false,
      // signups: [],
      form: this.resetForm(),
      isCardModalActive: false,
      // xeroLink: '//xero.com/signup/?xtid=x30commissionaire'
      xeroLink: '//xero.com/signup/?xtid=x30commissionaireoauth2'
      // user: null
    }
  },
  // computed: {
  //   user: () => auth.currentUser
  // },
  computed: {
    year: () => (new Date()).getFullYear(),
    ...mapState(['user'])
  },
  // firestore: {
  //   signups: db.collection('signups')
  // },
  watch: {
    isCardModalActive (val) {
      if (val) {
        logEvent(analytics, 'open_signup_modal')
      }
    },
    user (val) {
      console.log('user watch', val)
    }
  },
  mounted () {
    var video = document.getElementById('video')
    video.setAttribute('src', video.getAttribute('data-src'))
  },
  methods: {
    signInXero: signInXero,
    playVideo () {
      console.log('play video')
    },
    submit () {
      const formData = { ...this.form }
      // db.collection('signups').add(this.form)
      // db.collection('signups')
      //   .doc(this.form.email)
      //   .set({ ...this.form })
      // setDoc(doc(db, 'signups', this.form.email), formData)
      const when = (new Date()).toJSON()
      setDoc(doc(db, 'signups', when), formData)
      logEvent(analytics, 'sign_up', formData)
      this.form = this.resetForm()
      this.isCardModalActive = false
      this.$buefy.toast.open({
        message: 'Thank you, we\'ll be in touch!',
        type: 'is-primary',
        duration: 5000
      })
      // axios.post('/api/misc/email', {
      //   to: 'stew@commissionaire.io',
      //   from: 'noreply@commissionaire.io',
      //   subject: 'New Commissionaire Signup!',
      //   text: YAML.stringify(formData)
      // })
    },
    resetForm () {
      return {
        email: '',
        industry: null,
        teamSize: 1
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
ul.how
  display inline-block
  text-align left
.video
  // position absolute
  // top 0
  // left 0
  width 100%
  height 100%
  position absolute
  top 0
  left 0
  bottom 0
  right 0
.video-container
  width 100%
  padding-top 56.25%
  position relative
#help
  display none
// .navbar-item img.connect
//   max-height 40px !important
//   height 40px !important
</style>
