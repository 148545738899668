<template lang="pug">
#chart
  column-chart(:data='chartData' prefix='$')

  b-field(
    horizontal
  )
    b-field(
      horizontal
      label='Field'
    )
      b-select(
        placeholder='Select a field'
        v-model='field'
      )
        option(
          v-for='f in fields'
          :value='f'
          :key='f'
        )
          | {{ startCase(f) }}

    b-field(
      horizontal
      label='Date'
    )
      b-select(
        placeholder='Select a date'
        v-model='dateField'
      )
        option(
          v-for='d in dateFields'
          :value='d'
          :key='d'
        )
          | {{ startCase(d) }}

    b-field(
      horizontal
      label='Period'
    )
      b-select(
        placeholder='Select a period'
        v-model='dateFormat'
      )
        option(
          v-for='(value, name) in dateFormats'
          :value='value'
          :key='value'
        )
          | {{ startCase(name) }}
</template>

<script>
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import dayjs from 'dayjs'
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
    invoices: {
      type: Array
    },
    dateFieldInit: {
      type: String,
      default: 'dueDate'
    },
    dateFormatInit: {
      type: String,
      default: 'MMM-YY'
    },
    fieldInit: {
      type: String,
      default: 'commission'
    }
  },
  data () {
    return {
      dateField: this.dateFieldInit,
      dateFields: [
        'date',
        'dueDate'
      ],
      dateFormat: this.dateFormatInit,
      dateFormats: {
        day: 'DD-MMM-YY',
        month: 'MMM-YY',
        year: 'YY',
        quarter: 'Q-YY',
        week: 'W-YY'
      },
      field: this.fieldInit,
      fields: [
        'total',
        'subTotal',
        'totalTax',
        'amountDue',
        'amountPaid',
        'commission',
        'commission2',
        'cumulative',
        'cumulative2'
        // 'split',
        // 'split2'
      ]
    }
  },
  computed: {
    chartData () {
      return sortBy(this.invoices, this.dateField).map((i) => {
        const value = i[this.field]
        const date = dayjs(i[this.dateField]).format(this.dateFormat)
        return [date, value]
      })
    }
  },
  methods: {
    startCase
  }
}
</script>
