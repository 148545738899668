import { mapState, mapActions } from 'vuex'
// import { enable, disable } from 'darkreader'
import {
  auto,
  // enable,
  disable,
  setFetchMethod
} from '@/darkreader'

setFetchMethod(window.fetch)
// auto as followSystemColorScheme,
// exportGeneratedCSS as collectCSS,
// isEnabled as isDarkReaderEnabled

// const DarkReader = require('@/darkreader')
// DarkReader.setFetchMethod(window.fetch)
// DarkReader.auto({
//   brightness: 100,
//   contrast: 90,
//   sepia: 10
// })

export default {
  computed: {
    ...mapState(['darkMode'])
  },
  watch: {
    darkMode: {
      immediate: true,
      handler (val) {
        if (val) {
          auto({
            brightness: 100,
            contrast: 90,
            sepia: 10
          })
        } else {
          disable()
        }
      }
    }
  },
  methods: {
    ...mapActions(['toggleDarkMode'])
  }
}
