<template lang="pug">
//- section.hero.is-primary.is-fullheight
section.hero.is-white.is-fullheight
  .hero-body
    .container.has-text-centered
      .columns.is-mobile.is-centered
        .column.is-narrow
          //- a(:href='connectRedirect')

          #logo.level.mb-5
            .level-left
              .level-item
                figure.image.is-64x64
                  img(src='@/assets/commissionaire-logo.png' alt='Commissionaire')
            //- img(src='@/assets/commissionaire-logo.png' alt='Commissionaire')
            //- | &nbsp;
            .level-right.mt-1
              .level-item
                .has-text-primary.is-size-4.is-uppercase.has-text-weight-bold.pb-3 Commissionaire

          #login
            a(:href='loginUrl')
              figure.image
                img.connect(
                  src='@/assets/connect-blue.svg'
                )
      //- div
      //-   p.title
      //-     | Fullheight hero
      //-   p.subtitle
      //-     | Fullheight subtitle
</template>

<script>
import axios from 'axios'

export default {
  metaInfo: {
    title: 'Login'
  },
  data () {
    return {
      loginUrl: '',
      connectRedirect: `/api/xero/connect?redirect=${window.location.origin}/app`
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { data } = await axios.get('/api/xero/connect-url', {
      params: {
        redirect: `${window.location.origin}/app`
      }
    })
    console.log('login url', data)
    next((vm) => {
      vm.loginUrl = data
    })
  }
}
</script>
