<template lang="pug">
footer.footer.has-background-light
  .content.has-text-centered
      p(v-if='user')
        form(method='POST' action='/api/stripe/portal')
          input(type='hidden' name='uid' :value='user.uid')
          button.button(
            type='submit'
            @click='loading()'
          )
            b-icon(icon='credit-card')
            span Manage Subscription
      p
        strong Commissionaire
        |  by #[a(href='https://www.linkedin.com/in/stewheckenberg' target='_blank') Stew Heckenberg ]
        br
      p.is-size-7 #[strong Questions?] Email stew at commissionaire dot io
      p
        span &copy; {{ year }}

</template>

<script>
import { auth } from '@/firebase'
import { LoadingProgrammatic as Loading } from 'buefy'
export default {
  computed: {
    user: () => auth.currentUser,
    year: () => (new Date()).getFullYear()
  },
  methods: {
    loading: () => Loading.open()
  }
}
</script>
