var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container",
    attrs: {
      "id": "header"
    }
  }, [_c('b-navbar', {
    attrs: {
      "close-on-click": false
    }
  }, [_c('template', {
    slot: "brand"
  }, [_c('router-link', {
    staticClass: "navbar-item",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/commissionaire-logo.png"),
      "alt": ""
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "has-text-primary is-size-4 is-uppercase has-text-weight-bold"
  }, [_vm._v("Commissionaire")])])], 1), _c('template', {
    slot: "end"
  }, [_vm.user ? _c('b-navbar-item', {
    attrs: {
      "tag": "div"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_vm.$route.name !== "Plans" ? _c('router-link', {
    staticClass: "button is-primary",
    attrs: {
      "to": "/app"
    }
  }, [_vm._v("Click here to go to the Dashboard")]) : _vm._e()], 1)]) : _c('b-navbar-item', {
    attrs: {
      "tag": "div"
    }
  }, [_vm.ready ? _c('div', {
    staticClass: "buttons"
  }, [_vm.user ? _c('router-link', {
    staticClass: "button is-primary",
    attrs: {
      "to": "/plans"
    }
  }, [_c('strong', [_vm._v("Sign up")])]) : _c('router-link', {
    staticClass: "button is-primary",
    attrs: {
      "to": "/login"
    }
  }, [_c('strong', [_vm._v("Sign in with Xero")])])], 1) : _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "button is-primary",
    on: {
      "click": function click($event) {
        return _vm.$emit("update:isCardModalActive", true);
      }
    }
  }, [_c('strong', [_vm._v("Sign up")])])])])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }