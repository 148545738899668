var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_c('column-chart', {
    attrs: {
      "data": _vm.chartData,
      "prefix": "$"
    }
  }), _c('b-field', {
    attrs: {
      "horizontal": ""
    }
  }, [_c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Field"
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Select a field"
    },
    model: {
      value: _vm.field,
      callback: function callback($$v) {
        _vm.field = $$v;
      },
      expression: "field"
    }
  }, _vm._l(_vm.fields, function (f) {
    return _c('option', {
      key: f,
      domProps: {
        "value": f
      }
    }, [_vm._v(_vm._s(_vm.startCase(f)))]);
  }), 0)], 1), _c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Date"
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Select a date"
    },
    model: {
      value: _vm.dateField,
      callback: function callback($$v) {
        _vm.dateField = $$v;
      },
      expression: "dateField"
    }
  }, _vm._l(_vm.dateFields, function (d) {
    return _c('option', {
      key: d,
      domProps: {
        "value": d
      }
    }, [_vm._v(_vm._s(_vm.startCase(d)))]);
  }), 0)], 1), _c('b-field', {
    attrs: {
      "horizontal": "",
      "label": "Period"
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Select a period"
    },
    model: {
      value: _vm.dateFormat,
      callback: function callback($$v) {
        _vm.dateFormat = $$v;
      },
      expression: "dateFormat"
    }
  }, _vm._l(_vm.dateFormats, function (value, name) {
    return _c('option', {
      key: value,
      domProps: {
        "value": value
      }
    }, [_vm._v(_vm._s(_vm.startCase(name)))]);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }